<template>
  <div style="position:relative; width:825px; margin: 40px 0px 120px 50px; text-align:justify">
    <p>
      Hi, ich bin Alex, 21 Jahre alt und studiere Computational Engineering an der TU Darmstadt.<br><br>
      Ich habe diese Webseite erschaffen, weil ich ständig das Bedürfnis habe, Dinge zu kreieren und zu unternehmen.
      Hier bekommst du einen Einblick in die Dinge, die ich so tue. Es wird alle paar Monate mal etwas dazukommen.<br><br>
      Viel Spaß bis dahin!
    </p>
  </div>
</template>

<script>
export default{
  name: 'UeberMich',
  components: {},

  methods: {
    getAge(){
      var now = new Date()
      var currentMillis = now.getTime()
      var birthday = new Date()
      birthday.setFullYear(2001)
      birthday.setMonth(9)
      birthday.setDate(30)
      birthday.setHours(0)
      birthday.setMinutes(0)
      birthday.setSeconds(0)
      var age = now - birthday;
      return Math.floor(age/(1000*3600*24*365.25))
    }
  }
}
</script>