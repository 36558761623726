<template>
 <div class="article-div">

 <h1>Tinyhouse</h1>
 <h2>Anfänge</h2>

 <p>Alles begann damit, dass meine Eltern sich im Herbst 2019 dazu
 entschlossen, in Lichtenberg im Fischbachtal ein Grundstück zu kaufen...<br>
 Damit ist für mich aus dem Nichts ein lang ersehnter Traum in Erfüllung
 gegangen: Ich würde mein eigenes Häuschen bauen.
 Sofort begann ich damit, Entwürfe zu designen.</p>

 <span style="font-size:14.5pt;font-family:Arial,sans-serif;color:black;mso-no-proof:yes">
 <img width="855" src="@/assets/images/Tinyhouse1-Dateien/image015.jpg" v:shapes="Grafik_x0020_20">
 </span>

 <h2>Graben</h2>

 <div id="side-div2">
 <img class="side-img2 mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/image002.jpg" v:shapes="Bild_x0020_8"><br>
 <img class="side-img2"  src="@/assets/images/Tinyhouse1-Dateien/image003.png" v:shapes="Bild_x0020_7">
 </div>
 <p>Als es dann
 erworben war, machte ich mich gleich daran, eine Stelle zu finden. Es sollte
 nicht zu nah an dem größeren Haus auf dem Berg stehen &ndash; ich wollte meinen
 eigenen, privaten Bereich für mich beanspruchen &ndash;, es sollte aber auch ein
 Stück weit in den Berg hineingebaut werden, denn es soll sehr klein und
 unscheinbar nach außen wirken, jedoch trotzdem genug Raum für alle nötigen
 Dinge haben.</p>

 <p>Die Natursteinmauer zwischen den beiden mittleren Ebenen erwies
 sich als die perfekte Stelle. Hier musste nämlich nur die Mauer an der Stelle
 abgetragen werden, an der ich das Haus bauen wollte, eine Grube musste
 ausgehoben werden und der Aushub konnte an den Seiten platziert werden, um das
 Loch noch tiefer aussehen zu lassen.<br>
 Also habe ich im Februar (am Valentinstag) spontan ein paar Freunde
 zusammengesucht, die mit mir graben gegangen sind!<br>
 Damit war es aber noch lange nicht getan und dann kam Corona, also war ich ab
 dann wieder auf mich alleine gestellt.</p>

 <h2>März bis Mai 2020</h2>

 <p>
 Im Frühling bin ich regelmäßig aufs Grundstück gefahren, um weiter
 zu graben. Insgesamt musste ich etwa 8&thinsp;m³ Erde beiseite räumen. Das größte
 Hindernis war ein riesiger Stein, der aus dem Weg musste.<br>
 Aber nach und nach erreichte ich die gewünschte Tiefe. Ich habe mich auch dazu
 entschlossen, an der Wand zum Hang eine Stufe zu lassen, damit nicht zu viel
 Gewicht auf einmal auf das kleine Haus drückt. Auf dieser Stufe werden sich
 einmal die Sitzbank und die Küchenzeile befinden.</p>
 <div id="side-div">
 <img src="@/assets/images/Tinyhouse1-Dateien/dreiBalken.jpg" class="side-img1"/><br />
 <img src="@/assets/images/Tinyhouse1-Dateien/tabsunddabs.jpg" class="side-img1"/>
 </div>
 <img src="@/assets/images/Tinyhouse1-Dateien/stein.jpg" id="stein"/>

 <p>Ich konnte nun auch schon die ersten Balken in die Erde Stecken.
 Im unteren Bereich benutze ich dafür einfach Einschlagbodenhülsen. Im oberen
 Teil sind die Balken einbetoniert. Es ist sehr mühsam, die Balken in einer
 Linie und im 90°-Winkel zueinander auszurichten und sie dabei immer noch
 senkrecht zum Boden zu halten. Es ist mir letztendlich auch nicht zu 100%
 gelungen, aber leichte Imperfektionen gehören zum Eigenbau dazu.<br>
 Als es dann allmählich Sommer wurde, konnten mir auch wieder Freunde helfen.</p>

 <h2>Sommer 2020</h2>
 <p>Der nächste Schritt war nun, rund um das Loch Betonmauern zu
 gießen, um den Hang zu stützen und das Innere vor Feuchtigkeit zu schützen.
 Damit war ich noch das ganze restliche Jahr beschäftigt. Der Grund dafür ist,
 dass ich jeden Betonsack einzeln im Baumarkt kaufen und den Berg hoch schleppen
 musste. Ein Sack beton wiegt etwa 25 kg. Außerdem hatten wir in diesem Jahr
 noch kein Fließend Wasser, also musste ich jedes Mal das Wasser in großen
 Kanistern von zu Hause mitschleppen.</p>

 <p>Das Prinzip ist jedoch einfach: Mit Holz wird eine Verschalung
 gebaut. Dann wird ein Drahtgitter eingelegt und Stahlstangen werden eingehauen.
 Das Drahtgitter ist wichtig, damit der Beton nicht bricht. Ich habe dafür
 einfachen Hasendraht verwendet.</p>

 <p>Im Sommer habe ich zwei Gartenpartys auf dem Grundstück
 veranstaltet. Das Haus eignete sich beide Male bereits gut als Buffetraum.</p>

 <img width="855" src="@/assets/images/Tinyhouse1-Dateien/image017.jpg" v:shapes="Grafik_x0020_18">

 <h2>Herbst und Winter 2020</h2>

 <p>Ich musste nun noch eine Teichfolie auf dem Boden auslegen, da
 aufsteigende Feuchtigkeit aus dem Boden leicht zu Schimmelbildung führen kann.
 Im Januar des nächsten Jahres war ich erst mit allen Betonmauern fertig.</p>

 <img width="855" src="@/assets/images/Tinyhouse1-Dateien/winter.jpg">

 <h2>Frühling 2021</h2>
 <img id="tilted-image" height="205" src="@/assets/images/Tinyhouse1-Dateien/image019.png" align="right" v:shapes="Bild_x0020_9">

 <p>Neues Jahr &ndash; neues Glück! Endlich waren alle an der Erde liegenden
 Stellen zubetoniert und das Gerüst für den unteren Teil war gebaut. Nun konnte
 damit begonnen werden, es von außen mit Stecklatten zu verkleiden. Das Haus
 nahm langsam Gestalt an.</p>

 <p>Ich war nun auch an dem lang hinausgezögerten Punkt angekommen,
 mir Gedanken über Fenster zu machen. Leider musste ich feststellen, dass
 fertige Fenster sehr teuer sind, die Preise fangen bei 100&thinsp;€ an und
 multiplizieren sich mit jeder zusätzlichen gewünschten Eigenschaft.
 Also musste ich die Fenster selbst bauen.
 Ich entschied mich für Plexiglas. Es ist billig, einfach zu
 verarbeiten und nicht unbedingt schlechter als normales Glas. Es ist auch
 genauso durchsichtig. Der einzige Unterschied ist, dass Plexiglas schneller
 zerkratzt, UV-Licht durchlässt und sich halt wie Plastik anfühlt.</p>

 <p>Für 6 2-fach verglaste Holzfenster habe ich gerade einmal 150&thinsp;€
 für das gesamte Plexiglas ausgegeben. Nun musste ich Rahmen bauen, um die Scheiben einzusetzen. Diese
 sind einfach aus gewöhnlichen Holzbalken gefertigt, in die ich zwei Kerben
 gefräst habe, welche die Scheibe fassen.</p>

 <div>
    <img class="collage2" src="@/assets/images/Tinyhouse1-Dateien/image021.jpg" style="margin-right:8px">
    <img class="collage2" src="@/assets/images/Tinyhouse1-Dateien/image022.jpg" >
    <img id="collage2u" class="collage2" src="@/assets/images/Tinyhouse1-Dateien/image023.jpg" style="margin-right:0px">
 </div>

 <p>Ist die Scheibe eingesetzt, wird sie anschließend noch versilikoniert
 und das Fenster kann eingebaut werden.</p>

 <div>
    <img class="half_half mobile_wide mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/image025.jpg" style="margin-right: 8px;">
    <img class="half_half mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/image027.jpg" >
 </div>

 <p>
 Eines meiner Fenster kann sogar komplett geöffnet werden, ein anderes lässt
 sich kippen.</p>

 <h2>Sommer 2021</h2>

 <p>Die nächste große Aufgabe war die Konstruktion des Daches. Ich
 wollte die Dachschräge nicht direkt an den Boden des ersten Stocks anknüpfen
 lassen sondern etwas erhöht beginnen, um etwas mehr Raum zu schaffen. Deshalb
 mussten als erstes 8 kleine Stützen befestigt werden. Diese mussten natürlich
 perfekt im richtigen Winkel zurechtgesägt werden. Natürlich kann das nicht
 optimal klappen also musste ich viel feilen.<o:p></o:p></p>

 <p>Genauso war es mit den großen Sparren (also die Dreiecke), denn
 damit die Konstruktion gut hält, sollten sie oben ineinandergesteckt sein.</p>

 <div>
    <img class="half_half mobile_wide mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/image029.jpg" style="margin-right: 8px;">
    <img class="half_half mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/image031.jpg" >
 </div>

 <p>Auch diesen Sommer hatte ich wieder Hilfe.</p>

 <img class="collage3 mobile_wide mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/image033.jpg" style="margin-right:8px">
 <img class="collage3 mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/image035.jpg">

 <p>Und auch dieses Jahr habe ich wieder eine Party veranstaltet mit
 ganz vielen Lichtern!</p>

 <p>
 <img class="collage4 mobile_wide mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/image036.jpg" style="margin-right:8px">
 <img class="collage4 mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/image038.jpeg"></p>

 <h2>Herbst und Winter 2021</h2>

 <p>Ich hatte mir eigentlich vorgenommen, bis zum Winter das Dach
 fertig zu bauen, damit es nicht mehr rein regnet. Leider habe ich das nicht geschafft.
 Wegen der komplizierten Geometrie mit dem Knick und den Dachgauben dauerte alles länger als gedacht.</p>

<h2>2022</h2>

 <p>
  <img src="@/assets/images/Tinyhouse1-Dateien/dreieck_fenster.jpg" id="dreieck">
  Seit dem Herbst 2021 studierte ich wieder in Vollzeit, sodass für das Tinyhouse nur wenig Zeit blieb.
 Aber dennoch habe ich über das ganze Jahr gesehen doch viel erreicht. Im Januar habe ich endlich die letzten drei Fenster für das
 Obergeschoss gebaut. Ganz besonders stolz bin ich auf das dreieckige Doppelfenster. Und die restliche Zeit brauchte ich tatsächlich,
 um das ganze Dach rundum mit Holzbrettern zu verkleiden, wobei jedes Brett individuell zugesägt werden musste.</p>

<div>
    <img class="collage1 mobile_wide  mobile_upper" src="@/assets/images/Tinyhouse1-Dateien/haus_dach.jpg" style="margin-right: 8px;">
    <img class="collage1 mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/CE_Lagerfeuer.jpg" >
</div>

<p>
    So konnte ich im Herbst auch schon anfangen, das Dach mit Dachpappe abzudecken. Und natürlich gab es im Sommer wieder eine Tinyhouseparty!
</p>

<h2>2023</h2>
<p>
    Leider war das dach auch erst im Frühling des nächsten Jahres fertig mit Pappe abgedeckt. 
    Aber dafür konnte ich dann endlich damit anfangen, mich um die Fassade zu kümmern!<br>
    Ich hatte mir eine Fachwerkfassade vorgestellt mit braunen Holzbalken und weißem Putz dazwischen.
    Holz eignet sich eigentlich nicht gut als Untergrund für Putz, aber wenn schon Holz, dann sind 
    OSB-Platten am besten dafür. Daher kleidete ich als nächstes das Haus mit solchen Platten ein, bevor 
    ich das Fachwerk anbrachte. Dann Strich ich es in einem schönen Braunton an.
</p>
<div>
    <img id="upper1" class="half_half mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/haus_pappe0.jpg" style="margin-right: 8px;">
    <img class="half_half mobile_wide" src="@/assets/images/Tinyhouse1-Dateien/haus_pappe.jpg" >
</div>
<div>
    <img src="@/assets/images/Tinyhouse1-Dateien/haus_fachwerk.jpg" style="height: 645px; margin: 4px 8px -8px 0;" >
    <img src="@/assets/images/Tinyhouse1-Dateien/haus_gestrichen.jpg" style="height: 645px; margin: 4px 0 -8px 0;">
</div>
 <p>
 Momentan bin ich dabei, das Haus zu verputzen. Wenn ich damit fertig bin, kann ich endlich auch mal 
 anfangen, eine Tür für das Haus zu bauen, damit man es abschließen kann. 
 Danach werde ich die Fliesen für den Boden legen und die Betonwände
 innen verputzen. Danach wiederum werde ich das Haus noch dämmen und von innen
 mit Holz verkleiden. Zwischendurch werde ich auch noch irgendwann den Zwischenboden
 bauen und die Elektrik und das Wasser verlegen müssen. Und erst danach kann ich
 mir Gedanken um die Einrichtung machen…</p>

 <p>Wie ihr seht, ist es also noch ein langer Weg. Aber ihr seid alle
 gerne eingeladen, mich besuchen zu kommen und mitzuhelfen!</p>

</div>
</template>

<style scoped>
p{font-size:14.5pt;line-height:115%;font-family:Arial,sans-serif;}

.side-img1{
    width:289px;
    padding-left: 5px
}

.side-img2{width:286px;}

.half_half{
    width:423px;
}

.collage1{
    height:272px;
}

.collage2{
    height:301px;
    margin-right:8px
}

.collage3{
    height:259px;
}

.collage4{
    height:412px;
}

#side-div{float:right;}

#side-div2{float:right; margin: 20px 0px 0px 10px}

#stein{
    height:311px; padding-left: 10px; float:right;
}

#dreieck{
    width:346px; float:right; margin-left:12px; margin-right:2px;
}

#tilted-image{margin: 27px 0 0 20px;}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    p{font-size:35px;line-height:115%;font-family:Arial,sans-serif;}

    .side-img1{
        width:507px;
        padding-left: 5px
    }

    .side-img2{width:855px;}

    .collage2{
        width:auto;
        height:636px;
        margin-bottom: 4px;
    }

    #collage2u{
        height:auto;
        width: 855px;
    }

    .mobile_wide{
        height:auto;
        width:855px;
    }

    .mobile_upper{
        margin-bottom: 8px;
    }

    #stein{
        height:542px;
        float:none;
        padding-left: 0px;
    }

    #side-div2{float:none; margin: 40px 0px 0px 0px}

    #tilted-image{width:855px; height:auto; margin-top:40px; margin-bottom: 30px;}

    #dreieck{
        width:855px; margin-bottom:20px;
    }

    #upper1{
    margin-bottom: 4px;
}
}
</style>
