<template>
  <div id="Tabelle" style="position:absolute; ">
    <table>
      <tr>
        <td class="td"><router-link to="/philosophie" style="color:white; text-decoration: none">Philosophie</router-link></td>
      </tr>
      <tr>
        <td class="td"><router-link to="/gesellschaft" style="color:white; text-decoration: none">Gesellschaft</router-link></td>
      </tr>
    </table>
   </div>
  <div style="position:relative; width:835px; margin: 0px 0px 0px 50px; font-size:19px; text-align:justify">
    <p style='padding-top:10px;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:100%;font-size:15px;font-family:"Calibri","sans-serif";text-align:right;'><span style="font-size: 17px; line-height: 100%;">12.01.2021</span></p>
  <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style="font-size: 28px; line-height: 115%; color: rgb(71, 36, 122);">Gendern - Das Problem liegt in den K&ouml;pfen der Menschen, nicht in der deutschen Sprache</span><span style="font-size:24px;line-height:115%;color:#47247A;"><br></span><span style="font-size: 22px; line-height: 115%; color: rgb(71, 36, 122);"></span></strong><span style="font-size: 22px;"><strong><em><span style="line-height: 115%; color: rgb(71, 36, 122);">&minus;</span></em></strong></span><strong><em><span style="font-size: 22px; line-height: 115%; color: rgb(71, 36, 122);"> Wege, wie man es auf eine andere Weise l&ouml;sen kann</span><span style="font-size: 20px; line-height: 115%; color: rgb(71, 36, 122);"></span></em></strong></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px; line-height: 115%;">Nun ist es offiziell: letzte Woche hat der Duden, zumindest in seiner Online-Version, die Allgemeinform f&uuml;r Personenbezeichnungen abgeschafft. Fortan ist ein Fleischer nicht mehr &bdquo;jemand, der Vieh schlachtet&ldquo;, sondern ausdr&uuml;cklich eine &bdquo;m&auml;nnliche Person, die Vieh schlachtet&ldquo;.</span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Bevor ich in irgendwelche Schubladen gesteckt werde, m&ouml;chte ich klarstellen: Ich bin 20 Jahre alt, queer und politisch eher gr&uuml;n-links orientiert. Und dennoch sehe ich in dem Trend, der gerade besonders in meinem Milieu immer beliebter wird, eine wachsende Problematik.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Das Grundproblem ist nicht schwer zu verstehen: Wenn von Sch&uuml;lern, &Auml;rzten oder Professoren die Rede ist - also von Menschen, die zu bestimmten Personengruppen geh&ouml;ren, haben viele Menschen ein Bild von einer Gruppe von M&auml;nnern im Kopf. Dies ist ein Fakt der nicht abzustreiten ist. Schuld ist das sogenannte <em>generische Maskulinum</em>. Es ist in seiner Bildung identisch zu den m&auml;nnlichen Formen, es hat jedoch einen gravierenden Unterschied: es schlie&szlig;t alle Menschen ein, unabh&auml;ngig des Geschlechts.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 20px;"><strong><span style="line-height: 115%; color: rgb(71, 36, 122);">Das grammatikalische Geschlecht ist nicht gleichzusetzten mit dem tats&auml;chlichen Geschlecht</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">F&uuml;r viele ist es frustrierend, dass diese (scheinbar) m&auml;nnlichen Formen jahrelang Normalit&auml;t waren und es im allt&auml;glichen Sprachgebrauch immer noch sind. Die Deutsche Sprache sei &uuml;berf&uuml;llt von viel zu viel M&auml;nnlichkeit und &uuml;berwundenen Stereotypen und lie&szlig;e keinen Platz f&uuml;r Randgruppen.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Doch die wenigsten bemerken, dass hierbei ein subtiler, jedoch entscheidender Punkt &uuml;bersehen wird: das grammatikalische Geschlecht (Genus) ist nicht gleichzusetzen mit dem tats&auml;chlichen Geschlecht eines Subjekts.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Gerade im Deutschen ist dies eigentlich so deutlich wie in kaum einer anderen Sprache. So sind schon die Artikel in der deutschen Sprache ziemlich willk&uuml;rlich verteil. Warum sollte die T&uuml;r weiblich sein, aber der Schrank m&auml;nnlich, das Bett hingegen gar kein Geschlecht haben? - Es gibt keinen Grund, denn in Wahrheit haben <em>alle</em> diese Dinge kein Geschlecht, sie haben ein Genus.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Doch auch bei Personenbezeichnungen &uuml;bermitteln die Artikel <em>im Allgemeinen</em> keine Informationen &uuml;ber das Geschlecht einer Person.<br> Obwohl es beispielsweise &bdquo;<strong>der</strong> Mensch&ldquo; und &bdquo;<strong>die</strong> Person&ldquo; hei&szlig;t, sind weder mit dem einen nur M&auml;nner, noch mit dem anderen nur Frauen gemeint, denn <em>das Wort</em> &bdquo;Person&ldquo; ist weiblich, aber keinesfalls deswegen auch die Person <em>selbst</em>.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Noch willk&uuml;rlicher wird es bei der Bildung des Plurals oder des Dativ:</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Aus &bdquo;<strong>der</strong> Junge&ldquo; wird pl&ouml;tzlich &bdquo;<strong>die</strong> Jungen&ldquo; und wenn es in einem Satz noch um &bdquo;<strong>die</strong> Frau&ldquo; geht, ist im n&auml;chsten Satz schon von &bdquo;<strong>der</strong> Frau&ldquo; die Rede.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Hiermit scheint niemand ein Problem zu haben, das ist nun mal die deutsche Grammatik. Doch wenn es um den Plural mit der -er-Endung geht, wie bei &bdquo;Sch&uuml;ler&ldquo;, &bdquo;Lehrer&ldquo;, &bdquo;Mitarbeiter&ldquo;, so klingen diese W&ouml;rter pl&ouml;tzlich viel zu m&auml;nnlich und w&uuml;rden Frauen und Andere ausschlie&szlig;en.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 20px;"><strong><span style="line-height: 115%; color: rgb(112, 48, 160);">Wir m&uuml;ssen das Verst&auml;ndnis der W&ouml;rter erweitern, nicht die W&ouml;rter selbst</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Es ist jenes unterbewusste Missverst&auml;ndnis, das dringendst behoben werden sollte. Genauso wie bei &bdquo;die G&auml;ste&ldquo; (Einzahl: &bdquo;der Gast&ldquo;), das <em>Wort</em> zwar <em>grammatikalisch</em> maskulin ist, aber nicht die G&auml;ste selbst, so sollten doch auch &bdquo;die Sch&uuml;ler&ldquo; zumindest in der Pluralform auf die gleiche Weise verstanden werden. Wer sich in der heutigen Zeit unter &bdquo;Sch&uuml;lern&ldquo; eine reine Gruppe von Jungen vorstellt, lebt doch mental eindeutig noch im 18. Jahrhundert. (Dieses Problem l&auml;sst sich l&ouml;sen.)</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Wie oben schon gesehen, benutzen wir den Artikel &bdquo;die&ldquo; zum einen f&uuml;r <em>grammatikalisch</em> weibliche Formen, zum anderen aber auch f&uuml;r den Plural. Er kann also zwei vollkommen verschiedene Zwecke erf&uuml;llen.<br> Genauso kann doch auch der Artikel &bdquo;der&ldquo; verstanden werden. Wir benutzen ihn zwar zum einen auch als m&auml;nnlichen Artikel, zum anderen kann er jedoch auch im Kontext von &bdquo;der Nutzer&ldquo;, &bdquo;der Leser&ldquo; oder eben &bdquo;der Gast&ldquo; als ein allgemeines, <em>geschlechtsneutrales &bdquo;der&ldquo;</em>, losgel&ouml;st von jeglicher M&auml;nnlichkeit, verstanden werden.<br> Weitere Beispiele f&uuml;r das geschlechtsneutrale &bdquo;der&ldquo; sind Konstruktionen, wie &bdquo;jemand, der&ldquo; oder &bdquo;jeder, der&ldquo;, bei denen schlicht &bdquo;jemand&ldquo;, also eine beliebige Person, oder &bdquo;jeder&ldquo;, wortw&ouml;rtlich <em>jede</em> Person gemeint ist.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Das generische Maskulinum wird oft missverstanden als: &bdquo;Die M&auml;nner werden genannt, aber die anderen sind mitgemeint.&ldquo; Tats&auml;chlich ist es aber so, dass Artikel wie &bdquo;der&ldquo;, &bdquo;dem&ldquo; und Endungen wie &bdquo;-er&ldquo; in unserer Sprache auf eine <em>geschlechtsneutrale</em> Weise verwendet werden k&ouml;nnen. Die Bezeichnung &bdquo;generisches Maskulinum&ldquo; f&uuml;hrt daher in die Irre: man sollte eigentlich von einer <em>generischen Form</em> sprechen.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 20px;"><strong><span style="line-height: 115%; color: rgb(71, 36, 122);">Gendern verst&auml;rkt das Problem nur</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Selbstverst&auml;ndlich sind kreative Formen wie Lehrer*innen oder B&uuml;rger:innen eine hilfreiche und schnelle L&ouml;sung, sofort ein Bild von einem ausgewogenem Geschlechterverh&auml;ltnis zu vermitteln, aber sie bringen doch einige Nebenwirkungen mit sich.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Durch den immer h&auml;ufigeren Gebrauch von Paarformen und Suffixen entsteht eine klare semantische Trennung zwischen Begriffen, wie &bdquo;Lehrer&ldquo; und &bdquo;Lehrerinnen&ldquo;: ersteres sind jetzt nur noch die M&auml;nner und letzteres sind nur noch die Frauen (so, wie der Duden es jetzt &uuml;bernommen hat.)</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Die alte (und vorher praktisch geschlechtsneutrale) generische Form wird also zur reinen m&auml;nnlichen Form degradiert und vorher sehr allgemeine Begriffe, wie &bdquo;Leser&ldquo;, &bdquo;Nutzer&ldquo;, &bdquo;Spieler&ldquo; oder sogar &bdquo;Freunde&ldquo; f&uuml;hlen sich pl&ouml;tzlich seltsam m&auml;nnlich an. (Wollte man nicht genau das vermeiden?)<br>Verst&auml;ndlich, dass man dann auch hier pl&ouml;tzlich die Notwenigkeit sieht, in Spielanleitungen von &bdquo;dem Spieler oder der Spielerin&ldquo; zu schreiben oder zu spezifizieren, dass man zu seiner Geburtstagsfeier nicht nur seine Freunde, sondern selbstverst&auml;ndlich auch seine Freundinnen einl&auml;dt.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Ist die generische Form abgeschafft, so ist es nun gar nicht mehr m&ouml;glich, allgemein von einer Person eines bestimmten Berufs oder mit einer bestimmten Eigenschaft zu sprechen - selbst in Kontexten, in denen das Geschlecht vollkommen belanglos ist und &uuml;berhaupt nicht Teil der Information sein soll, die man &uuml;bermitteln m&ouml;chte, ist man nun <em>gezwungen</em>, die eigentlich belanglose Komponente Geschlecht miteinzubeziehen, da unter Architekten jetzt <em>erst recht</em> nur noch M&auml;nner verstanden werden.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Es gibt nun offiziell keine Sch&uuml;ler im Allgemeinen mehr, es gibt nur noch <em>entweder</em> Sch&uuml;lerinnen <em>oder</em> Sch&uuml;ler, zwei von nun an verschiedene Dinge. Ein B&uuml;rger ist nun nie mehr dasselbe, wie eine B&uuml;rgerin und eine Frau kann nicht einfach mehr schlicht ein Chef sein, sie ist jetzt eine Chefin, mit der Betonung auf Vagina.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">F&uuml;r gender-non-konforme oder diverse Personen ist dies nun besonders unvorteilhaft, da man sich nun nicht mehr einfach als Student (oder Studierender) bezeichnen kann, ohne dass sich das Wort auf einmal so unangenehm m&auml;nnlich und falsch anf&uuml;hlt; auch ein Sternchen im Wort als Trost &auml;ndert dort nicht viel dran. Stattdessen muss dann auf Umschreibungen ausgewichen werden, wie &bdquo;ich studiere&ldquo;.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Ist das wirklich, was wir wollen? Warum haben wir diesen zwingenden Drang, <em>unbedingt</em> das Geschlecht einer Person in den Vordergrund zu r&uuml;cken? Ist uns das so wichtig, das <em>mit jedem Mal</em> zu betonen?</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Man stelle sich nur vor, man w&uuml;rde pl&ouml;tzlich jede andere menschliche Eigenschaft in die Sprache mit aufnehmen, beispielsweise die Hautfarbe, Herkunft oder Sexualit&auml;t.<br>Schwarze, Asylanten oder Homosexuelle werden in Deutschland viel st&auml;rker Diskriminiert als Frauen. Unter dem Begriff &bdquo;Arzt&ldquo; w&uuml;rde man sich m&ouml;glicherweise noch viel weniger einen gefl&uuml;chteten Syrer vorstellen, als eine Frau. Doch es w&auml;re die H&ouml;lle los, wenn wir pl&ouml;tzlich anfangen w&uuml;rden, von Asylanten-&Auml;rzten, Schwarz- und Wei&szlig;mitarbeitern oder Professor-Schwulen zu reden.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">In unserer Gesellschaft sind wir endlich so weit gekommen, Menschen nicht mehr nach oberfl&auml;chlichen Eigenschaften zu diskriminieren und zu segregieren, aber bei dem Geschlecht entwickelt sich hingegen dieser <em>Zwang</em>, das &uuml;berall und st&auml;ndig zu betonen.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Ich finde &uuml;brigens auch, dass Innenarchitekten viel zu sehr im &Uuml;berbegriff &bdquo;Architekten&ldquo; untergehen. Meiner Meinung nach sollte man sie durch die Schreibweise Architekt*innen*au&szlig;en sichtbarer machen.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 20px;"><strong><span style="line-height: 115%; color: rgb(71, 36, 122);">Der Englische Ansatz</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Wir haben also die falsche Herangehensweise gew&auml;hlt. Das Geschlecht von Personen sollte nicht in der Sprache betont werden, bis es zur Unverzichtbarkeit wird und es zu einer nicht mehr zu schlie&szlig;enden Trennung kommt, sondern es sollte auf anderen Wegen deutlich gemacht werden, dass inzwischen auch sehr viele Frauen Architekten, Ingenieure oder Chefs sind. Und ebenso nat&uuml;rlich auch umgekehrt, dass immer h&auml;ufiger M&auml;nner Erzieher oder Krankenpfleger sind.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Dieser Trend ist im Englischen der Fall. Da dort unter &bdquo;engineer&ldquo; typischerweise ein Mann und unter &bdquo;nurse&ldquo; (Krankenpfleger) typischerweise eine Frau unterbewusst verstanden wurden, ist heute der Trend, beispielsweise in Pr&auml;sentationen, Zeitungsartikeln oder Nachrichtensendungen, h&auml;ufiger eine Frau als Darstellung beizuf&uuml;gen, wenn beispielsweise von Ingenieuren die Rede ist, oder das Pronomen &bdquo;she&ldquo; zu verwenden, oder noch besser das <em>singular they</em> - das geschlechtsneutrale Pronomen des Englischen.<br> Dies schafft mehr Selbstverst&auml;ndlichkeit daf&uuml;r, dass unter historisch rein m&auml;nnlichen Berufen heutzutage alle Menschen miteinbezogen sind.<br>Ein weiterer Trend ist, bereits gegenderte Bezeichnungen, wie &bdquo;actress&ldquo; (Schauspielerin) statt &bdquo;actor&ldquo;, sogar zu vermeiden, damit gar nicht erst ein Unterschied geschaffen wird. Etwas zu gendern, ist im Englischsprachigen Raum also sogar etwas Negatives.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Nicht nur das Englische zeigt, dass es in einer Sprache nicht n&ouml;tig ist, st&auml;ndig auf das Geschlecht zu verweisen: Das Ungarische, zum Beispiel, kennt nicht einmal eine Unterscheidung der 3. Person Singular in er/sie/es.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 20px;"><strong><span style="line-height: 115%; color: rgb(71, 36, 122);">Ein neues Konzept f&uuml;r Deutschland?</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Wir sollten unsere Vorstellung von Gleichberechtigung und unseren Ansatz, diese in der Gesellschaft umzusetzen, &uuml;berdenken. Das Geschlecht sollte keine Rolle in auch nur irgendeinem Bereich der Gesellschaft spielen und sollte daher auch nicht sprachlich hervorgehoben werden m&uuml;ssen.<br>Wir m&uuml;ssen stattdessen eine Selbstverst&auml;ndlichkeit daf&uuml;r schaffen, dass auch Frauen Lehrer, &Auml;rzte oder Ingenieure sind (und zwar sehr viele).</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Der erste Schritt ist, den Unterschied zwischen dem Genus (dem grammatikalischen Geschlecht) und dem tats&auml;chlichen Geschlecht bewusster zu machen.<br> Als n&auml;chstes m&uuml;ssen die Bezeichnungen &bdquo;Lehrer&ldquo;, &bdquo;&Auml;rzte&ldquo;, &bdquo;Architekten&ldquo;, etc. ihre M&auml;nnlichkeit verlieren, denn <strong>die Bedeutung von W&ouml;rtern ergibt sich aus der Weise, wie wir sie benutzen.</strong></span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Frauen k&ouml;nnten anfangen, sich selbst wieder Verk&auml;ufer, Student oder Designer zu nennen. Die Endsilbe &bdquo;-in&ldquo; k&ouml;nnte weiter optional verwendet werden, wenn man wirklich spezifizieren m&ouml;chte, dass es sich um eine Frau handelt oder wenn man einfach f&uuml;r sich gerne diese Form benutzt.<br>Eine rein m&auml;nnliche Endung g&auml;be es dann einfach nicht, oder aber man w&uuml;rde mit der Zeit eine konstruieren, indem z.B. ein zus&auml;tzliches &bdquo;-er&ldquo; an das Wort angeh&auml;ngt wird (bsp. &bdquo;B&auml;ckerer&ldquo;), um zu spezifizieren, dass ein m&auml;nnlicher B&auml;cker gemeint ist.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Vielleicht wird es aber auch in Zukunft sogar als sexistisch aufgefasst, &uuml;berhaupt das Geschlecht in der Sprache zu spezifizieren.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Es ist m&ouml;glich, dass es daf&uuml;r jedoch schon zu sp&auml;t ist und sich kein M&auml;dchen mehr als &bdquo;Sch&uuml;ler&ldquo; bezeichnen m&ouml;chte. Dann muss der Weg freiger&auml;umt werden f&uuml;r neue, <em>wahre</em> geschlechtsneutrale Bezeichnungen, wie etwa &bdquo;Lehra&ldquo;, &bdquo;Mitarbeiton&ldquo; oder &bdquo;Studenti&ldquo; - nicht welche, die von Geschlecht nur so triefen, wie &bdquo;Lehrer*innen&ldquo;.</span></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><strong><span style="line-height: 115%; color: rgb(112, 48, 160);">Danke, dass du bis hierhin gelesen hast!</span></strong></span></p>
  <p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Wer zumindest f&uuml;r ein paar meiner Argumente Verst&auml;ndnis gefunden hat, dem lege ich diesen <a href="https://www.tagesspiegel.de/kultur/deutschland-ist-besessen-von-genitalien-gendern-macht-die-diskriminierung-nur-noch-schlimmer/26140402.html">Artikel vom Tagesspiegel</a> ans Herz.
  Er ist sogar von einer echten Frau geschrieben und dr&uuml;ckt das, was ich ausdr&uuml;cken m&ouml;chte nochmals bei weitem treffender und aus ihrer Perspektive aus.</span></span></p>
  <p style="margin: 0cm 50px 10pt; font-size: 15px; font-family: Calibri, sans-serif; text-align:right;"><span style="font-size: 18px;"><span style="line-height: 115%;  color:grey;"><em>&minus; Alex Zieschang</em></span></span></p>
  <div style="background-color: #EEEEFF; margin: 0px 20px 0px 20px">
    <p style="background-color: #CCCCFF; padding: 5px; margin-bottom:-15px">Kommentar von Isabelle S.</p>
    <div style="padding: 10px; margin-bottom:15px">
      <p style="margin-bottom:0px">Ich habe gerade deinen Blogartikel gelesen und ich stimme dir absolut zu! Wenn auch, ehrlich gesagt, ohne grammatikalische oder sonstige wirkliche Begr&uuml;ndung finde ich, es gibt einfach Wichtigeres, als sich dauernd neue Begriffe f&uuml;r Unterschiede auszudenken. Das hilft nicht gegen Diskriminierung (auch wenn es den Schein hat oder als Ausrede ganz okay ist). Stattdessen k&ouml;nnte man tats&auml;chlich versuchen, irgendwelche der (tats&auml;chlichen) Probleme zu l&ouml;sen...<br>
  Und irgendwann wird es auch einfach zu sperrig. Ich kann zwar verstehen, dass man neue Begriffe schafft, wenn Personengruppen es fordern; es ist immerhin recht wenig Arbeit und die, die es fordern, geben Ruhe. Bei Sachen wie Krankenschwester halte ich es auch f&uuml;r notwendig. Dass aber am Ende in den Elternbriefen &uuml;berall &#8222;die Sch&uuml;lerin oder der Sch&uuml;ler&ldquo; stand, halte ich pers&ouml;nlich f&uuml;r eher unn&ouml;tig, vor allem, da das noch immer nicht unbedingt alle einschlie&szlig;t.<br>
  Und wie du bereits sagst: Es ist letzten Endes unm&ouml;glich, alles und jedem einen eigenen Namen zu geben, der dann s&auml;mtliche Eigenschaften von Geschlecht &uuml;ber Hautfarbe, Orientierung, Herkunft und wer wei&szlig; was noch beinhaltet.<br>
  Wenn man eine Person anspricht, darf man gerne entsprechende Artikel und Formen verwenden (die Idee mit dem zus&auml;tzlichen &#8222;-er&ldquo; gef&auml;llt mir), aber die m&auml;nnliche - eigentlich generelle - Form auch bei gemischten Gruppen oder eben einer unbekannten Person, wie beim Elternbrief, nur noch auf die M&auml;nner zu beziehen, finde ich schade.<br>
  Ironischerweise vor allem in Situationen, in denen wir Frauen zum Beispiel unterrepr&auml;sentiert sind, w&uuml;rde ich mir eine einheitliche Form f&uuml;r alle w&uuml;nschen. Sonst wird n&auml;mlich hervorgehoben, dass das nicht die Norm ist, und anstatt dass sich irgendwas normalisiert, werden die Unterschiede nur umso deutlicher. Dann bleibt der Informatiker n&auml;mlich m&auml;nnlich und ich bin pl&ouml;tzlich die Informatikerin - die, die von der Norm abweicht und nicht eben einfach ein(e) Informatiker(in) ist.<br>
  Ich sage auch lieber, dass ich Informatiker oder Programmierer bin, obwohl ich inzwischen sage, dass ich Studentin bin. Wahrscheinlich, weil ich mich einfach bei Sch&uuml;lerin und Studentin schon daran gew&ouml;hnt habe und es etwas Allt&auml;gliches ist, dass ein M&auml;dchen zur Schule geht, w&auml;hrend man Informatikerin eben nicht so h&auml;ufig h&ouml;rt.<br>
  Und die ganzen Begriffe &auml;ndern leider nichts daran, dass man bei Informatiker an Mann und bei Informatikerin an Ausnahme denkt.<br>
  Die Ausnahmen in mindestens gleichen Teilen auf Fotos oder &Auml;hnlichem abzubilden (wie beim Englischen Ansatz), damit sich ein normaleres Bild im Kopf festsetzt, scheint mir ein besserer Weg zu sein, wenngleich z.B. bei den Gruppenfotos der tats&auml;chlichen Mitarbeiter die Unterschiede weiter deutlich sichtbar w&auml;ren.<br>
  Und an Ausgrenzung, ungleicher Bezahlung usw. &auml;ndert es weiterhin nichts.<i> Jedoch lenken Diskussionen &uuml;ber Sprache und Ausdrucksweise z.T. gekonnt von so etwas ab.</i><br>
  Ein Artikel (<a href="https://www.swr3.de/aktuell/psychologeek-gendern-nervig-oder-notwendig-102.html">Gendern &ndash; nervig oder notwendig? - SWR3</a>), der Argumente f&uuml;r das Gendern liefern soll, beweist f&uuml;r mich gerade dessen Unsinnigkeit.<br>
  Erst einmal: dass Gendern als &#8222;ein sprachliches Verfahren, um die gleiche und faire Behandlung von Frauen und M&auml;nnern im Sprachgebrauch zu erreichen&ldquo; erkl&auml;rt wird, und als eine M&ouml;glichkeit des Genderns das Verwenden der m&auml;nnlichen und weiblichen Form, also &#8222;Studenten und Studentinnen&ldquo; statt Student vorgeschlagen wird, ist ein perfektes Beispiel daf&uuml;r, dass unterm Gendern h&auml;ufig jene zu leiden haben, die nicht in das bin&auml;re Geschlechterbild hineinpassen und so pl&ouml;tzlich komplett ausgeschlossen sind.<br>
  Viel interessanter aber finde ich die Studie, die als Kernargument f&uuml;rs Gendern angef&uuml;hrt wird. Dabei wurden, so der Artikel, Schulkindern Berufe vorgestellt &ndash; den einen Kindern nur mit dem Maskulinum, den anderen mit m&auml;nnlicher und weiblicher Form. Das Ergebnis war, dass sich M&auml;dchen der zweiten Gruppe viel eher die Aus&uuml;bung &#8222;typisch m&auml;nnlicher&ldquo; Berufe zugetraut haben und diese auch als leichter erlernbar wahrgenommen haben.<br>
  Aber das ist doch komplett logisch! Angenommen, wir haben zwei Freundinnen im Grundschulalter, nennen wir sie Anna und Lisa, und Annas Mutter ist Polizistin. Dann wird wahrscheinlich keines der M&auml;dchen Polizist oder Arzt werden wollen. Denn sie sind dann so gepr&auml;gt, dass sie z.B. Polizist<b>in</b> oder &Auml;rzt<b>in</b> werden wollen. Das m&auml;nnliche Maskulinum muss sich doch befremdlich anf&uuml;hlen, wenn seit dem Erlernen der Sprache alle Frauen mit der weiblichen Form bezeichnet werden.<br>
  Wenn jedoch f&uuml;r alle Geschlechter das m&auml;nnliche Maskulinum als einheitliche Form verwendet werden w&uuml;rde und Lisas Mutter entsprechend Polizist ist, dann glaube ich nicht, dass die M&auml;dchen es als abwegig empf&auml;nden Polizist oder Arzt zu werden. Denn sie w&auml;ren so gepr&auml;gt, dass sie bei dem Wort &#8222;Polizist&ldquo; nicht unbedingt an einen Mann denken. Es kann genauso gut eine Frau sein. Oder weder noch.<br>
  Ich bin &uuml;berzeugt, unbewusst w&uuml;rden sich in diesem Szenario die M&auml;dchen nicht von dem m&auml;nnlichen Maskulinum ausgeschlossen, sondern mit eingeschlossen f&uuml;hlen, da sie niemals gelernt h&auml;tten, dass das nur eine Bezeichnung f&uuml;r M&auml;nner ist, wie es jetzt offiziell beim Duden der Fall ist.<br>
  Leider k&auml;mpfen manche Feminist(inn)en f&uuml;rs Gendern und investieren ihre Energie dort hinein, obwohl es doch wirklich wichtigere Dinge g&auml;be, f&uuml;r die man k&auml;mpfen k&ouml;nnte. N&auml;mlich die, die tats&auml;chlich eine Verbesserung der Lebensumst&auml;nde, des Alltags oder &Auml;hnliches hervorrufen!<br>
  Und wenn man bei Informatiker oder Student nat&uuml;rlicherweise an <i>alle</i> - nicht nur an M&auml;nner - denkt, dann ist die Chance gr&ouml;&szlig;er, dass man auch alle gleichbehandelt, als wenn man schon sprachlich genau differenziert und in Kategorien zuordnet, die f&uuml;r Vieles einfach komplett irrelevant sind.<br>
  Dass ich diese Meinung habe, war mir nicht bewusst, bis ich deinen Artikel gelesen hatte. Informatikerin hat sich vorher einfach irgendwie falsch angef&uuml;hlt und ich danke dir, dass du mich zum Nachdenken angeregt hast und ich nun gemerkt habe, warum es sich nicht richtig anf&uuml;hlt.<br>
  Ich pers&ouml;nlich m&ouml;chte jetzt anfangen, f&uuml;r mich und diejenigen, die es w&uuml;nschen, explizit die neutrale, &#8222;m&auml;nnliche&ldquo; Form zu verwenden und nicht nur ein stolzer Informatiker, sondern auch ein &uuml;berzeugter Student zu sein.<br>
  </p>
  </div>
</div>
<p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%;">Kommentare und Antworten bitte an:</span></span></p>
<p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px;"><span style="line-height: 115%; color:blue;">contact@alexadventures.de</span></span></p>
<p style="margin: 0cm 0cm 10pt; font-size: 15px; font-family: Calibri, sans-serif; line-height: 1.5;"><span style="font-size: 19px; line-height: 115%;">Auf Wunsch werde ich diese hier, gerne auch anonym, ver&ouml;ffentlichen.</span></p>
</div>
</template>
<style scoped>
#Tabelle {

width:140px;
padding: 10px;
color:white;
margin: -10px 0 0px -180px;
font-size:20px;
 }
.td {
color: white;
background: #193f7d;
padding: 5px 10px 5px 10px;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #Tabelle {
    display: none;
  }
}
</style>
