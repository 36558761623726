<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:21px; text-align:justify">
    <p style="font-size:24px;"><b>Das Beet 2019 - Spargel</b></p>
    <p style="font-size:21px;text-align:justify">
      Im Jahr 2019 begann ich nun richtig mit meinem ambinionierten Projekt, Spargel anzupflanzen. Zun&auml;chst einmal mussten jedoch die richtigen Wuchsbedingungen geschaffen werden.
      Dazu muss ein Graben von 20-30 cm L&auml;nge und 40 cm Breite ausgehoben werden. Dieser sollte m&ouml;glichst in Nord-S&uuml;d-Richtung verlaufen, damit sich die sp&auml;teren Spargeld&auml;mme
      gleichm&auml;&szlig;ig erw&auml;rmen. Der Graben muss nun mit 10 cm Mist und Kompost aufgef&uuml;llt werden.
    </p>
    <img src="@/assets/images/garten/Spargel1.png" alt="" title="" style="width: 700px;"/>
    <p>
      Darauf kommt nun noch eine 5 cm dicke Humusschicht. Auf diese werden nun die Pflanzen mit einem Abstand von 30 cm gesetzt und mit Erde zugesch&uuml;ttet.
    </p>
    <img src="@/assets/images/garten/Spargel2.png" alt="" title="" style="width: 700px;"/>
    <p>
      Der Anbau von Spargel ist eine langwierige Angelegenheit, die sich jedoch auszahlt. Im ersten und im zweiten Jahr darf nicht geerntet werden, da die Pflanzen erst kr&auml;ftig
      genug werden m&uuml;ssen, um durch die Ernte nicht besch&auml;digt zu werden. Die ernte im dritten Jahr f&auml;llt mager aus, daf&uuml;r hat man aber die darauffolgenden 15 Jahre lang
      jeden Fr&uuml;hling Freude an einer vollen Ernte.
    </p>
    <router-link to="/garten/beet2020">
     <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 0 0 25px 0; width: 150px;"/>
   </router-link>
  </div>
</template>
