<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:21px; text-align:justify">
      <p style="font-size:24px"><b>Das Beet 2020</b></p>
      <p style="font-size:21px;text-align:justify">
      Ich bin sehr gl&uuml;cklich dar&uuml;ber, dass die Spargelpflanzen dieses Jahr wieder gekommen sind. Sie werden dieses Jahr
      in Ruhe gelassen, damit sie n&auml;chstes Jahr stark genug f&uuml;r die Ernte sind. <br>
      Im Fr&uuml;hjahr habe ich Salat, Karotten und Spinat eingeplanzt. Davon ist leider bis auf etwas Salat nichts gekommen. Daf&uuml;r sind dieses Jahr so viele Pflanzen gekommen,
      die ich nicht ausges&auml;ht habe. Petersilie und Zitronenmelisse gedeihen pr&auml;chtig und auch Tomaten sind aus dem Nichts gewachsen und
      spenden uns nun im September ihre reifen Fr&uuml;chte!
      </p>
      <img src="@/assets/images/garten/Beet2020.jpg" style="display: block;width:600px; margin-left: auto; margin-right: auto; margin-bottom:30px"/>
      <router-link to="/garten/beet2021">
       <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 0 0 25px 0; width: 150px;"/>
     </router-link>
   </div>
</template>
