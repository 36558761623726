import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'
import projekte from '../views/projekte/index'
import bachboot from '../views/projekte/bachboot'
import tinyhouse from '../views/projekte/tinyhouse'
import ukulele from '../views/projekte/ukulele'
import ueberMich from '../views/ueberMich/index'
import buecher from '../views/ueberMich/buecher'
import zamonien from '../views/ueberMich/zamonien'
import steckbrief from '../views/ueberMich/steckbrief'
import reisen from '../views/reisen/index'
import nordsee from '../views/reisen/berlin-hamburg-nordsee'
import belgien from '../views/reisen/belgien'
import daenemark from '../views/reisen/daenemark'
import interrail from '../views/reisen/interrail'
import holland from '../views/reisen/holland'
import foehr from '../views/reisen/foehr'
import impressum from '../views/impressum'
import beet2021 from '../views/garten/beet2021'
import beet2020 from '../views/garten/beet2020'
import beet2019 from '../views/garten/beet2019'
import beet2018 from '../views/garten/beet2018'
import beet2017 from '../views/garten/beet2017'
import beet2016 from '../views/garten/beet2016'
import beet2015 from '../views/garten/beet2015'
import loch from '../views/garten/loch'
import sonnenbeet from '../views/garten/sonnenbeet'
import hochsitz from '../views/garten/hochsitz'
import menschundwelt from '../views/mensch-und-welt/index'
import philosophie from '../views/mensch-und-welt/philosophie'
import gesellschaft from '../views/mensch-und-welt/gesellschaft'
import gendern from '../views/mensch-und-welt/gendern'
import bootparabel from '../views/mensch-und-welt/bootparabel'
import russian1 from '../views/Learn-Russian/learn-russian1'
import russian2 from '../views/Learn-Russian/learn-russian2'
import russian3 from '../views/Learn-Russian/learn-russian3'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ueber-mich',
    name: 'UeberMich',
    component: ueberMich
  },
  {
    path: '/buecher',
    name: 'Buecher',
    component: buecher
  },
  {
    path: '/buecher/zamonien',
    name: 'zamonien',
    component: zamonien
  },
  {
    path: '/ueber-mich/steckbrief',
    name: 'steckbrief',
    component: steckbrief
  },
  {
    path: '/projekte',
    name: 'Projekte',
    component: projekte
  },
  {
    path: '/projekte/bachboot',
    name: 'Bachboot',
    component: bachboot
  },
  {
    path: '/projekte/tinyhouse',
    name: 'tinyhouse',
    component: tinyhouse
  },
  {
    path: '/projekte/ukulele',
    name: 'ukulele',
    component: ukulele
  },
  {
    path: '/garten',
    redirect: '/garten/beet2021'
  },
  {
    path: '/garten/beet2021',
    name: 'beet2021',
    component: beet2021
  },
  {
    path: '/garten/beet2020',
    name: 'beet2020',
    component: beet2020
  },
  {
    path: '/garten/beet2019',
    name: 'beet2019',
    component: beet2019
  },
  {
    path: '/garten/beet2018',
    name: 'beet2018',
    component: beet2018
  },
  {
    path: '/garten/beet2017',
    name: 'beet2017',
    component: beet2017
  },
  {
    path: '/garten/beet2016',
    name: 'beet2016',
    component: beet2016
  },
  {
    path: '/garten/beet2015',
    name: 'beet2015',
    component: beet2015
  },
  {
    path: '/garten/loch',
    name: 'loch',
    component: loch
  },
  {
    path: '/garten/sonnenbeet',
    name: 'sonnenbeet',
    component: sonnenbeet
  },
  {
    path: '/garten/hochsitz',
    name: 'hochsitz',
    component: hochsitz
  },
  {
    path: '/reisen',
    name: 'Reisen',
    component: reisen
  },
  {
    path: '/reisen/berlin-hamburg-nordsee',
    name: 'nordsee',
    component: nordsee
  },
  {
    path: '/reisen/belgien',
    name: 'belgien',
    component: belgien
  },
  {
    path: '/reisen/daenemark',
    name: 'daenemark',
    component: daenemark
  },
  {
    path: '/reisen/interrail',
    name: 'interrail',
    component: interrail
  },
  {
    path: '/reisen/holland',
    name: 'holland',
    component: holland
  },
  {
    path: '/reisen/foehr',
    name: 'foehr',
    component: foehr
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: impressum
  },
  {
    path: '/mensch-und-welt',
    name: 'menschundwelt',
    component: menschundwelt
  },
  {
    path: '/philosophie',
    name: 'philosophie',
    component: philosophie
  },
  {
    path: '/gesellschaft',
    name: 'gesellschaft',
    component: gesellschaft
  },
  {
    path: '/gesellschaft/gendern',
    name: 'gendern',
    component: gendern
  },
  {
    path: '/gesellschaft/bootparabel',
    name: 'bootparabel',
    component: bootparabel
  },
  {
    path: '/learn-russian1',
    name: 'russian1',
    component: russian1
  },
  {
    path: '/learn-russian2',
    name: 'russian2',
    component: russian2
  },
  {
    path: '/learn-russian3',
    name: 'russian3',
    component: russian3
  }
  /*{ 
    path: '/:catchAll(.*)', 
    name: 'Home',
    component: Home
  }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
