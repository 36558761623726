<template>
  <div
    style="
      position: relative;
      width: 755px;
      margin: 0px 0px 0px 85px;
      text-align: justify;
    "
  >
  <img src="@/assets/images/holland/interrail+roadtrip.jpg"
  style="width: 930px; margin: 0 -65px -15px -85px"/>
    <h1>Roadtrip durch Holland und Friesland 2023</h1>
    <img src="@/assets/images/holland/fish_restaurant.jpg" id="nilsi"/>
    <p>
      Dies ist die Weiterführung meiner <router-link to="/reisen/interrail">Interrail-Reise</router-link>. 
      Als mich am 8. Tag Nils in Holland besuchen kam, verbrachten wir noch ein paar Tage gemeinsam in den 
      Niederlanden. Ich hatte mir hier zum Abschluss zwei Nächte in einem schönen, kleinen ländlichen Gasthaus 
      in einem kleinen Dorf in der Nähe der Stadt Gouda gebucht, um die letzten Tage entspannt in der holländischen, 
      sommerlichen Natur zu verbringen. Zum Glück war mein Zimmer ein Doppelzimmer, sodass noch Platz für Nils war.
      Als er am Abend des 8. Tages vorbeikam, sind wir direkt in die Dünen gefahren und aßen dort einen Fisch in 
      einem Strandrestaurant.
    </p>
    <h2>Tag 9</h2>
    <p>
      Heute war ein ereignisreicher Tag. Wir hatten um 9 Uhr Frühstück und ich hatte mir auch den Wecker 
      auf meiner Uhr gestellt. Leider ist über Nacht die Batterie leergegangen, also haben wir komplett 
      verschlafen und wurden um 10:15 Uhr von der Putzfrau geweckt. Fail… Aber Frühstück haben wir noch 
      bekommen. An dem Tag war echt schönes Wetter und ich hatte ziemlich Lust, Kanu zu fahren. Und tatsächlich 
      gab es in diesem Dorf einen Kanuverleih von wo aus man ein paar Kilometer einen hübschen Kanal 
      entlangfahren konnte. Und so fuhren wir bei strahlendem Sonnenschein mit dem Kanu los, vorbei an süßen 
      Häuschen, Weiden mit Kühen und Schafen und an einer Windmühle. Etwa eine Stunde später kamen wir dann 
      an unserem Ziel, einem Kleinen Café am Flüsschen, in dem wir uns stärkten. Und dann machten wir uns auch 
      wieder auf den Weg zurück.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/kanu3.jpg" style="height: 499px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/kanu2.jpg" style="height: 499px; margin: 0 8px 0 0"/>
    </div>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/kanu4.jpg" style="height: 279px; margin: 0 8px -4px 0"/>
      <img src="@/assets/images/holland/cute_nils.jpg" style="height: 279px; margin: 0 8px -4px 0"/>
      <img src="@/assets/images/holland/kanu5.jpg" style="height: 279px; margin: 0 8px -4px 0"/>
      <img src="@/assets/images/holland/kanu1.jpg" style="height: 279px; margin: 0 8px -4px 0"/>
    </div>
    <p>
      Am Nachmittag haben wir nochmal einen Abstecher nach Gouda gemacht, um noch mehr Käse zu kaufen.
      Es war sogar gerade Markt!
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/gouda1.jpg" style="height: 394px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/gouda2.jpg" style="height: 394px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/gouda3.jpg" style="height: 394px; margin: 0 8px 0 0"/>
    </div>
    <p>
      Abends wollten wir wieder ans Meer fahren, diesmal war unser Ziel Katwijk aan Zee. 
      Und Nils hat mich sogar dazu gebracht, ins kalte Wasser zu den starken Wellen zu gehen. 
      Und das war am Ende richtig toll. Leider konnten wir nicht lange in den Dünen bleiben, 
      weil es dann doch schnell kalt wurde. Also fuhren wir am Abend noch in ein Pfannekuchenhaus in Leiden. 
      Heute war wirklich ein erfolgreicher Tag.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/leiden1.jpg" style="height: 210px; margin: 0 8px 8px 0"/>
      <img src="@/assets/images/holland/leiden2.jpg" style="height: 210px; margin: 0 8px 8px 0"/>
    </div>
    <h2>Tag 10 - Amsterdam</h2>
    <p>
      Ich war lange nicht mehr in Amsterdam gewesen und Nils noch nie! Darum mussten wir unbedingt einen 
      Abstecher in die Hauptstadt machen. 
      Es war ein ziemlich regnerischer Tag, als wir aus dem kleinen Gasthaus auschekten und auf den Weg machten.
      Da wir mit dem Auto unterwegs waren, mussten wir es für die Nacht in Amsterdam irgendwo abstellen.
      Zum Glück gibt es in Amsterdam ein <span style="white-space:nowrap">Park&thinsp;&&thinsp;Ride</span> System, wo man in großen Parkhäusern an U-Bahn-Stationen für 6&thinsp;€ 
      parken kann, solange man nur 24&thinsp;h bleibt und mit der lokalen ÖPNV-Chipkarte in die Innenstadt und zurück fährt. 
      Diese OV-Chipkaart ist vor Ort erhältlich, mehr Informationen <a href="https://www.rai.nl/en/route/p-and-r">hier</a>.
    </p>
    <p>
      <img src="@/assets/images/holland/alex_telefoniert_mit_daddy.jpg" id="funny_alex"/>
      In Amsterdam hatten wir ein schönes Zweierbett im Hostel. Und auch nicht viel mehr, es war wirklich nur ein Bett in einer Kabine, 
      aber das hat uns gereicht. 
      Das Hostel war außerdem sehr zentral gelegen, direkt in der Innenstadt am Damrak.
      Wir checkten nur schnell ein und legten unsere Sachen ab und machten uns direkt auf in die Stadt. 
      Zunächst suchten wir uns ein Café, um zwei Stunden Homeoffice zu machen, denn Nils hatte sich 
      keinen Urlaub genommen. Ich holte mir zu Mittag ein großes Englisches Frühstück, weil ich in London echt 
      auf den Geschmack gekommen war.
      Als nächstes statteten wir dem Our House, einem Museum über House Music, einen Besuch ab. Dort 
      konnte man verschiedene Mischpulte ausprobieren, hat etwas über die Geschichte von House Music und andere elektronische 
      Musik gelernt und zum Schluss gab es eine Show in der die verschiedenen Epochen präsentiert wurden.
      Abends ruhten wir uns noch kurz im Hostel aus, denn wir hatten vor, abends Techno feiern zu gehen. 
      Bis 23&thinsp;Uhr mussten wir aber noch etwas Zeit vertreiben und etwas essen. Nachdem wir uns mit Burgern gestärkt 
      hatten, wollten wir noch dem Amaze Amsterdam &ndash; einer epischen Lichtershow &ndash; einen Besuch abstatten. 
      Als wir dann dort gegen 10 ankamen, mussten wir jedoch leider feststellen, dass wir vorher einen Termin 
      hätten buchen müssen, denn es waren keine Plätze mehr frei. Wir beschlossen dann, auf der Rückfahrt 
      nochmal über Amsterdam zu fahren und dann dorthin zu gehen.
      Abends gingen wir dann noch Techno feiern, für mich das erste Mal und es war richtig cool.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/house1.jpg" style="height: 261px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/house2.jpg" style="height: 261px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/house3.jpg" style="height: 261px; margin: 0 8px 0 0"/>
    </div>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/amsterdam4.jpg" style="height: 215px; margin: 0 9px 4px 0"/>
      <img src="@/assets/images/holland/amsterdam1.jpg" style="height: 215px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/holland/amsterdam2.jpg" style="height: 215px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/holland/amsterdam3.jpg" style="height: 215px; margin: 0 8px 4px 0"/>
    </div>
    <h2>Tag 11 - Friesland</h2>
    <p>
      Den letzten Tag wollten wir noch entspannt irgendwo in Friesland verbringen. Vorher hatten wir aber noch 
      das berühmte Inntel Hotel in Zaandam auf der Liste. Es ist bekannt geworden durch seine sonderbare 
      Architektur, die es erscheinen lässt, als bestünde es aus mehreren miteinander verschmolzenen Häusern.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/zaandam1.jpg" style="height: 394px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/zaandam2.jpg" style="height: 394px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/zaandam3.jpg" style="height: 394px; margin: 0 8px 0 0"/>
    </div>
    <p>
      Und dann machten wir uns schon auf Richtung Harlingen. Dorthin gelangten wir über den Afsluitdijk 
      &ndash; dem riesigen Damm, der von 1927 bis 1933 gebaut wurde, um die Zuiderzee von der Nordsee abzuschließen, 
      wodurch das IJsselmeer entstand. Er ist beeindruckende 32&thinsp;km lang und 10&thinsp;m hoch. 
      Durch den Abschluss des IJsselmeers sind die beiden Inseln Nordoostpolder und Flevoland entstanden, von denen 
      letztere die größte künstlich erzeugte Insel der Welt ist. Im IJsselmeer befindet sich auch einer 
      der größten Windparks der Niederlande. Über den Damm verläuft eine Autobahn und ein 
      Fahrradweg. Etwa auf einem Viertel des Damms befindet sich ein Monument mit Aussichtsturm. Leider war das Monument 
      wegen einer Baustelle gerade nicht erreichbar und ich konnte kein Souvenir vom Afsluitdijk kaufen.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/afsluitdijk1.jpg" style="height: 210px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/afsluitdijk2.jpg" style="height: 210px; margin: 0 8px 0 0"/>
    </div>
    <p>
      Wir hatten ein Hotel am Strand in Makkum hinterm Deich am IJsselmeer. Dort verbrachten wir noch einen schönen Tag am Strand und 
      hatten ein überteuertes Abendessen.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/makkum1.jpg" style="height: 239px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/makkum2.jpg" style="height: 239px; margin: 0 8px 0 0"/>
      <img src="@/assets/images/holland/makkum3.jpg" style="height: 239px; margin: 0 8px 0 0"/>
    </div>
    <h2>Tag 12 - Rückfahrt</h2>
    <p>
      Heute war der Tag der Rückfahrt. Am Morgen sind wir aber noch zum Deich gefahren, um dort zu frühstücken.
      Die Deichschafe haben uns ein bisschen Gesellschaft geleistet.<br>
      Am Mittag hatten wir noch den Besuch im Amaze-Amsterdam gebucht. Das ist ein Licht-Erlebnis-Museum 
      und man lief durch Räume mit verschiedenen Darbietungen. Wenn man mal in Amsterdam ist, ist das Amaze
      definitiv einen Besuch wert.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img src="@/assets/images/holland/schaf.jpg" style="height: 219px; margin: 0 9px 4px 0"/>
      <img src="@/assets/images/holland/amaze.png" style="height: 219px; margin: 0 9px 4px 0"/>
      <img src="@/assets/images/holland/amaze1.jpg" style="height: 219px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/holland/amaze2.jpg" style="height: 219px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/holland/amaze3.png" style="height: 219px; margin: 0 8px 4px 0"/>
    </div>
    <p>
      Und so kam ich am Samstag, dem 29. Juni nach 12 Tagen wieder zu Hause an, dafür dass ich 
      ursprünglich nur für 5 Tage nach London fahren wollte.
    </p>

    <div style="background-color: aquamarine; padding: 10px 25px 5px 25px; border-radius: 20px; margin-bottom: 20px;">
      <h2>Die Niederlande &ndash; einfach ein funktionierendes Land!</h2>
      <p>
        Jedes Mal, wenn ich in den Niederlanden bin, bin ich beeindruckt von der Fortschrittlichkeit 
        dieses Landes. Überall bewegt sich etwas: Schiffe, Züge, Windräder, Fahrräder, alles mit einer 
        solchen Effizienz! Das System des öffentlichen Nahverkehrs ist bewundernswert! Ebenso die Fahrradinfrastruktur.
        In den Niederlanden wird nicht Autofahrern das Leben schwer gemacht, sondern die Infrastruktur ist so 
        designed, dass es in den meisten Fällen einfach bequemer und natürlicher ist, mit dem Fahrrad, mit dem Bus, U-Bahn 
        oder mit dem Zug zu reisen. Und auch die Straßen sind wunderbar effizient und angenehm designed mit 
        Bremsschwellen oder engeren Straßen für langsames Tempo, statt Blitzern und Strafen.<br>
        Auch in Sachen umweltfreundlicher Energieerzeugung sind die Niederlande weit vorne dabei. Bereits 
        jetzt werden 18&thinsp;% des Stromverbrauchs durch Windkraft gedeckt, von denen sich <span style="white-space:nowrap">27&thinsp;%</span> 
        off-shore befinden (Stand 2023).<br>
        Die Niederlande sind ein Land, das ich sehr bewundere und das mich zutiefst beeindruckt.
      </p>
    </div>
  </div>

</template>

<style scoped>
  #nilsi{
    width:270px; float: right; margin: 12px 0 0 16px
  }

  #funny_alex{
    width:205px; float: right; margin: 5px 0 0 16px
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    #nilsi{
      width: 755px; margin: 15px 0 15px 10px
    }
    #funny_alex{
      width: 755px; margin: 15px 0 15px 10px
    }
  }
</style>
