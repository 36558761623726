<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:20px; text-align:justify">
    <p style="font-weight:bold; font-size:24px; ">
      Das Beet 2016
    </p>
    <p style="text-align:justify; font-size:21px;">
     Ich  w&uuml;sste  spontan  gar  nicht,  was  ich  im  Jahr  2016  gro&szlig; anders  gemacht  habe  als  im  vorigen  Jahr.  Neu  war  auf  jeden Fall  das  beheizbare  Beet,  welches  ich  im  Winter  anlegte.  In den  H&uumlgel  hatte  ich  11  L&oumlcher  gebort,  3  gro&szlige  und  8  kleine.  In der   Mitte   befand   sich   ein   Hohlraum,   in   dem   <a href="@/assets/images/garten/feuerloch.jpg">ein   Feuer</a> entfacht     werden     konnte.     Leider  h&auml;tte das Feuer durchgehend  brennen  m&uumlssen,  um  die  Pflanzen  warm  zu  halten  und das   w&auml;re   auch   nicht   besonders   gut   f&uumlr   die   Umwelt gewesen,   deshalb versuchte  ich  es  den folgenden Winter  einfach mit  Frostschutzfolie.<br>Die Ernte fiehl jenes Jahr etwas besser und vielf&aumlltiger aus.
   </p>

<div style="text-align:justify; font-size:21px">
  <p style="text-align:left; float:right; width:190px; font-size:18px;">
   Optimale Platzausnutzung beim Fr&uuml;beet.</p>
 <img src="@/assets/images/garten/fruebeet.jpg" alt="" title="" style="width: 650px;"/>
 <img src="@/assets/images/garten/152.jpg" alt="" title="" style="width: 380px; height:326px"/>
 <img src="@/assets/images/garten/153.jpg" alt="" title="" style="width: 475px; height:326px"/>
 <img src="@/assets/images/garten/154.jpg" alt="" title="" style="width: 855px;"/>
 <p>Salat,   Petersilie,   Karotten,   Kohlrabi   auf   dem   Gem&uuml;seberg.   Der   Kohlrabi   ist statt   in   die   Breite   in   die   H&ouml;he   geschossen,   wegen   N&auml;hrstoffmangel.   Auf dem   Hang   waren   Tomaten,   Paprika   und   Physalis.   Rechts   sind   Erbsen, Mangold, Getreide und Gurken.Gurke, Tomate und Physalis</p>
 <img src="@/assets/images/garten/GurkeTomatePhysalis.png" style="width: 855px;"/>
 <p>Gurke, Tomate und Physalis</p>
 <img src="@/assets/images/garten/wassermelone.png" alt="" title="" style="width: 855px;"/>
 <p style="text-align: justify">
 Der  Entwicklungsgang  der  Wassermelone:  Sie  wuchs  im  Hochbeet  und  ich habe  mich  sehr  gefreut,  dass  sie  so  sch&ouml;n  gewachsen  ist.  Leider  hat  sie unten angefangen zu  faulen.  Reif  ist  sie  auch  nicht  ganz  geworden,  also habe ich sie an Halloween f&uuml;r Dekorationszwecke verwendet.
 </p>
 <img src="@/assets/images/garten/155.jpg" style="width: 457px; height: 344px;"/>
 <img src="@/assets/images/garten/158.jpg" style="width: 344px;"/>
<p>
  Weiße   Zucchini.   Die   Samen   wurden   uns   von   meinem   Opa   aus   Russland zugesandt. Seitdem bereichern die kulinarischen Fr&uuml;chte meinen Garten.
</p>
</div>
    <router-link to="/garten/beet2017">
     <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 0px 0 25px 0; width: 150px;"/>
   </router-link>
   </div>
</template>
