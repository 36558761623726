<template>
  <div class="reise-div">
    <img src="@/assets/images/interrail/interrailkarte.png"
      style="width: 930px; margin: 0 -65px -15px -85px"/>
    <h1>Interrail-Reise 2023</h1>
    <h2>Intention und Vorgeschichte</h2>
    <p>
      Ich hatte ein anstrengendes Wintersemester und kam auch in der
      Vorlesungszeit im Sommersemester nicht wirklich zur Ruhe. Außerdem wollte
      ich so gerne mal wieder meine gute Freundin Nikhita in London besuchen,
      die ich schon sehr lange nicht mehr gesehen hatte. So hatte ich mir
      vorgenommen, nach der Vorlesungszeit für eine Woche nach London zu fahren
      und dort eine entspannte Zeit zu verbringen. Da Fliegen für mich nicht in
      Frage kommt, suchte ich Zugverbindungen raus. Die schnellste über Brüssel
      hätte mich jedoch mehr als 500&thinsp;&euro; gekostet. So fiel mir dann
      auf, dass es deutlich günstiger wäre, mir einfach ein Interrail-Ticket zu
      holen.
    </p>
    <p>
      Zufälligerweise hatte ich mich im Frühling bei dem
      <a
        href="https://staatskanzlei.hessen.de/berlin-europa-und-die-welt/europa-in-hessen/aktivitaeten-und-veranstaltungen-in-hessen/hessenrail"
        >#HessenRail</a
      >
      Programm angemeldet, bei dem 7-Tage-Interrail-Tickets an Schüler und
      Studierende verlost wurden. Also beschloss ich, noch ein paar Wochen zu
      warten, um zu schauen, ob ich gewinnen würde. Und tatsächlich bekam ich
      ein paar Tage vor meiner geplanten Abreise die E-Mail, dass ich gewonnen
      hatte!<br />
      Leider musste ich nur feststellen, dass mein Pass abgelaufen war und seit
      dem Brexit erlauben es die Briten nicht mehr, mit dem Personalausweis ins
      Vereinigte Königreich einzureisen. Zum Glück gelang es mir noch
      rechtzeitig, einen vorläufigen Reisepass zu beantragen. Und dann konnte es
      auch schon losgehen!
    </p>
    <h2>Tag 1 &ndash; Anreise</h2>
    <p>
      Meine Reise begann am Montag dem 16. Juli 2023 um 9:00 Uhr am Frankfurter
      Hauptbahnhof. Hier stieg ich in den Zug nach Brüssel ein. Mein
      Interrail-Ticket befand sich als digitales Ticket in der Interrail-App auf
      meinem Handy. Die App regelt alles, was mit der Reise zutun hat. In ihr
      kannst du Zugverbindungen finden und diese zu deiner Reise hinzufügen.
      Bevor du in den Zug einsteigst, musst du die Fahrt aktivieren.
      Dadurch aktivierst du auch einen neuen Reisetag deines Tickets. Die in
      meinem Fall sieben Reisetage müssen also nicht direkt aufeinander folgen,
      sondern können innerhalb eines Monats beliebig aktiviert werden.
      Im Zug zeigst du dann einfach den QR-Code vor, welchen du unter dem Tab
      &bdquo;Mein Pass&ldquo; findest.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img
        src="@/assets/images/interrail/frankfurt_hbf.jpg"
        style="width: 373px; margin: 0 4px 0 0"
      />
      <img
        src="@/assets/images/interrail/frankfurt.jpg"
        style="width: 373px; margin: 0 0 0 4px"
      />
    </div>
    <p>
      Ich hatte einen Fensterplatz mit Tisch bekommen. Innerhalb von etwas mehr
      als einer Stunde waren wir schon in Köln. Dort stieg ein Irischer Junge
      ein, der ebenfalls gerade auf einer Interrail-Reise war und mit dem ich
      mich kurz unterhielt. Bis wir es ins nur 70&thinsp;km entfernte Aachen
      geschafft hatten, verging jedoch nochmal mindestens eine ganze Stunde.
      Grund dafür war eine Weichenstörung. Und da der Zug inzwischen schon eine
      Stunde Verspätung hatte, hieß es dann spontan, dass wir nur bis
      Brüssel-Nord fahren würden und nicht bis Bruxelles-Midi (FR) bzw.
      Brussel-Zuid (NL).
      <i>Sänk ju for trävveling wis Deutsche Bahn!</i>
      Glücklicherweise konnte ich auch noch einer Argentinischen Familie helfen,
      die ebenfalls bis zur Endstation fahren wollte und von dem ganzen
      überhaupt nichts mitbekommen hatte, obwohl die Ansage auf vier Sprachen
      (!) wiederholt wurde.<br />
      Aber für mich war das insgesamt kein Problem, denn wie ich die Deutsche
      Bahn kenne, hatte ich extra nicht den direkten Anschlusszug sondern den
      späteren Gebucht.
    </p>
    <p><b>Reise mit dem Eurostar</b></p>
    <p>
      Von Brüssel aus sollte ich nun weiter mit dem Eurostar durch den
      Eurotunnel nach London fahren. Auch diesen Zug kann man mit dem
      Interrail-Ticket benutzen. Es ist allerdings notwendig, einen Sitzplatz
      für 30&thinsp;&euro; zu reservieren und wie ihr später noch näher erfahren
      werdet, war es auch gut, dass ich das schon einige Tage vorher getan
      hatte. <br />
      Man sollte sich mindestens 90&thinsp;min vor Abfahrt des Zuges zum
      Check-In begeben. Und es war 1:1 so wie am Flughafen. Nachdem unsere
      Tickets gescannt wurden, wurde erstmal unser Gepäck durchleuchtet. Dann
      wurde sich mein Reisepass erst von der EU und dann von den britischen
      Behörden angeschaut und als wir durch alle Kontrollen durch waren, befand
      sich am Ende der klassische überteuerte Shop neben dem "Gate", bei dem man
      auf den letzten Drücker noch Souvenire kaufen kann. (Schokolade für
      20&thinsp;&euro; !!!)
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <div>
        <img
          src="@/assets/images/interrail/bahnhof1.jpg"
          style="width: 373px; margin: 0 8px 4px 0"
        />
        <img
          src="@/assets/images/interrail/bahnhof3.jpg"
          style="width: 373px; margin: 0 8px 0 0"
        />
      </div>
      <div>
        <img
          src="@/assets/images/interrail/bahnhof2.jpg"
          style="width: 373px; margin: 0 8px 4px 0"
        />
        <img
          src="@/assets/images/interrail/bahnhof4.jpg"
          style="width: 373px; margin: 0 8px 0 0"
        />
      </div>
    </div>
    <p>
      Und dann stand ich endlich am Gleis und konnte in den Eurostar einsteigen!
      Die Überfahrt von Brüssel nach London dauerte nur zwei Stunden, da der Zug
      zwischendurch teilweise um die 300&thinsp;km/h schnell ist. Der
      50&thinsp;km lange Eurotunnel war verhältnismäßig unspektakulär. Man merkt
      nicht wirklich, dass man 75&thinsp;m unter dem Meeresspiegel ist, sondern
      man ist einfach eine Weile im Dunkeln. Die Durchfahrt dauert etwa 20 min
      bzw. wenn man auf die Uhr schaut -40 min, da es in England immer eine
      Stunde früher ist als in Mitteleuropa. Gegen 17:00 Uhr britische Zeit kam
      ich dann endlich in London an und ich konnte es noch gar nicht glauben,
      dass ich wirklich dort war.
    </p>
    <div style="background-color: aquamarine; padding: 10px 25px 5px 25px; border-radius: 20px; margin-bottom: 20px;">
      <h2>Zug statt Flug</h2>
      <p>
        Wenn ich Leuten von meiner Reise nach London erzählte, gingen sie immer pauschal davon aus,
        dass ich dorthin geflogen wäre. Die Vorstellung, dass man in ein anderes Land fliegen muss, um dort Urlaub
        zu machen, ist noch sehr tief in vielen Menschen verankert, dabei war meine Zugfahrt eine angenehme Alternative.
        Mein Reisetag unterschied sich eigentlich nicht groß von anderen Tagen. Statt in der Uni saß ich halt im Zug 
        an meinem Laptop und habe an meinen Dingen gearbeitet, mit dem Unterschied, dass ich am Ende des Tages plötzlich 
        in London war. Und es fühlte sich so natürlich an, als wäre ich einfach mal eben in die nächste Stadt gefahren, nur dass 
        die Reise natürlich etwas länger war. Und mit einem Interrail-Ticket reist es sich wirklich verhältnismäßig günstig.
        Die günstigste Option für eine Reise durch mehrere europäische Länder kostet gerade einmal 194&thinsp;&euro; (für Jugendliche bis 27) 
        und beinhaltet vier Reisetage innerhalb eines Monats. Das ist günstiger als viele Lufthansa-Flüge und man hat zusätzlich noch den 
        Vorteil der vollkommenen Flexibilität. Der viel wichtigere Grund ist aber die CO2-Bilanz. Denn mit einer Zugreise nach London stößt man volle
        95&thinsp;% <a target="_blank" href="https://www.eurostar.com/rw-en/sustainability">[1]</a> weniger CO2 aus, als mit einen Flug.
        Da mag man wirklich zweifeln, wechen Vorteil es überhaupt noch hat, über so 
        kurze Strecken zu fliegen.
      </p>
    </div>
    <p>
      Als ich am frühen Abend in St. Pancras ankam, machte ich mich sofort auf den Weg zur
      Northern Line um bis zur letzten Station High Barnet zu fahren, in deren
      Nähe sich meine Unterkunft befand. Als ich ausstieg, befand ich mich in
      einem authentischen, britischen Vorort mit Backsteinhäusern, Autos auf der
      linken Seite und roten Doppeldeckerbussen.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img
        src="@/assets/images/interrail/northern_line_train1.jpg"
        style="height: 319px; margin: 0 8px 0 0"
      />
      <img src="@/assets/images/interrail/barnet.jpg" style="height: 319px" />
    </div>
    <p>
      Meine Wohnung war sehr angenehm, ich hatte ein großes Bett, eine Küche und
      ein kleines Bad &ndash; ganz für mich alleine! Um 7 war ich mit meiner
      guten Freundin Nikhita verabredet, welche ich seit 4 Jahren nicht mehr
      gesehen hatte, um gemeinsam in den Süden der Stadt zu fahren und dort Nina
      zu treffen, eine Schulfreundin von mir, die zufälligerweise auch gerade in
      London war. Da ich an dem ganzen Tag jedoch nur ein Marzipancroissant zum
      Frühstück gegessen hatte, war ich am Verhungern und musste mir vorher noch
      eine Portion Fish and Chips holen.<br />
      Es war schön, sie wieder zu sehen! Und es war lustig, Nina an der
      Battersea Powerstation zu treffen. Die Gegend dort ist sehr modern. Es
      gibt zahlreiche Hochhäuser, welche erst vor Kurzem gebaut wurden, in denen
      sehr reiche Leute lebten. Am meisten aus der Fassung hat mich der
      schwebende Glaspool gebracht.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <div>
        <img
          src="@/assets/images/interrail/battersea4.jpg"
          style="width: 373px; margin: 0 8px 4px 0"
        />
        <img
          src="@/assets/images/interrail/battersea2.jpg"
          style="width: 373px; margin: 0 8px 0 0"
        />
      </div>
      <div>
        <img
          src="@/assets/images/interrail/battersea1.jpg"
          style="width: 373px; margin: 0 8px 4px 0"
        />
        <img
          src="@/assets/images/interrail/battersea3.jpg"
          style="width: 373px; margin: 0 8px 0 0"
        />
      </div>
    </div>
    <p>
      Aber wir waren dort, um in einem Venezuelanischen Restaurant zu speisen,
      welches wir nach einem langen Umweg schließlich endlich gefunden hatten.
      Venezuelanische Speisen bestehen aus Huhn, südamerikanischen Kartoffeln,
      tropischen Früchten und Bohnen. Aber ich kann sagen, dass mir Bananen mit
      Käse nicht schmecken. Wir ließen schließlich noch den Abend in einer Bar
      ausklingen, dann machten wir uns auf den Rückweg und ich fiel wie ein
      Stein ins Bett.
    </p>
    <div style="display: flex; margin-bottom: 8px">
      <img
        src="@/assets/images/interrail/restaurant2.jpg"
        style="height: 427px; margin: 0 8px 4px 0"
      />
      <img
        src="@/assets/images/interrail/restaurant1.png"
        style="height: 427px; margin: 0 8px 4px 0"
      />
      <img
        src="@/assets/images/interrail/conho.jpg"
        style="margin-top: -10px; margin-left: -490px; height: 130px"
      />
    </div>
    <h2>Tag 2 &ndash; Dienstag</h2>
    <p>
      Am nächsten Tag wollte ich eigentlich mit Nikhita gegen 10 frühstücken
      gehen und hatte deswegen morgens extra nichts gegessen. Das haben wir dann
      auf 11 verschoben, aber weil sie dann immer noch nicht fertig war, habe
      ich sie einfach bei ihr zu Hause besucht und dort endlich meinen
      obligatorischen Kaffee und Toast bekommen. Es war schön, nach all den
      Jahren mal wieder bei ihr zu sein und ihr Zimmer nicht nur aus der
      Webcamperspektive zu sehen. Wir hatten uns für heute vorgenommen, ein
      Cover einzustudieren und aufzunehmen, so haben wir uns am Morgen ein
      bisschen eingesungen und geübt. Gegen Mittag sind wir dann in das lokale
      Zentrum von Totteridge und Whetstone gegangen &ndash; einer kleinen,
      residentiellen Wohngegend im Stadtteil Barnet. Dort fanden wir ein sehr
      schönes Café in das wir uns setzten und ich bestellte mir zu Mittag
      erstmal ein großes, deftiges, traditionelles englisches Frühstück und
      schwarzen Tee! Und wir verbrachten sehr lange in dem Café und führten
      ausgedehnte Gespräche. Ich hatte mich lange nicht mehr so entspannt
      gefühlt und es tat richtig gut, sich so lange zu unterhalten! So kamen wir
      tatsächlich auch erst um halb 5 wieder bei ihr zu Hause an und machten uns
      gleich daran, unser Lied einzustudieren, sodass wir es am Abend aufnehmen
      konnten. Wir hatten eigentlich vorgehabt, das Video irgendwo draußen im
      Grünen aufzunehmen, aber als wir gerade starten wollten, fing es an zu
      regnen (typisch englisches Wetter). Aber dafür fanden wir einen schönen
      Platz im überdachten Lichthof und die Videos sind wirklich schön geworden!
      Du kannst sie dir
      <a href="https://www.youtube.com/watch?v=YRy8ZOjp-zw">hier</a> und
      <a href="https://www.youtube.com/watch?v=Tt3bih_ZGUU">hier</a> auf YouTube
      ansehen. Und obwohl der Tag nicht voller Aktionen geladen war, war es
      wirklich ein schöner, langer und erfüllter Tag!
    </p>
    <div style="display: flex">
      <img
        src="@/assets/images/interrail/breakfast_alex.jpg"
        style="height: 600px; margin: 0 8px 4px 32px"
      />
      <img
        src="@/assets/images/interrail/breakfast_nikki.jpg"
        style="height: 600px; margin: 0 32px 4px 8px"
      />
    </div>
    <h2>Tag 3 &ndash; Cambridge</h2>
    <p>
      Am Mittwoch hatte Nikhita morgens ein Meeting, also machte ich mich am
      Vormittag auf den Weg ins Stadtzentrum. Ich hatte von meiner Familie den
      Auftrag bekommen, ein paar bestimmte Souvenire zu kaufen, außerdem
      brauchte ich einen britischen Steckdosenadapter, um meinen Laptop laden zu
      können, da meiner nur für flache Stecker funktionierte. Ich fuhr also mit
      der Northern Line bis Leicester Square und fand mich in China Town wieder.
      Dieses asiatische Viertel ist ebenfalls stark von der LGBT-Community
      geprägt. So habe ich eine ganze Weile zahlreiche Souvenirgeschäfte
      abgeklappert und musste leider feststellen, dass die alle den gleichen
      Billigkram verkauften und wirklich kein originelles, besonderes Geschäft
      dabei war. Auf meiner Suche kam ich am Trafalgar Square vorbei und dann
      verspürte ich das Bedürfnis, über die Brücke auf die andere Seite der
      Themse zu laufen und mir bat sich die Möglichkeit für ein tolles Foto der
      Londoner Silhouette.<br />
      Ich kehrte zurück über die Westminster Abbey und dann rief auch schon
      Nikhita an, dass sie fertig sei und wir uns auf den Weg nach Cambridge
      machen konnten. So trafen wir uns am King’s Cross und machten noch ein
      schönes Foto vor dem Bahnhof Saint Pancras, welcher sich direkt nebenan
      befindet.
    </p>
    <div>
      <img
        src="@/assets/images/interrail/London.jpg"
        style="height: 425px; margin: 0 8px 4px 0"
      />
      <img
        src="@/assets/images/interrail/china_town.jpg"
        style="height: 358px; margin: 0 8px 4px 0"
      />
      <img
        src="@/assets/images/interrail/london_pillar.jpg"
        style="height: 358px; margin: 0 8px 4px 0"
      />
      <img
        src="@/assets/images/interrail/saint_pancreas.jpg"
        style="height: 358px; margin: 0 0 4px 0"
      />
    </div>
    <p>
      Unser Zug ging von Gleis 10, welches sich direkt neben dem Gleis
      9&frac34; befand, vor dem sich eine lange Schlange türmte. Die Fahrt nach
      Cambridge dauerte etwa eine Stunde, dann kamen wir an. Je mehr wir uns dem
      Stadtzentrum näherten, desto mehr historische Gebäude bekamen wir zu
      sehen. Die Häuser bestehen alle aus schönen Sandsteinen und haben schön
      gemusterte Fassaden!
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/cambridge1v.jpg"
        style="height: 324px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/interrail/camebridge2v.jpg" 
        style="height: 324px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/interrail/camebridge3v.jpg"
        style="height: 324px; margin: 0 8px 4px 0"/>
      <img src="@/assets/images/interrail/camebridge4v.jpg"
        style="height: 324px; margin: 0 0 4px 0" />
    </div>
    <p>
      Cambridge ist weltberühmt für seine Universität. Diese ist in verschiedene
      Colleges aufgeteilt, die in der ganzen Stadt verteilt sind. Nach einem
      kurzen Aufenthalt auf dem Markt besuchten wir das Saint John’s Colledge.
      Es war wunderschön! Man bekam ein authentisches Gefühl, in Hogwarts zu
      sein. Man spührt die jahrhundertelange Tradition des Colleges. Dort hatten
      wir einen Blick auf die Bridge of Sighs, welche Nikhita umgehend mit
      Wachsmalstiften zeichnete.
    </p>
    <img src="@/assets/images/interrail/camebridge6h.jpg" width="755" />
    <p>
      Es war ein so schöner Anblick mit den Kanus, die durchgehend auf dem Fluss
      unter uns passierten.
    </p>
    <img src="@/assets/images/interrail/camrbridge5h.jpg" width="755" />
    <p>
      Am späten Nachmittag setzten wir uns noch in ein Café, um einen großen
      Crêpe zu essen und schwarzen Tee zu trinken und ich schrieb eine
      Postkarte. Es gab noch zwei Dinge auf meiner Bucketlist: der
      Reality-Checkpoint und die mathematische Brücke. Als meine Mutter wegen
      einer Geschäftsreise einmal für eine kurze Zeit in Cambridge war, ist sie
      zufällig an jenem misteriösen Laternenpfahl auf dem Parker’s Piece
      vorbeigekommen. Eine der Erklärungen für den Namen ist, dass Studenten,
      die gerade aus der Stadt gingen und somit ihre priviligierte,
      intelektuelle Universitätsblase verließen, sich an diesem Checkpoint
      einmal daran erinnern sollten, dass dies nicht die gesamte Welt
      wiederspiegelt sondern die Realität außerhalb der Toren anders ist. Der
      Reality Checkpoint war leider etwas unterweltigend und um zur
      Mathematischen Brücke zu gelangen, mussten wir wieder ein ganzes Stück
      zurücklaufen. Aber es hat sich gelohnt: wir kamen an schönen Gebäuden
      vorbei und bei der Brücke war eine sehr schöne Anlegestelle für Boote.
      Diesmal ließen wir den Abend in einem guten, englischen Fischrestaurant
      ausklingen und machten uns gegen 10 wieder auf den Weg nach London. Es war
      ein wunderschöner und gelungener Tag.
    </p>
    <div style="display: flex">
    <div>
      <img src="@/assets/images/interrail/tiny_gate.jpg" style="height: 358px; margin: 0 8px 4px 0" />
      <img src="@/assets/images/interrail/food1.jpg" style="height: 358px; margin: 0 8px 4px 0" />
    </div>
    <div>
      <img src="@/assets/images/interrail/mathmatical_bridge.jpg" style="height: 358px; margin: 0 8px 4px 0" />
      <img src="@/assets/images/interrail/food2.jpg" style="height: 358px; margin: 0 8px 4px 0" />
    </div>
    </div>
    <h2>Tag 4 &ndash; Donnerstag</h2>
    <p>
      Am Donnerstag musste ich sehr viele Dinge regeln und erledigen… Am Morgen
      habe ich eine Chatnachricht von meinem Vorgesetzten bekommen, dass wir
      Tutoren doch daran denken sollen, zeitnah noch die letzten Abgaben zu
      korrigieren, damit die Studienleistungen verbucht werden können. Das hatte
      ich absolut nicht mehr auf dem Schirm gehabt. Dann musste ich noch die
      Ausarbeitung unseres Uniprojekts querlesen und ich hatte nun auch
      endgültig beschlossen, meine Reise zu verlängern und noch nach Edinburgh
      zu fahren, um meine sieben Interrail-Tage noch zu nutzen. Dies bedeutete
      auch, dass ich die für Freitag geplante Rückfahrt nach Brüssel, für die
      ich eine Sitzplatzreservierung hatte, nicht antreten konnte. Dummerweise
      lassen sich diese Sitzplatzreservierungen auch nicht stornieren. Das war
      es für mich aber wert gewesen, wenn ich dafür noch ein paar Tage bleiben
      und nach Edinburgh fahren könnte. Also buchte ich einfach eine
      Reservierung für Montag... Eine Woche zu spät!!! Ich hatte in meiner
      Verpeiltheit ausversehen Montag, den 31. statt dem 24. gebucht! Und für
      den 25. waren keine Plätze mehr zu haben. So hatte ich 60&thinsp;&euro;
      verloren und die Buchungen konnten nicht storniert werden, auch nicht,
      nachdem ich den Kundenservice kontaktiert habe...<br />
      Also musste ich doch die Fähre buchen!
    </p>
    <p>
      Aber am Vormittag machte ich mich erstmal auf den Weg nach Covent Garden.
      Dies ist eine sehr schöne Fußgängerzone im Londoner Zentrum mit einigen
      Markthallen in denen Schmuck, Klamotten, Essen und lauter Krimskrams
      verkauft werden. Dort verbrachte ich eine ganze Weile bis ich mich endlich
      entschied, ein paar lustige T-Shirts zu kaufen. Gegen Mittag kam Nikhita
      dazu und wir aßen in einer Pizzeria zu Mittag. Danach machten wir uns
      jedoch auf den Weg zu einem Gebäude des University College London, weil
      Nikhita dort ein Meeting hatte und ich konnte die Zeit nutzen,
      währenddessen alle meine Erledigungen zu erledigen. Ich habe außerdem von
      dort aus mein Hostel in Edinburgh gebucht. Ich würde in einem Schlafsaal
      mit anderen Leuten schlafen. Sowas hatte ich zuvor noch nie gemacht.<br />
      Abends hatten wir nicht mehr wirklich die Lust oder Energie, etwas zu
      unternehmen, also haben wir uns noch in eine Filiale der healthy Fastfood
      Kette Leon gesetzt und uns mit ChatGPT amüsiert.
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/covent_garden.jpg" style="height: 314px; margin: 0 8px 4px 0" />
      <img src="@/assets/images/interrail/todos.jpg" style="height: 314px; margin: 0 8px 4px 0" />
    </div>
    <h2>Nacht 4</h2>
    <p>
      In dieser Nacht konnte ich nicht wirklich schlafen. Vielleicht lag es an
      dem vielen Cappuccino und schwarzen Tee, den ich getrunken hatte. Nachts
      kam ich jedenfalls auf die Idee, dass es vielleicht schlau wäre, mal
      nachzuschauen, ob ich eine Sitzplatzreservierung für den Zug nach
      Edinburgh brauchte. Natürlich brauchte ich das. Und zu meiner Panik hat es
      auch nicht geklappt, die Sitzplatzreservierung über das Interrail-Portal
      durchzuführen. Also musste ich mich eine Weile durchklicken, bis ich
      gesehen hatte, dass man bei LNER kostenlos Sitzplätze reservieren konnte,
      sogar ohne ein Ticket gebucht zu haben. Leider stellte sich zudem noch
      heraus, dass scheinbar genau an dem Samstag, als ich nach Edinburgh fahren
      wollte, ein nationaler Bahnstreik stattfand und nur ganz wenige Züge
      fuhren. Also konnte ich nicht morgens fahren, wie ich es erst vorhatte,
      sondern konnte nur einen Zug um halb 3 nehmen, der erst um 7 ankam. Aber
      gut, dafür würde ich dann am Sonntag einfach später wieder zurückfahren.
      Wenn man reist, muss man nehmen, was kommt.
    </p>
    <h2>Tag 5 - Camden Market</h2>
    <img
      src="@/assets/images/interrail/camden_breakfast.jpg"
      id="camden_breakfast"
    />
    <p>
      Am nächsten Morgen war ich sehr Müde, aber ich hatte mir vorgenommen, nach
      Camden zu gehen und den Markt anzuschauen. Aber vorher wollte ich ein
      schönes Frühstück bekommen, also suchte ich mir eine schöne Bäckerei in
      Camden aus, in der ich den Morgen verbrachte, Cappucino trank, Croissants
      aß und ein bisschen am Laptop arbeitete.
    </p>
    <p>
      Ich hatte außerdem inzwischen schon viele Souvenirs zusammengesammelt
      (Tasse, T-Shirts, Süßigkeiten, Tee, Buch), die mich langsam recht viel
      Platz kosteten, also hatte ich beschlossen, ein paar der Sachen schonmal
      nach Hause zu schicken. Leider wog mein Päckchen minimal mehr als 2&thinsp;kg und
      die freundliche Frau musste mir mitteilen, dass mich das Päckchen wegen
      dem Zoll &pound;&thinsp;60 kosten würde und es erst in 15 Tagen ankommen
      würde. Ich hatte es schon verpacken lassen und das Zollformular ausgefüllt
      bis ich dann doch den Rückzieher machte und beschloss, es irgendwo von der
      EU aus zu schicken, weil mir das die 60 Pfund wirklich nicht wert war.
      <br />
      Nikhita war inzwischen dazugekommen und so war es, dass wir den ganzen
      Nachmittag in Camden Market verbrachten und ein Päckchen, auf dem in
      riesengroßen Buchstaben meine Adresse geschrieben war, mit uns
      herumschleppten…<br />
      Aber Camden Market war ein sehr toller Ort. Es gibt unglaublich viele
      Geschäfte und Markthallen, die kein Ende nahmen. Natürlich gibt es die
      standard Touristengeschäfte, aber wenn man etwas weiter vordringt, findet
      man alles: Schmuck, Kleidung, Leder, Krimskrams. Es gibt auch einen großen
      Streetfood Teil in dem man wirklich interessante Sachen aus aller Welt
      bekommt. Die ganze Location ist sehr alternativ und hat einen tollen
      steampunky Flair. Wir verbrachten den ganzen Nachmittag nur damit, durch
      die ganzen Märkte zu laufen, ohne wirklich was zu kaufen. Bis auf zwei
      Schmuckstücke: Nikhita kaufte sich eine Kette mit einem Eisvogel und ich
      Bienenohrringe am selben Stand. Das ist ein schönes Erinnerungsstück.
    </p>
    <div style="display: flex">
        <img src="@/assets/images/interrail/beerings.jpg" style="height: 356px; margin: 0 8px 8px 0" />
        <img src="@/assets/images/interrail/camden_package.jpg" style="height: 356px; margin: 0 8px 8px 0" />
        <img src="@/assets/images/interrail/camden2v.jpg" style="height: 356px; margin: 0 8px 8px 0" />
    </div>
    <img src="@/assets/images/interrail/camden1h.jpg" style="width: 750px" />
    <p>
      Am späten Nachmittag war Nikhita mit ihren Eltern in der Oper verabredet
      und ich musste auch noch ins Zentrum, um eine gute Touristenkarte für
      meine Mutter zu kaufen. Also sind wir noch kurz wieder nach Covent Garden
      gefahren und haben einen schönen Platz zum hinsetzen gefunden. Gegen 5
      fuhr ich dann zur Touristeninformation neben der St&thinsp;Pauls Kathedrale und
      ich muss schon sagen, sie ist wirklich beeindruckend groß und ich musste
      mich an die Szene mit der Taubenfrau aus Marry Poppins erinnern. Und als
      ich dann abends wieder in Barnet ankam, war ich wirklich kaputt von den
      ereignisreichen 5 Tagen. Außerdem hatte ich das Bedürfnis, mal für einen
      Moment dem Großstadttrubel zu entkommen. Zum Glück liegt High Barnet
      ziemlich an der Stadtgrenze und ich fand eine wirklich schöne Stelle im
      Grünen, um meine Pizza zu essen. Und abends hatte ich dann endlich die
      Zeit, mich hinzusetzen und eines der tollen Covers die wir aufgenommen
      haben zu bearbeiten und auf YouTube hochzuladen!
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/iconical_london.jpg" style="height: 256px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/saint_pauls.jpg" style="height: 256px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/barnet2.jpg" style="height: 256px; margin: 0 8px 8px 0" />
    </div>
    <h2>Tag 6 &ndash; Edinburgh</h2>
    <img
      src="@/assets/images/interrail/english_breakfast.jpg"
      id="english_breakfast"
    />
    <p>
      Heute war der Tag, an dem ich aus der Unterkunft in Barnet auschecken und
      nach Edinburgh fahren würde. Doch am Vormittag hatte ich mich nochmal mit
      Nikhita verabredet, um in dem tollen Café in Barnet, in dem wir am
      Dienstag waren, nochmal ein tolles, reichhaltiges englisches Breakfast zu
      haben. Und als ich so durch Barnet lief und auf Nikhita wartete &ndash;
      wohlbemerkt mit allen meinen Sachen &ndash;, bemerkte ich das Post Office
      direkt gegenüber von der Bushaltestelle, an der ich ausgestiegen war und
      ich beschloss, meinem Glück mit dem Paket nochmal eine Chance zu geben.
      Und tatsächlich! Hier kostete es nur &pound;&thinsp;40, ein Paket mit
      3&thinsp;kg Gewicht zu versenden. Also öffnete ich es sogar nochmal, um
      noch ein paar Dinge hinein zu tun, um auf die 3&thinsp;kg zu kommen. (Es
      stellte sich dann nur heraus, dass mein Vater in Deutschalnd letztendlich
      noch 18&thinsp;&euro; dazubezahlen musste, um es abzuholen.)<br />
      Wir hatten jedenfalls nochmal ein entspanntes, ausgelassenes Frühstück und
      gegen Mittag musste ich dann auch schon zum King’s Cross, um meinen
      Schnellzug nach Edinburgh zu nehmen.
    </p>
    <p>
      Dafür dass Edinburgh 650&thinsp;km weit weg ist, ging die Fahrt richtig
      schnell. Aber man glaubt gar nicht, wie weit weg Edinburgh wirklich ist.
      Mein Bild war gewesen, dass York schon ziemlich weit im Norden ist und
      Edinburgh dann nur noch ein Stück von dort aus, dabei liegt York gerade
      einmal auf der Hälfte der Strecke. Die Fahrt war allerdings sehr
      komfortabel und ich verbrachte den meisten Teil der Reise damit, das
      zweite Video zu bearbeiten. So in etwa ab York veränderte sich die
      Landschaft und das Wetter sehr. Die Landschaft wurde grasiger und
      steiniger und ab und zu bekam man sogar das Meer zu sehen. Das Wetter
      wurde grauer, regnerischer und ungemütlicher. Ein kleines Highlight der
      Reise war, dass ich einen kurzen Blick auf die Durham Cathedral bekam.
      Leider war der Moment so kurz, dass ich es nicht mehr schaffte, ein Foto
      davon zu machen, daher muss hier dieses Foto aus dem Internet hinhalten.
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/lner.jpg" style="height: 185px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/landscape.jpg" style="height: 185px; margin: 0 8px 8px 0" />
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Durham_MMB_02_Cathedral.jpg/1200px-Durham_MMB_02_Cathedral.jpg?20140913173215" style="height: 185px; margin: 0 8px 8px 0" />
    </div>
    <p>
      Schließlich kam ich dann an. Es war sehr kalt. Ich stieg aus dem Bahnhof
      nach oben und musste mich erstmal orientieren. Anscheinend führt der
      schnellste Weg zu meiner Unterkunft durch diese schmale Gasse nach oben.
      Und in dem Moment, in dem ich diese Gasse betrat, fühlte ich mich wie in
      einer anderen Welt. Überall große, steinige, verzierte Gebäude, wo das
      Auge hinschaut. Die Gasse mündete in eine breitere Straße und vor mir
      öffnete sich der magische Anblick der Stadt und ich wusste nicht, wo ich
      zuerst hinschauen sollte. Edinburgh hat eine ganz eigene, besondere
      Atmosphäre, die sich für mich anfühlte, als sei ich plötzlich in der
      magischen Welt von Harry Potter aufgetaucht. Und es soll auch hier in
      dieser magischen Stadt gewesen sein, in dem die Autorin J. K. Rowling die
      ersten Kapitel dieser Weltberühmten Buchreihe verfasst hat. <br />
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/edinburgh1v.jpg" style="height: 394px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/edinburgh2v.jpg" style="height: 394px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/castle.jpg" style="height: 394px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex">
      <img src="@/assets/images/interrail/edinburgh3h.jpg" style="height: 139px; margin: 0 7px 8px 0" />
      <img src="@/assets/images/interrail/edinburgh4h.jpg" style="height: 139px; margin: 0 7px 8px 0" />
      <img src="@/assets/images/interrail/edinburgh5h.jpg" style="height: 139px; margin: 0 7px 8px 0" />
    </div>
    <p>
      Die erste Zeit wandelte ich einfach durch die fantastischen Straßen
      Edinburghs und nahm erstmal alles auf. Schließlich näherte ich mich meinem
      Hostel, welches direkt am Fuße der großen Festung lag. Ich war noch nie
      zuvor in einem Hostel gewesen und dann hatte ich auch noch direkt ein
      16-Bett-Zimmer. Aber zum Glück waren es keine gewöhnlichen Hochbetten
      sondern Schlafkapseln, die man mit einem Vorhang zuziehen konnte. Ich
      blieb aber nicht lange dort, denn ich wollte die Stadt in ihrem
      abendlichen Flair erkunden. Aber vorallem hatte ich ziemlichen Hunger.
      Also fand ich schnell ein nettes Restaurant und bestellte ein
      traditionelles Bangers and Mash (Würstchen mit Kartoffelbrei). Ich hab
      mich nicht getraut, Haggis zu bestellen, weil meine Englischlehrerin
      einmal erzählt hat, dass ihr schlecht davon geworden sei. Als es dann
      dunkel wurde, machte ich mich dann auf den Weg zum Monument, von dem man
      einen wundervollen Ausblick auf die leuchtende, alte Stadt hatte. Dort
      blieb ich eine Weile bis ich mich auf den Weg zurück zu meinem Hostel
      machte. Die Nacht war total in Ordnung. Die anderen Gäste habe ich quasi
      nicht gesehen und es war auch sehr ruhig. Die Schlafkapsel war sogar
      gewissermaßen gemütlich und es gab LEDs an der Decke deren Farbe man
      verändern konnte. Aber vor allem gab es gutes WLAN, sodass ich endlich das
      zweite Video hochladen konnte!
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/bangers_and_mash.jpg" style="height: 210px; margin: 0 8px 8px 0"  />
      <img src="@/assets/images/interrail/edinburgh7h.jpg" style="height: 210px; margin: 0 8px 8px 0"  />
    </div>
    <div style="display: flex">
      <img src="@/assets/images/interrail/edinburgh9h.jpg" style="height: 319px; margin: 0 9px 8px 0"  />
      <img src="@/assets/images/interrail/edinburgh8v.jpg" style="height: 319px; margin: 0 8px 8px 0"  />
    </div>
    <div style="display: flex">
      <img src="@/assets/images/interrail/edinburgh8h.jpg" style="height: 172px; width:305px; margin: 0 8px 8px 0"  />
      <img src="@/assets/images/interrail/edinburgh6h.jpg" style="height: 172px; width:305px; margin: 0 8px 8px 0"  />
      <img src="@/assets/images/interrail/edinburgh10v.jpg" style="height: 172px; margin: 0 8px 8px 0"  />
    </div>
    <h2>Tag 7 &ndash; Sonntag</h2>
    <p>
      Ich war froh, die Nacht im Hostel überstanden zu haben, also machte ich
      mich nach dem Frühstück direkt auf den Weg in die Stadt. Da ich mich nicht
      wirklich informiert hatte, was es in Edinburgh für Sehenswürdigkeiten gibt
      und ich nur dreieinhalb Stunden bis zu meinem Zug hatte, war ich ein
      schlechter Tourist und verbrachte den Vormittag hauptsächlich in
      Souvenirläden. Aber ich habe nach langem Suchen den perfekten Pullover
      gefunden!<br />
      Und dann machte ich mich auf die Reise zurück. Und nach nocheinmal vier
      Stunden kam ich endlich wieder in London an und als ich bei strahlendem
      Sonnenschein aus dem Bahnhof King’s Cross stieg, war ich richtig froh,
      wieder in London zu sein. Edinburgh war eine tolle Erfahrung, aber vor
      allem aufregend und anstrengend. Und als ich in der Tube Station
      Totteridge & Whetstone ausstieg, hatte ich sogar ein klein bisschen das
      Gefühl, wieder zu Hause zu sein, da mir die Gegend in den letzten Tagen
      doch sehr vertraut geworden ist.<br />
      Den Abend und die Nacht verbrachte ich im Haus von Nikhita und es war so
      schön, wieder eine warme Dusche und ein gemütliches Bett zu haben!
    </p>
    <div style="background-color: aquamarine; padding: 10px 25px 5px 25px; border-radius: 20px; margin-bottom: 20px;">
      <h2>Ode an das britische Verkehrssystem</h2>
      <p>
        Erst wenn man einmal ein wenig im Ausland herumgereist ist, bemerkt man,
        wie weit zurück wir mit unserem öffentlichen Nah- und Fernverkehr in
        Deutschland doch noch liegen. Reisen in England gibt einem ein ganz
        anderes Gefühl. Die Standards sind viel höher und man merkt, dass hier
        eine ganze andere Einstellung zum Zug- und Busfahren herrscht. Mein
        erster Ausdruck der Bewunderung gebührt dem Londoner U-Bahn-System. Es
        ist ein Meisterwerk der Ingenieurskunst und Organisation. Die Züge sind
        sauber und das Reisen ist komfortabel und verständlich. Überall findet
        man mitarbeiter, die einem weiterhelfen. Probleme und technische
        Störungen werden umgehend behoben. Es ist Wahnsinn, wie diese riesige,
        komplexe Maschinerie tagtäglich aufrechterhalten wird.
      </p>
      <p>
        Auch das Busfahren in London folgt ganz anderen Standards. Natürlich
        gibt es immer noch die klassischen roten Doppeldeckerbusse. Es gibt ein
        einfaches Check-In-Check-Out-System, für welches man seine Oysterkart
        oder einfach seine reguläre Bankkarte benutzen kann. In Deutschland sind
        die Fahrgäste in Bussen hauptsächlich Schüler, welche noch kein Auto
        fahren dürfen oder sozial schwache Menschen und Studierende, die sich
        kein Auto leisten können. In England ist der öffentliche Nahverkehr auch
        komfortabler Teil des Mittelstandes und man findet oft Tageszeitungen in
        Bussen und Bahnen ausgelegt. Was mir zudem in England sehr gefällt ist,
        dass es üblich ist, sich beim Busfahrer oder der Busfahrerin beim
        Aussteigen zu bedanken. Das ist einfach sehr freundlich und sorgt für
        eine freundliche Atmosphäre. Daran könnten wir uns auch ein Beispiel
        nehmen.
      </p>
      <p>
        Aber noch etwas ganz anderes sind Langstreckenreisen mit Schnellzügen.
        Schon die 2. Klasse fühlt sich für mich an wie die erste Klasse. Das
        Design im Zug ist schön, er wirkt modern und es gibt Steckdosen und
        Tische an jedem Sitzplatz. Während ich das schreibe, sitze ich gerade im
        Zug nach Edinburgh. Heute ist eigentlich ein Streiktag, weshalb nur
        wenige Züge fahren. Aber auf der Webseite war ganze verständlich
        erklärt, welche Züge genau nicht fahren. Und am Bahnhof und jetzt im Zug
        habe ich überhaupt nichts von dem Streik mitbekommen. Ich fühle mich
        sehr komfortabel und alles wirkt sehr kompetent. Ich bin außerdem
        beeindruckt, dass der Zug die 400 Meilen in nur 4:27 h zurücklegen wird.
        Das bedeutet, dass er durchschnittlich mit 143&thinsp;km/h unterwegs ist. Das
        wäre in Deutschland gar nicht möglich, schon allein weil das Zugnetz im
        Allgemeinen zu voll dafür ist.
      </p>
    </div>

    <h2>Tag 8 &ndash; Zurück aufs Festland</h2>
    <p>
      Am Montag musste ich früh aufstehen, um mich auf den langen Weg mit der
      Fähre nach Calais und weiter nach Gent zu begeben. Als Fußpassagier hat
      man es ein bisschen schwierig. Ich kam mit dem Zug in Dover an, aber der
      Bahnhof ist etwa 1.5 Meilen vom Fährhafen entfernt. Und ich bin mir
      sicher, es gibt einen Shuttelservice zum Hafen und dort stehen auch Taxis,
      aber Google Maps hat mir keine Optionen für öffentlichen Nahverkehr
      angezeigt, also beschloss ich, einfach zu laufen. Wer also zu Fuß
      unterwegs ist, sollte 45 min Laufweg mit einplanen, zusätzlich zu den >90
      min die man vor Abfahrt der Fähre am Terminal sein muss. Ich habe also
      rechtzeitig eingecheckt und tatsächlich kam genau 90 Minuten vor Abfahrt
      der Bus, der uns zur Fähre brachte. Interessanterweise mussten wir diesmal
      durch keinerlei Gepäckkontrollen durch, lediglich unsere Pässe wurden
      kontrolliert. Ansonsten war alles voll OK, die überfahrt war langsam, aber
      angenehm. Leider hat sich der Aufenthalt auf der Fähre ziemlich in die
      Länge gezogen. Als Fußpassagiere waren wir zwar die ersten an Bord, wir
      waren allerdings auch die letzten, die aussteigen durften, weil erst alle
      Autos rausfahren mussten, damit der Bus hineinfahren und uns abholen
      konnte. Und dann muss man noch einen Shuttlebus nehmen, der einen zum
      Bahnhof ins Zentrum von Calais bringt.
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/dover1.jpg" style="height: 210px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/dover2.jpg" style="height: 210px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex">  
      <img src="@/assets/images/interrail/calais_station.jpg" style="height: 319px; margin: 0 8px 0px 0" />
      <img src="@/assets/images/interrail/calais_kirche.jpg" style="height: 319px; margin: 0 8px 0px 0" />
    </div>
    <p>
      Ich muss leider sagen, ich bin nicht gerne in Frankreich. Ich bin
      grundsätzlich einfach nicht gerne in einem Land, in dem ich die Sprache
      nicht richtig verstehe und ich mir nicht sicher bin, wie gut die Leute
      englisch sprechen. Außerdem kam der nächste Zug, den ich nehmen konnte,
      erst um 18:30 Uhr und ich musste mit drei Regionalbahnen/ICs nach Gent
      fahren. So kam ich demetsprechend leider auch erst um halb 10 in Gent an
      und als ich an der Unterkunft ankam, war es schon 10. Aber ich war froh,
      endlich wieder in einem Land zu sein, in dem ich die Sprache verstehe und
      das sich wieder ein bisschen mehr wie Heimat anfühlt. Mitteleuropäische
      Länder sind doch alle irgendwie sehr ähnlich zueinander. Und die
      Unterkunft war sehr cool. Sie machte einen freundlichen, gemütlichen
      Eindruck und der Stil war schön. Anscheinend liegt sie in einem sehr
      historischen Haus, in dem sogar Erasmus früher regelmäßig gewesen ist. Und
      sogar der Schlafraum, vor dem ich am meisten Angst hatte, sah freundlich
      aus. Außerdem hatte die Unterkunft ein total krasses Konzept, um die Türen
      zu öffnen: Man konnte über das Handy von deren Webseite aus über
      verschiedene Regler die Türen entsperren. Wild! Ich wollte nun unbedingt
      noch die Stadt erkunden, denn Gent bei Nacht ist ein besonderes Erlebnis
      und das Genter Beleuchtungskonzept wurde auch schon mehrfach
      ausgezeichnet. Und das ist denen allemal vergönnt. Ich lasse hier einfach
      mal die Bilder für sich sprechen.
    </p>
    <div style="display: flex">
      <div>
        <img src="@/assets/images/interrail/gent1v.jpg" style="height: 829px; margin: 0 8px -8px 0"/>
      </div>
      <div>
        <img src="@/assets/images/interrail/gent2h.jpg" style="width: 280px; margin: 0 0 4px 0" />
        <img src="@/assets/images/interrail/gent3v.jpg" style="width: 280px; margin: 0 0 4px 0" />
        <img src="@/assets/images/interrail/gent4h.jpg" style="width: 280px; margin: 0 0 -8px 0" />
      </div>
    </div>

    <p>
      Leider hatte ich auch ziemlichen Hunger, aber alle Restaurants hatten
      einfach schon um 10 zugemacht und es hatten nur noch einige Bars offen,
      die aber kein Essen anboten. Ich hätte gerne etwas traditionelles
      Belgisches gegessen mit Pommes, aber so musste ich dann leider einfach zu
      McDonald’s gehen. Ich verbrachte aber noch eine gute Zeit in der
      nächtlichen, leeren, beleuchteten Stadt.
    </p>
    <h2>Tag 9 &ndash; Gent &amp; Holland</h2>
    <img src="@/assets/images/interrail/waschmaschine.jpg" width="240" style="float: right; margin: 24px 0 0 10px"/>
    <p>
      In der Nacht im Hostel habe ich allerdings trotzdem kaum geschlafen. Ich
      kam etwa gegen 12 dort an, doch ein Mädel hatte einfach vergessen, ihre
      Lampe auszumachen und ist eingeschlafen und das hat es noch schwieriger
      gemacht, einzuschlafen, weil mich das Licht ziemlich anstrahlte. Gegen 1
      muss ich dann aber doch irgendwann eingeschlafen sein und als ich gegen 3
      wieder aufwachte, war das Licht auch zum Glück aus. Leider bin ich dann
      trotzdem nicht mehr eingeschlafen, zum Teil auch weil ich riesen Durst
      hatte und gegen 4 sogar aufgestanden bin, um ins Bad zu gehen, um Wasser
      zu holen. Naja, dafür war ich dann auch sehr früh wach und einer der
      ersten beim Frühstück. Und ich konnte mir richtig viel Zeit nehmen,
      endlich irgendwo meine Wäsche zu waschen und zu trocknen (denn sie hatten
      einen Trockner!) und in Ruhe die gesamte Morgenroutine durchzugehen,
      sodass ich wieder frisch in die Weiterreise starten konnte.
    </p>
    <p>
      Am Vormittag habe ich bei einer Bootstour mitgemacht, um diesmal
      wenigstens etwas über die Stadt zu erfahren! Natürlich auf Niederländisch,
      um mein Sprachverständnis weiter zu fördern. Anscheinend gab es hier
      einmal einen Kanal, der direkt zur Nordsee führte und damals die
      schnellste Verbindung nach England war. Danach habe ich mich noch
      gemütlich in ein Café gesetzt und gebruncht und dabei eine Postkarte
      geschrieben und mich dann auch schon auf den Weg weiter in die Niederlande
      gemacht!
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/gent5.jpg" style="height: 210px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/gent6.jpg" style="height: 210px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex"> 
      <img src="@/assets/images/interrail/gent7.jpg" style="height: 210px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/gent8.jpg" style="height: 210px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex"> 
      <img src="@/assets/images/interrail/gent9.jpg" style="height: 210px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/gent10.jpg" style="height: 210px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex"> 
      <img src="@/assets/images/interrail/gent11.jpg" style="height: 210px; margin: 0 8px 0 0" />
      <img src="@/assets/images/interrail/gent12.jpg" style="height: 210px; margin: 0 8px 0 0" />
    </div>
    <p>
      Ich war sehr aufgeregt, denn heute würde mein Freund mich besuchen kommen
      und mit mir die letzten drei Tage in den Niederlanden verbringen. Das
      erste Gasthaus befand sich in einem süßen Dorf in der Unmittelbaren Nähe
      von Gouda &ndash; der Stadt aus der der Käse kommt. Von Gent aus fuhr ich
      über Antwerpen und Rotterdam bequem und entspannt bis nach Gouda und
      brauchte dafür nicht mal drei Stunden. Ich nahm mir noch eine Stunde, um
      mir Gouda anzuschauen und etwas von dem berühmten Käse zu kaufen. Gegen
      18:00 Uhr nahm ich dann aber den Bus nach Haastrecht und checkte im Hotel
      ein. Es liegt direkt an einem wunderschönen Kanal mit süßen Booten.
    </p>
    <div style="display: flex">
      <img src="@/assets/images/interrail/gouda1.jpg" style="height: 210px; margin: 0 8px 8px 0" />
      <img src="@/assets/images/interrail/gouda2.jpg" style="height: 210px; margin: 0 8px 8px 0" />
    </div>
    <div style="display: flex">
      <img src="@/assets/images/interrail/haastrecht1.jpg" style="height: 210px; margin: 0 8px 0 0" />
      <img src="@/assets/images/interrail/haastrecht2.jpg" style="height: 210px; margin: 0 8px 0 0" />
    </div>
    <img src="@/assets/images/interrail/nils.jpg" id="nils"/>
    <p>
      Und nur eine Stunde später kam auch schon Nils. Es war schön, ihn nach über
      einer Woche mal wieder zu sehen. Wir trödelten auch nicht lange und haben
      uns direkt auf den Weg zum Strand an den Dünen gemacht, weil wir noch
      einen guten Fisch in einem Restaurant am Strand essen wollten. <br>
      Und damit endet auch meine Interrail-Reise und der Roadtrip durch die
      Niederlande beginnt! Diesen findet ihr <router-link to="/reisen/holland" @click="toTop(1000)">im nächsten Artikel</router-link>.
    </p>
  </div>
</template>

<style scoped>
  #camden_breakfast{
    width: 290px; float: right; margin: 12px 0 0 10px
  }

  #english_breakfast{
    width:270px;
    float: right; margin: 23px 0 0 10px
  }

  #nils{
    width:245px; float: right; margin: 24px 0 0 16px
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    #camden_breakfast{
      width: 755px; margin: 40px 0 15px 10px
    }

    #english_breakfast{
      width: 755px; margin: 40px 0 15px 10px
    }

    #nils{
      width: 755px; margin: 40px 0 15px 10px
    }
  }
</style>

<script>
export default{
  name: 'interrail',

  methods: {
    toTop: function(offset){
      document.body.scrollTop = offset; // For Safari
      document.documentElement.scrollTop = offset; // For Chrome, Firefox, IE and Opera
    },
  }
}

</script>
