<template>
  <div class="reise-div">
  <h1>Belgien</h1>
  <h2>Vorgeschichte</h2>
  <p>
    Im Frühjahr 2021 hatten meine Freunde und ich zum ersten Mal die Idee, mit unserer Freundesgruppe gemeinsam in den Urlaub zu fahren.
    Zuerst standen die Niederlande im Raum, aber da zu der Zeit die Coronazahlen dort enorm hoch waren, entschieden wir uns lieber für Belgien, das
    ist ja schließlich so ähnlich und außerdem war noch nie jemand von uns dort gewesen. So haben wir eine schöne Ferienwohnung in Nieuwpoort an der Küste des Flämischen Teils
    gebucht. Allerdings wollten wir uns auch die Hauptstadt nicht entgehen lassen, so haben wir also noch eine Nacht in Brüssel vorne dran geschoben.
  </p>
  <img src="@/assets/images/Belgien/vlaams_en_frans.jpg" alt="Bücher" style="width: 300px; margin: 0 0 0 30%"/>
  <p>
    In den Monaten davor war ich sehr motiviert, meine Kenntnisse in Französisch und Niederländisch aufzufrischen &ndash; die beiden Amtssprachen in Belgien &ndash;,
    wobei die dortige Variante des Niederländischen dort als Flämisch bezeichnet wird.<br>
    Am 23.09.21 machten wir uns zu sechst mit zwei Autos auf nach Brüssel. Für mich war es das erste Mal, so eine lange Strecke mit dem Auto zu fahren. Leider hatte sich 
    Nina zu beginn der Reise erkältet.
  </p>

  <h2>Brüssel</h2>
  <p>
    Gegen Nachmittag kamen wir dann in Brüssel an. Großstädte sind nicht dazu da, um sich dort mit dem Auto fortzubewegen. Glücklicherweise gibt es am 
    Stadtrand einen großen, kostenlosen Parkplatz "P+R Stalle" mit direkter Anbindung an das U-Bahn-System.<br>
    So kamen wir also an und fuhren mit der U-Bahn direkt zu unserer Jugendherrberge. Nachdem wir dort eingecheckt waren, machten wir uns natürlich
    gleich auf, die Stadt zu erkunden. Brüssel stellte sich als eine moderne, Europäische Metropole heraus, die auch viel Historisches zu bieten hatte.
    Brüssel ist offiziell eine zweisprachige Stadt, aber es schien so, als wäre Französisch dort die dominantere Sprache des täglichen Umgangs.
    Natürlich mussten wir uns gleich zu Anfang ein paar der berühmten Belgischen Waffeln holen. Und die erste Hauptsehenswürdigkeit, die wir abhaken mussten, war
    natürlich das berühmnte <i>Manneken Pis</i>, welches jedoch enttäuschend klein war.<br>
    Da wir alle hungrig waren, suchten wir schnell das nächste Restaurant auf, um
    etwas zu essen. Die Belgische Küche ist sehr ähnlich zur Französischen Küche, jedoch mit mehr herzhaften Dingen und natürlich mit Pommes, von denen die 
    Belgier stolz behaupten, sie seien die Erfinder dieser weltbekannten, frittierten Speise.
    Da niemand von uns die Speisekarte verstand, bestellten wir einfach alle ein <i>Vole au Vent</i> (Flug im Wind), weil es als Beilage Pommes gab.
    Ich habe versucht, mit dem Kellner auf Französisch zu kommunizieren, aber gemeinsam haben wir ihn komplett verwirrt, sodass er dann auf Englisch umgeschwungen ist.
    Wir haben dann unsere Speise bekommen, welche sehr fettig und zudem noch teuer war, aber immerhin hatten wir dort die Gelegenheit, zum ersten Mal
    das berühmte Belgische <i>Kriek</i> &ndash; ein Kirschbier &ndash; zu probieren.<br>
    Den Rest des Abends verbrachten wir damit, noch bis spät durch die Stadt zu laufen und die Umgebung zu erkunden.
  </p>
  <div style="display: flex; margin-bottom: 6px;">
    <img src="@/assets/images/Belgien/bruxelles_1.jpg" alt="Bücher" style="height: 280px; margin: 0 4px 0 0px;"/>
    <img src="@/assets/images/Belgien/waffeln.jpg" alt="Waffeln" style="height: 280px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Belgien/kriek.jpg" alt="Kriek" style="height: 280px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Belgien/boulevard.jpg" alt="Boulevard" style="height: 280px; margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex;">
    <div><img src="@/assets/images/Belgien/manneken_pis.jpg" alt="Bücher" style="width: 450px; margin: 0 8px 0 0px;"/></div>
  <div> 
    <img src="@/assets/images/Belgien/haeuser.jpg" alt="Bücher" style="width: 295px;"/>
    <img src="@/assets/images/Belgien/palast.jpg" alt="Bücher" style="width: 295px;"/>
  </div>
  </div>
  <p>
  Am nächsten Tag frühstückten wir in Ruhe in der Herrberge, doch wir machten uns noch nicht auf die Weiterfahrt nach Niewpoort, denn ich hatte noch
  Tickets für meine Lieblingssehenswürdigkeit gekauft: das Atomium! Schon seid meiner Kindheit war ich fasziniert von diesem Gebäude gewesen. Ich fand einfach
  das Konzept toll, einen Verbund aus Atomen als ein riesiges, begehbares Gebäude nachzubauen! Und die Erfahrung war wirklich toll! In jeder Kugel befand sich eine
  andere Ausstellung oder Lichtershow und man konnte sich mit Rolltreppen und Fahrstühlen zwischen den Kugeln bewegen. Von der obersten Kugel aus hatte man eine schöne Aussicht.
  </p>
  <div style="display: flex;">
    <div><img src="@/assets/images/Belgien/Gruppenbild.jpg" alt="Gruppenbild vor Atomium" style="height: 601px; margin: 0 8px 0 0px;"/></div>
    <div>
      <img src="@/assets/images/Belgien/atomium1.jpg" alt="Atomium" style="height: 216px; margin: 0 3px 0 0px;"/>
      <img src="@/assets/images/Belgien/atomium2.jpg" alt="Atomium" style="height: 216px; margin: 0 0 0 3px;"/>
      <img src="@/assets/images/Belgien/ausblick.jpg" alt="Ausblick" style="width: 343px; margin: 0 0 0 0px;"/>
      <img src="@/assets/images/Belgien/rolltreppe.jpg" alt="Rolltreppe" style="height: 216px; margin: 0 4px 0 0px;"/>
      <img src="@/assets/images/Belgien/lichtshow.jpg" alt="Lichtshow" style="height: 216px; margin: 0 0 0 3px;"/>     
    </div>
  </div>

  <h2>Nieuwpoort</h2>
  <p>
    Gegen Nachmittag machten wir uns dann aber auf den Weg für das letzte Stück nach Nieuwpoort. Dort kamen wir in einer wunderschönen
    Ferienwohnung an und freuten uns darauf, hier entspannt gemeinsam die nächsten Tage zu verbringen. Zu Abend kochten wir uns eine große, herzhafte Portion Spaghetti Bolognese und alle waren glücklich, nach Langem endlich
    wieder etwas Gescheites zu essen zu haben. Nur merkte ich an diesem Tag, wie sich auch bei mir die ersten Anzeichen einer Erkältung deutlich machten...
  </p>
  <p>
    Am nächsten Tag hatte sich die Befürchtung bewahrheitet: ich hatte mich ziemlich hart erkältet. Die nächsten zwei Tage verbrachte ich also mit Fieber
    im Bett oder auf der Couch. Aber es war zu verkraften, wir hatten die Wii mitgenommen und ich hatte genug Bücher. Und so ist die nächsten Tage auch nicht
    wahnsinnig viel passiert. Da sich nacheinander natürlich auch die anderen ansteckten, verbrachten wir einfach sehr viel Zeit gemeinsam in der Ferienwohnung.
    Aber wir sind alle außerordentlich gut miteinander ausgekommen, haben gemeinsam gekocht, Spiele gespielt, Filme geschaut und harmonisch miteinander existiert.
  </p>
  <div>
    <img src="@/assets/images/Belgien/Totoro.jpg" alt="Totoro" style="height: 235px; margin: 0 6px 0 0px;"/>
    <img src="@/assets/images/Belgien/Spaghetti.jpg" alt="Spaghetti" style="height: 235px; margin: 0 3px 0 0px;"/>
    <img src="@/assets/images/Belgien/Meer.jpg" alt="Meer" style="height: 235px; margin: 0 0px 0 3px;"/>
    <img src="@/assets/images/Belgien/Strandgruppe.jpg" alt="Gruppenbild am Strand" style="height: 240px; margin: 0 6px 0 0px;"/>
    <img src="@/assets/images/Belgien/Strand1.jpg" alt="Strand" style="height: 240px; margin: 0 0 0 0px;"/>
  </div>

  <h2>Brügge</h2>
  <p>
    Letztendlich mussten wir doch nicht die gesamte Zeit in der Ferienwohnung verbringen. Am vorletzten Tag, als es den meisten einigermaßen gut ging,
    machten wir einen Ausflug in die mittelalterliche Stadt Brügge! Brügge wird wegen seiner vielen Kanäle auch das Venedig des Nordens genannt.
    An dem Tag hatten wir schönes Wetter und nahmen uns viel Zeit, die historische Stadt mit den vielen Kirchen und schönen Gebäuden anzuschauen. Wir statteten
    dem Foltermuseum einen Besuch ab und natürlich machten wir auch eine obligatorische Fahrt durch die Grachten.
  </p>
  <div>
    <img src="@/assets/images/Belgien/brugge.jpg" alt="Malerwinkel in Brügge" style="height: 380px; margin: 0 3px 0 0px;"/>
    <img src="@/assets/images/Belgien/radhuis.jpg" alt="Brügger Radhaus" style="height: 380px; margin: 0 3px 0 3px;"/>
    <img src="@/assets/images/Belgien/gruppenbild_brugge.jpg" alt="Gruppenbild am Hafen" style="height: 380px; margin: 0 0px 0 3px;"/>
    <img src="@/assets/images/Belgien/gebaeude2.jpg" alt="Gebäude in Brügge" style="height: 230px; margin: 0 3px 0 0px;"/>
    <img src="@/assets/images/Belgien/alex_shop.jpg" alt="Alex vor dem Alex Shop" style="height: 230px; margin: 0 3px 0 3px;"/>
    <img src="@/assets/images/Belgien/bruecke_in_brugge.jpg" alt="Alex auf Brücke in Brügge" style="height: 230px; margin: 0 0 0 3px;"/>
  </div>

  <h2>Letzter Tag und Abfahrt</h2>
  <p>
    Am letzten Tag verbrachten wir noch einen enstpannten Tag im lokalen Freizeitbad, in dem sich Nina leider eine Schnittwunde zulegte.<br>
    Und am Tag darauf stand auch schon die Abreise an, auf der wir uns natürlich grandios verfahren haben und plötzlich im Belgischen Nirgendwo auftauchten!
    Aber insgesamt war es trotz Krankheit ein wunderschöner und entspannter Urlaub mit den besten Menschen!
  </p>
  </div>
</template>
