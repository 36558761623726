<template>
  <div id="Tabelle" >
  <table style="width:190px">  <!--:style="`width: ${getWidth}`"!-->
    <tr v-for="elem in subMenuContent" :key="elem">
      <td class="td"><router-link :to="elem[0]" style="color:white">{{elem[1]}}</router-link></td>
    </tr>
  </table>
 </div>
</template>

<script>
  export default{
    name: 'SubMenu',
    data() {
      return {
        content: [],
      }
    },

    computed: {
    subMenuContent() {
      const routeName = this.$route.name;
      const routePath = this.$route.path;
      if(routeName == 'Home') {
        this.content = [
          ["/projekte/bachboot", "Bachboot"],
          ["/buecher", "Bücher"],
          ["/learn-russian1", "Learn Russian"],
          ["/mensch-und-welt", "Mensch und Welt"],
          ["/projekte/tinyhouse", "Tinyhouse"]
        ]
      }
      if(routePath.match("ueber-mich") || routeName == 'Buecher' || routeName == 'zamonien') {
        this.content = [
          ["/ueber-mich/steckbrief", "Steckbrief"],
          ["/buecher", "Bücher"],
        ]
      }
      if(routePath.match("projekte")){
        this.content = [
          ["/projekte/bachboot", "Bachboot"],
          ["/projekte/tinyhouse", "Tinyhouse"],
          ["/projekte/ukulele", "Ukulele"],
        ]
      }
      //if(routeName.match(/beet/g) || routeName == 'loch' || routeName == 'hochsitz') {
      if(routePath.match("garten")){
        this.content = [
          ["/garten/beet2021", "Beet 2021"],
          ["/garten/beet2020", "Beet 2020"],
          ["/garten/beet2019", "Beet 2019"],
          ["/garten/beet2018", "Beet 2018"],
          ["/garten/beet2017", "Beet 2017"],
          ["/garten/beet2016", "Beet 2016"],
          ["/garten/beet2015", "Beet 2015"],
          ["/garten/loch", "Das Loch"],
          ["/garten/sonnenbeet", "Sonnenbeet"],
          ["/garten/hochsitz", "Hochsitz"]
        ]
      }
      if(routePath.match("reisen")){
        this.content = [
          ["/reisen/holland", "Holland Roadtrip"],
          ["/reisen/interrail", "Interrail-Trip"],
          ["/reisen/daenemark", "Dänemark"],
          ["/reisen/belgien", "Belgien"],
          ["/reisen/foehr", "Föhr"],
          ["/reisen/berlin-hamburg-nordsee", "Berlin - Hamburg - Nordsee"]
        ]
      }
      if(routePath.match("russian")) {
        this.content = [
          ["/learn-russian1", "Сашин день"],
          ["/learn-russian2", "Москва"],
          ["/learn-russian3", "Маша и медведь"],
        ]
      }
      if(routePath.match("impressum")) {
        this.content = []
      }
      if(routePath.match("mensch-und-welt") || routePath.match("gesellschaft") || routePath.match("philosophie")) {
        this.content = []
      }
      return this.content
    },
  }
  }

</script>

<style scoped>
  @import '../assets/styles/styles.css';

  #Tabelle {
    float:left;
    padding: 0px;
    color:white;
    margin-top: 462px;
    margin-bottom: 3px;
    margin-left: 4px;
    font-size:22px;
  }

  .td {
    color: white;
    background: #206020;
    padding: 10px 15px 10px 15px;
  }

  /* Hide SideMenu on mobile */
  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    #Tabelle {display: none;}
  }

</style>
