<template>
  <div style="position: relative; width: 930px; height: 400px;">
    <transition-group>
     <div v-for="(img, i) in images" :key="img">
       <img :src="img" v-if="i==getIndex" class="fade" />
     </div>
   </transition-group>
  </div>
</template>

<script>
import img1 from '../assets/images/main-slideshow/img1.png';
import img2 from '../assets/images/main-slideshow/img2.png';
import img3 from '../assets/images/main-slideshow/img3.png';
import img4 from '../assets/images/main-slideshow/img4.png';
import img5 from '../assets/images/main-slideshow/img5.png';
import img6 from '../assets/images/main-slideshow/img6.png';
import img7 from '../assets/images/main-slideshow/img7.png';
import img8 from '../assets/images/main-slideshow/img8.png';

export default {
name: "Slideshow",
data() {
  return {
    images: [
      img3,
      img1,
      img2,
      img4,
      img5,
      img6,
      img7,
      img8
    ],
    currentIndex: 0
  };
},

mounted: function() {
  this.startShow();
},

methods: {
  startShow: function(){
    setInterval(this.next, 7000);
  },
  next: function() {
    if (this.currentIndex+1 >= this.images.length) {
      this.currentIndex = 0
    } else {
      this.currentIndex++;
    }
  },
},

computed: {
  getIndex: function(){
    return this.currentIndex;
  }
}
};
</script>

<style scoped> /*scoped means it's only for this component*/

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 4s;
  animation-name: fade;
  animation-duration: 4s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

img {
height:400px;
width:100%;
}

</style>
