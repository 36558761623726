<template>
  <div style="position:relative; margin:-90px 30px 0px 30px; top:90px; font-size:20px;">
  <p style="font-weight:bold">Meine B&uuml;cher</p>
  <p style="text-align:justify; width:870px">Ich m&ouml;chte hier die B&uuml;cher vorstellen, die ich gelesen (oder auch als H&ouml;rbuch geh&ouml;rt) habe, beginnend mit dem Buch, das ich als letztes gelesen habe.
    Du kannst allerdings die <b>Reihenfolge &auml;ndern</b>. M&ouml;chtest du die Liste beispielsweise nach Titel sortieren, klicke auf die &Uuml;berschrift "Titel". Wenn du auf das Dreieck klickst, &ouml;ffnet sich meine kleine, <b>pers&ouml;nliche Rezension</b>, in der ich auch nichts spoilern werde.
   Ich leihe B&uuml;cher auch sehr gerne aus, allerdings nur, wenn ich dich pers&ouml;nlich kenne. Und nun viel Spa&szlig;!
  </p>
 </div>

 <router-link to="/buecher/zamonien" style="cursor:pointer"><div class="zamonien" style="color:black; margin:15px; margin-top:115px; font-size:17px">
   <table class="bookTable">
   <tr>
     <td>Die Zamonien-Romane</td>
     <td>Walter Moers</td>
     <td>Phantasy</td>
     <td>Deutsch</td>
     <td>sehr oft gelesen und gehört</td>
     <td><b>Ewiger Platz 1</b></td>
   </tr>
 </table>
</div></router-link>

 <a href="https://alexadventures.de/buecher"><button style="position: relative; margin: 0 0 0 15px">Urspr&uuml;ngliche Reihenfolge</button></a>

 <div style="margin:15px; font-size:17px">
   <table class="bookTable" id="myTable">
   <tr>
     <th><a @click="sortTable(0)" title="Nach Titel Sortieren">Titel<img src="@/assets/images/sort.png"/></a></th>
     <th><a @click="sortTable(1)" title="Nach Autor Sortieren">Autor*in<img src="@/assets/images/sort.png"/></a></th>
     <th><a @click="sortTable(2)" title="Nach Genre Sortieren">Genre<img src="@/assets/images/sort.png"/></a></th>
     <th><a @click="sortTable(3)" title="Nach Sprache Sortieren">Sprache<img src="@/assets/images/sort.png"/></a></th>
     <th><a @click="sortTable(4)" title="Nach Status Sortieren">Status<img src="@/assets/images/sort.png"/></a></th>
     <th><a @click="sortTable(5)" title="Nach Rating Sortieren">Rating<img src="@/assets/images/sort.png"/></a></th>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Three Body Problem">
      <p>Certainly a philosophical book with very interesting thoughts and topics.</p>
      </Collapsible>
     </td>
     <td>Cixin Liu</td>
     <td>SciFi</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td>(8/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Der Monustrumologe">
      <p>Ein sehr interessanter Roman über einen Jungen der bei einem Monsterforscher aufwächst. Die Geschichte ist teilweise sehr brutal und zum Ende hin wird es sehr spannend.</p>
      </Collapsible>
     </td>
     <td>Rick Yancey</td>
     <td>Roman</td>
     <td>Deutsch</td>
     <td>gehört</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Planet Magnon">
      <p>Ein sehr interessantes Buch bei dem ich aber zugeben muss, dass es ein wenig mein Verständnisvermögen übersteigt.<br>
      Die Handlung spielt in einer fernen Zukunft in einem Sonnenystem, welches von einer weit fortgeschrittenen, menschlichen Zivilisation bewohnt ist.
      Staaten gibt es nicht, stattdessen Kollektive, die Menschen mit verschiedenen Lebensphilosophien zusammenschließen und als Ersatz für Nationen oder Religionen fungieren.
      Regiert und gesteuert wird das ganze Sonnenystem von einer künstlichen Intelligenz, die für alle Menschen die bestmöglichen Lebensbedingungen ausrechnet und umsetzt.
      Das zugrundeliegende Thema scheint die Exploration von Lebensphilosophien zu sein, in einer Welt in der die meisten Sorgen des menschlichen Lebens, wie Nahrungssuche, Armut und
      Arbeit, überwunden sind. In einer solchen Welt können sich die Menschen mit immateriellen Gütern beschäftigen und eher nach bestimmten Lebenserfahrungen als nach materiellem Besitz streben.<br>
      Eine sehr faszinierende Geschichte über die ich mich gerne mal mit jemandem austauschen würde.</p>
      </Collapsible>
     </td>
     <td>Leif Randt</td>
     <td>Roman</td>
     <td>Deutsch</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="How to Stop Time">
      <p>Imagine there were people that no one knows about that live for many centuries. What would those people's lives be like?
      That's what this book is about. Certainly an interesting story.</p>
      </Collapsible>
     </td>
     <td>Matt Haig</td>
     <td>Roman</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="They Both Die at the End">
      <p>This is a very surreal story. In this world everyone who is about to die receives a phonecall 24h in advance to notify them so they can enjoy their last day of life.
      This happens to the 18 y/o protagonist. It's a very emotional story. He get's to meet another one who is exactly like him and they even fall in love. Of course the ending is really sad.</p>
      </Collapsible>
     </td>
     <td>Adam Silvera</td>
     <td>Roman</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Humans">
      <p>An alien creature is sent to earth to prevent a mathematician from solving the Riemann Hypothesis and thereby contributing to a great advancement of human society.
      This alien takes the form of this mathematician and replaces him. From this alien perspective many things that humans do seem very weird and they don't understand much of it. But as the alien
      more and more takes the position of the mathematician, they start to learn what it means to be human and start to change. This story was very interesting to listen to and I find that it explores the
      things that are really important in life and makes one value those more.</p>
      </Collapsible>
     </td>
     <td>Matt Haig</td>
     <td>Roman</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td>(8/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Midnight Library">
      <p>Do you ever lie awake in bed and wonder about all the possible lives you could have had if you had taken a different decision or had done something different in your past?
      This story explores exactly that. The protagonist is very insatisfied with her life and decides to end it. But instead of dying she wakes up in a library containing all the possible lives that
    she could have had. Through this library she is able to enter and live those lives to experience what they are like. She however is forced to leave these lives whenever she feels dissatisfaction.
      At the end, none of the lives are really better than hers because she realises that those are not really <i>her</i> lives but from a different 'her'.<br>
      I read this book when I was in a similar periode in my life and it has really helped me out. I recommend it, it's a really good book! </p>
      </Collapsible>
     </td>
     <td>Matt Haig</td>
     <td>Roman</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Artemisa">
      <p>This time Andy Weir's story doesn't take us to Mars but on a moon base where the female, arabic protagonist has to solve a case.<br>
      Imagining life on the moon is really interesting.</p>
      </Collapsible>
     </td>
     <td>Andy Weir</td>
     <td>SciFi</td>
     <td>Spanisch</td>
     <td>angebrochen</td>
     <td>(8/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="El Marciano (Der Marsianer)">
      <p>An astronaut is accidentally left alone on Mars after a mission. This is the story of how he manages to survive. Very well written and very well researched!</p>
      </Collapsible>
     </td>
     <td>Andy Weir</td>
     <td>SciFi</td>
     <td>Spanisch</td>
     <td>gehört</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="21 Lessons for the 21st Century">
      <p>The third one of Hararis books about humankind. This time he teaches us about the dangers of the different paths that it might take, mainly concerning artificial
      intelligence and the loss of a reason in life. It is a very important book for these times that definitely more people should read since many topics he embarks on are poorly understood or completely unheard of.</p>
      </Collapsible>
     </td>
     <td>Yuval Noah Harari</td>
     <td>Science</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Alex Through The Looking Glass">
      <p>A similarly nice book as its predecessor "Alex's Adventures in Numberland", written in much the same way.</p>
      </Collapsible>
     </td>
     <td>Alex Bellos</td>
     <td>Science</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Hitchhiker's Guide to the Galaxy">
      <p>A series that I thought I had to finally listen to, since it is a must for nerdy and sciency people. It was of course entertaining and I also learned
         that the reason of life is 42, but to me it wasn't more than that. It's not deeply philosophical and the humor is not quite mine.</p>
      </Collapsible>
     </td>
     <td>Douglas Adams</td>
     <td>Comedy, SciFi</td>
     <td>Englisch</td>
     <td>gehört</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="How To">
      <p>&Auml;hnlich gut wie sein Vorg&auml;nger "What If". Es ist immer wieder urkomisch und unterhaltsam, zu lesen, welch skurrilste Fragen sich mit Hife von Physik beantworten lassen.</p>
      </Collapsible>
     </td>
     <td>Randall Munroe</td>
     <td>Comedy, Science</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="La Sombra del Viento">
      <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
      Aprecio mucho los cuentos que tienen un sentido m&aacute;s profundo y cuales te dejan pensando.
      Adem&aacute;s me encantan libros que logran transmitir el esp&iacute;ritu de un tiempo pasado. En este caso Zafón nos lleva a una Barcelona misteriosa de los a&ntilde;os 40.
      El autor logra fusionar lo real con lo fantástico y en lugares arroja una luz sobre los crímenes de la Guerra Civil.<br>
      Me encanta el estilo de escritura.</p>
      <p style="display:none"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
      Ich schätze es sehr, wenn Bücher einen tieferen Hintergrund haben und einen noch nach dem Lesen in Gedanken verweilen lassen.
      Außerdem liebe ich Bücher, die es schaffen, den Geist einer vergangenen Zeit zu übermitteln. In diesem Fall nimmt uns Zafón mit in ein
      misteriöses Barcelona der 40er Jahre. Dem Autor gelingt es, das Reale mit dem Fantastischen zu fusionieren und wirft stellenweise auch ein Licht auf
      die Verbrechen des spanischen Bürgerkriegs.<br>
      Ich liebe den Schreibstil.</p>
      </Collapsible>
     </td>
     <td>Carlos Ruiz Zaf&oacute;n</td>
     <td>Roman</td>
     <td>Spanisch</td>
     <td>gehört</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Othello">
      <p>"Othello" is said to be a play of great value and Shakespeare is said to be a writer who was ahead of his time.
      As I am only a student I am not able to fully appreciate the genius of the play. I can only say that it is easy to understand
      (if you read the translation into modern English, of course) and the themes presented (jealousy, racism, sexism, ...) are easy to work with in class.
    In a nutshell: it's not a torture to read, like many other books we have had to read in school and the old language is fun to decipher.</p>
    </Collapsible>
     </td>
     <td>William Shakespeare</td>
     <td>Drama</td>
     <td>Fr&uuml;hneuenglisch</td>
     <td>gelesen</td>
     <td>(8/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="El Pr&iacute;ncipe de la Niebla">
      <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
      Una peque&ntilde;a historia de Zaf&oacute;n con todo lo que necesita: tiene lugar en el pasado (Galicia, 1933), no es superficial y como a&uacute;n
      me cuesta mucho tiempo leer en espa&ntilde;ol, me pareci&oacute; muy bien que s&oacute;lo tiene unas 200 p&aacute;ginas.</p>
      <p style="display:none"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
      Eine kleine Geschichte von Zaf&oacute;n mit allem, was sie braucht: sie findet in der Vergangenheit statt (Galizien, 1933), sie ist nicht
      oberflächlich und da ich noch recht viel Zeit brauche, Spanisch zu lesen, erschien es mir sehr angenehm, dass sie nur 200 Seiten hat.</p>
      </Collapsible>
     </td>
     <td>Carlos Ruiz Zaf&oacute;n</td>
     <td>Kurzroman</td>
     <td>Spanisch</td>
     <td>gelesen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Mother To Mother">
      <p>To many this might have been the most dull and exhausting to read book of all. So it was to me. I remember gradually working myself through the lines while sitting in the London
        Tube or in one of the various Great Western Railway trains, taking ages to complete one of those huge pages. The negative part was obviously, that the book really dragged on.
        Nevertheless I believe there are few books that give one a better, deeper, more personal and more authentic insight into the crude realiy of South Africa's apartheid regime.
        It was therefore (in parts) nevertheless interesting and capturing to read. For me it was especially interesting to get a look at the Xhosa language with its clicking sounds
        and to try to pronounce Mxolisi - the name of a character in the book.</p>
    </Collapsible>
     </td>
     <td>Sindiwe Magona</td>
     <td>Roman</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td>(6/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Die Verwandlung">
      <p>Was soll ich sagen? Ich bin ganz stolz darauf, das ber&uuml;hmteste Werk vom ber&uuml;hmten Kafka gelesen zu haben. Ich wei&szlig; jetzt auch, was <k>kafkaesk</k> bedeutet! Tats&auml;chlich war "Die Verwandlung" noch
        eines der angenehmsten B&uuml;cher, die wir im Deutschunterricht lesen mussten. Ich mochte die Surrealit&auml;t der Geschichte und die tr&auml;umerische Atmosph&auml;hre. Die kafkaesken Leerstellen gefielen mir jedoch nicht, da ich gerne genau bescheid wei&szlig;, was gerade
        in der Handlung passiert oder wie sie zu deuten ist. Au&szlig;erdem fand ich die Geschichte zu traurig und d&uuml;ster f&uuml;r meinen Geschmack.</p>
    </Collapsible>
     </td>
     <td>Franz Kafka</td>
     <td>Erz&auml;hlung</td>
     <td>Deutsch</td>
     <td>gelesen</td>
     <td>(6/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Woyzeck">
      <p>Mein erster Kontakt mit dem Drama "Woyzeck" war, als unser Deutschleher uns alle Szenen chaotisch und unsortiert auf Bl&auml;ttern austeilte.
        Denn "Woyzeck" ist nichts weiter als das - ein Dramenfragment. Mein erster eindruck war, dass Georg B&uuml;chner geistesgest&ouml;rt oder unter Drogen
        gewesen sein muss. Warum soll Woyzeck "an die Wand pissen"?! Was war &uuml;berhaupt los mit den Charakt&auml;ren, waren die alle aus dem Irrenhaus? "Blutwurst sagt: Leberwurst!"
        Erst nach ausf&uuml;hrlicher Auseinandersetzung mit dem Werk hat sich mir erschlossen, was hinter dem Werk steckt und auf welche genial einfache Weise
        Georg B&uuml;chner den Idealismus Schillers widerlegt und moderne ethische Fragen in den Raum stellt, wie beispielsweise die Frage nach Schuld in einer
        deterministischen Welt. "Woyzeck" ist klar ein Werk des Epochenumbruchs  aus einer Zeit gepr&auml;t von neuen wissenschaftlichen Erkenntnissen und
        dem Aufkommen nihilistischer Denkweisen. Wer sich gerne mit solchen Themen besch&auml;ftigt, dem empfehele ich, sich mit dem Buch einmal auseinanderzusetzen.
        Es sollte jedoch dazu auf jeden Fall Sekund&auml;rliteratur hinzugezogen werden.</p>
      </Collapsible>
     </td>
     <td>Georg B&uuml;chner</td>
     <td>Drama</td>
     <td>Deutsch</td>
     <td>gelesen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="To Kill A Mockingbird">
      <p>In contrast to many others I truly loved "To Kill A Mockingbird". It throws one back in time to an America in the midst of the Great
        Depression in the 1930s. It lovingly and authentically describes how life has been like in a rural smalltown of
        Alabama. I so much enjoyed the gothic atmosphere and the elaborate illustrations of the setting. The court scene captured me immensely,
        I was very taken after having finished the book. If a piece of literature is capable of evoking a range of strong emotions and is capable of
        creating such a vivid fictional reality, it certainly is of true excellence.</p>
      </Collapsible>
     </td>
     <td>Harper Lee</td>
     <td>Roman</td>
     <td>Englisch (Am)</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Alex's Adventures in Numberland">
      <p>This book was written by the British mathematician Alex Bellos whom I know from <a href="https://www.youtube.com/c/numberphile">Numberphile</a>.
        It was a really nice journey that gave me a glimpse into the world of Mathematics without digging too deep into it and getting too complicated.
        It is written as a reportage where Alex visits and interviews different, peculiar people that in some way have to do with maths.
        Alongside I learned many interesting things about the history of numbers, the golden ratio, the mentally sick duke who invented logharithms, a woman that
        is crocheting the hyperbolic plane, number series and many, many more things that unveil the beauty of maths.</p>
      </Collapsible>
    </td>
     <td>Alex Bellos</td>
     <td>Science</td>
     <td>Englisch (Br)</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Dingen Uitlegger">
        <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
         Het boek gebruikt alleen de 1000 meest gebruikte woorden, om ingewikkelde dingen uitteleggen. Het gebruikt dus erg eenvoudige woorden. Toentertijd ben ik begonnen Nederlands te leren. Daarom heb ik het boek in Nederlands gekocht.
         Sindsdien vind ik het heel leuk, de grappige Nederlandse taal te lezen.
         Bovendien is het boek heel grappig te lezen, omdat de auteur manieren moet vinden, ingewikkelde vaktermen te omschrijven. Ik prijs het aan!</p>
        <p style="display:none"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
         Das Buch benutzt nur die 1000 meistgesprochenen Wörter, um komplizierte Dinge zu erklären. Es verwendet daher sehr einfache Wörter. Zu der Zeit habe ich begonnen, Niederländisch zu lernen.
         Darum habe ich es auf Niederländisch gekauft. Außerdem ist es sehr amüsant, die lustige Niederländische Sprache zu lesen und es ist außerdem lustig zu lesen, welche Wege der Autor findet,
         komplizierte Fachbegriffe mit sehr einfachen Begriffen zu umschreiben. Ich empfehle es!</p>
       </Collapsible>
     </td>
     <td>Randall Munroe</td>
     <td>Comedy, Science</td>
     <td>Niederl&auml;ndisch</td>
     <td>fast fertig</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Homo Deus">
      <p>This is the sequel of the book Sapiens which I have reviewed a few lines lower. Rather than telling the story of where humans came from and how
      we developed, Homo Deus (deus lat. God) deals more with the abilities acquired by humans throughout their existence, describes the newest discoveries and achievements in science
      and attempts to paint an image of the future. Many philosophical issues are discussed, such as the human experience, individualism, human emotion, and consciousness.</p>
      </Collapsible>
     </td>
     <td>Yuval Noah Harari</td>
     <td>Science</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="O Alquimista">
      <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
        &Ocirc; que livro mais lindo! &Eacute; uma hist&oacute;ria de um rapaz que decidiu ir-se de sua casa para viajar pelo mundo como pastor.
        &Eacute; uma hist&oacute;ria sonhadora e fant&aacute;stica com acontecimentos sobrenaturais. &Eacute; um livro filos&oacute;fico, uma explora&ccedil;&atilde;o das secretas da vida e da felicidade e da linguagem da natureza e do destino.
        Me encantou! E tamb&eacute;m me a feito muito prazer ler em portugu&ecirc;s porque o portugu&ecirc;s é um idioma muito lindo.</p>
      <p style="display:none;"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
        Der Alchimist<br><br>Ach, was f&uuml;r ein sch&ouml;nes Buch! Es ist die Geschichte eines jungen Mannes, der beschlossen hat, sein Zuhause zu verlassen, um als Hirte die Welt zu bereisen.
        Es ist eine traumhafte und fantastische Geschichte mit &uuml;bernat&uuml;rlichen Ereignissen. Es ist ein philosophisches Buch, eine Erforschung der Geheimnisse des Lebens und des Gl&uuml;cks sowie der Sprache der Natur und des Schicksals.
        Es hat mir sehr gut gefallen! Ich hatte auch viel Freude daran, Portugiesisch zu lesen, weil Portugiesisch eine so sch&ouml;ne Sprache ist. </p>
      </Collapsible>
     </td>
     <td>Paulo Coelho</td>
     <td>Roman</td>
     <td>Portugiesisch (Br)</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="El D&iacute;a Que Se Perdi&oacute; La Cordura">
      <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
      Este fue el primer libro espa&ntilde;ol que le&iacute; voluntariamente. Aprend&iacute; el ingl&eacute;s a traves de libros y por eso,
      cuando ten&iacute;a 16 a&ntilde;os, decid&iacute; empezar a leer libros espa&ntilde;oles también. Este libro es un Thriller. Tiene lugar en los EE.UU.
      y la manera en que fue escrito mola mucho: hay tres tramas diferentes que al final se unen y s&oacute;lo al final todo se pone claro. Le&iacute; el
      libro dos veces porque la primera vez no lo entend&iacute; bastante bien.</p>
      <p style="display:none;"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
      Der Tag, An Dem Man Den Verstand Verlor<br><br>Dies war das erste Spanische Buch, welches ich freiwillig gelesen habe. Ich habe Englisch
      &uuml;ber B&uuml;cher gelernt und so beschloss ich mit 16 Jahren, anzufangen, auch spanische Bücher zu lesen. Dieses Buch ist ein Thriller. Es spielt
      in den USA und die Weise in der es geschrieben wurde ist sehr cool: es gibt drei verschiedene Handlugsstr&auml;nge und am Ende vereinen sie sich und alles wird klar.
      Ich habe das Buch zweimal gelesen, weil ich beim ersten Mal noch nicht so viel verstanden habe wie ich wollte.</p>
      </Collapsible>
     </td>
     <td>Javier Castillo</td>
     <td>Thriller</td>
     <td>Spanisch</td>
     <td>2x gelesen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Sapiens">
      <p>This book was life-changing for me. It completely transformed my view on religion, my understanding of History and my
      anticipation of the future. Sapiens tells the story of humans - where we came from and where we're headed or as Wikipedia puts it: Harari recounts the course of history while describing events and the individual human experience,
      along with ethical issues in relation to his historical survey.
      One might consider the day I opened the first page of that book a turning point in my view and understanding of the world that brought me onto a new path:
      one of a philosophy of science and utilitarism but also one of determinism and nihilism.
      The book is not for nothing a bestselling book.</p>
      </Collapsible>
     </td>
     <td>Yuval Noah Harari</td>
     <td>Science</td>
     <td>Englisch</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Le Petit Prince">
        <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
        Dans le temps quand j'&eacute;tudiais le fran&ccedil;ais, j'ai achet&eacute; le c&eacute;l&egrave;bre livre du Petit Prince et je donc appris un peu de la langue.
        C'est une jolie petite histoire.</p>
        <p style="display:none;"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
        Zu der Zeit, in der ich Französich gelernt habe, kaufte ich das berühmte Buch "Der kleine Prinz" und so konnte ich die Sprache etwas lernen. Es ist eine hübsche,
        kleine Geschichte über das Kindsein und die skurrile, widersprüchliche Welt der Erwachsenen.</p>
      </Collapsible>
     </td>
     <td>Antoine de Saint-Exup&eacute;ry</td>
     <td>Roman</td>
     <td>Franz&ouml;sisch</td>
     <td>gelesen</td>
     <td>(8/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Al Otro Lado de la Pantalla">
        <p><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[1].style.display='block';this.parentNode.parentNode.children[0].style.display='none';">&Uuml;bersetzung</a><br><br>
        Este fue el primer libro espa&ntilde;ol que leí. Lo tuvimos que leer para el colegio. Aunque fue un gran desafío entenderlo al principio, me dio un gran salto en mi conocimiento
        del idioma. Me habrió la puerta al mundo de la literatura y a otros medios espa&ntilde;oles como películas o vídeos de YouTube. Fue entonces crucial para el desenvolvimiento de mi presente habilidad de escribir, hablar y entender el Espa&ntilde;ol.<br>
        En cuanto al contenido, no es muy profundo. Es un relato simple de unos chicos en el instituto con problemas ordinarios.</p>
        <p style="display:none;"><a style="color:blue; text-decoration:underline" onclick="this.parentNode.parentNode.children[0].style.display='block';this.parentNode.parentNode.children[1].style.display='none';">Original</a><br><br>
        Dies war das erste spanische Buch, das ich gelesen habe. Obwohl es anfangs eine große Herausforderung war, es zu verstehen, gab es mir einen riesigen Sprung
        in meiner Kenntnis der Sprache. Es öffnete mir die Tür in die Welt der Literatur und zu anderen spanischen Medien wie Filmen oder YouTube-Videos. Es war deshalb zentral für die
        Entwicklung meiner heutigen Fähigkeiten Spanisch zu schreiben, zu sprechen und zu verstehen.<br>
        In Hinblick auf den Inhalt ist es nicht sehr tiefgründig. Es ist eine simple Erzählung über Oberstufenschüler mit ordinären Problemen.</p>
      </Collapsible>
     </td>
     <td>Alba Quintas Garciandias</td>
     <td>Roman</td>
     <td>Spanisch</td>
     <td>gelesen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Die K&auml;nguruchroniken (1-4)">
      <p>Die Bücher sind allesamt ein geniales Werk des links-liberalen Satierikers und Kleinkünstlers Mark-Uwe Kling. Es ist eine köstliche Sartiere über die Deutsche Gesellschaft, den Kapitalismus
      und vieles mehr, ohne zu avatgardistisch zu sein. Witzig in jedem Satz und Quelle so vieler Insider!</p>
      </Collapsible>
     </td>
     <td>Mark-Uwe Kling</td>
     <td>Sartiere</td>
     <td>Deutsch</td>
     <td>geh&ouml;rt</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Qualityland (1-2)">
      <p>Eine unglaublich gute Science-Fiction-Sartiere von Mark-Uwe Kling über ein scheinbar zukünftiges Deutschland, in dem jeder gegenwärtige Trend, sei es Kapitalismus, Technologien oder Medien, ins
      Extreme gezogen ist. Es sehr witzig und unterhaltsam, aber viel wichtiger sind die Implikationen die dahinter stecken. Ich empfinde beide Teile als sehr wichtige Bücher der Gegenwart, die uns vor falschen Schritten
      in die Zukunft warnen und extrem wichtige Probleme thematisieren.</p>
      </Collapsible>
     </td>
     <td>Mark-Uwe Kling</td>
     <td>Sartiere, SciFi</td>
     <td>Deutsch</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Absolutely True Diary...">
      <p>...Of A Part-Time Indian. Ein Buch &uuml;ber das harte Leben eines Native Americans in einem Reservat in der heutigen Zeit. Es wirft einen Blick auf die traurige Realit&auml;t
      der Nachkommen der Ureinwohner Amerikas und hat ein sehr trauriges Ende.</p>
    </Collapsible>
    </td>
     <td>Sherman Alexie</td>
     <td>Kurzroman</td>
     <td>Englisch (Am)</td>
     <td>gelesen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The ABC's of LGBT+">
      <p>This is by no means a scientific book but rather a wonderful and hugely extensive guide to the world of LGBT+, filled
      with so much love and compassion, created by my favourite queer youtuber at that time Ash Hardell.<br>
      Countless spectrums and an immense variety of lables help you find your place if you are questioning you gender or sexuality. And
      even for rigidly straight people this guide explains all those terms that one otherwise quickly gets lost in so quickly really well.</p>
      </Collapsible>
     </td>
     <td>Ash Hardell</td>
     <td>Guide</td>
     <td>Englisch (Am)</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Power of Five (1-5)">
      <p>So I had finished all of the Alex Rider books and I also quite liked the other story by Anthony Horowitz so, without any preconceptions,
      I decided to listen into the "Power of Five" series. This is one of those stories that start in the most ordinary way but over the course of the books transform in such a wild way in which you
      would never have expected it to when you first started reading. For me this transformation into something really big made me love these books so much which is also why I don't want to provide any
      further information regarding the content.</p>
      </Collapsible>
     </td>
     <td>Anthony Horowitz</td>
     <td>Phantasy</td>
     <td>English (Br)</td>
     <td>2x geh&ouml;rt</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="The Word Is Murder">
      <p>This book was so realistically written that even at the end of the book I was still convinced that the story had really taken place in reality.
      It was only afterwards when I looked up if something has been written in the news about the incidents that I was told otherwise. The author Anthony Horowitz himself is the protagonist and pretends to have accompanied a commissar on his way around London to solve a murder case. This book was just so realistically written that now that I
      know it is fiction I believe it is a true artwork!</p>
      </Collapsible>
     </td>
     <td>Anthony Horowitz</td>
     <td>Crime</td>
     <td>English (Br)</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Alex Rider (1-10)">
      <p>The Alex Rider books were possibly even more action-packed than the Young James Bond books. I listended to the audiobooks and they helped me to learn
      the pronounciation of the English language (namely British English). I never would have thought, though, that I would ever read such kinds of books.
      Nonetheless, rather <i>because</i> of the constant excitement, these books created such a vivid and fulfilling reading experience. Nowadays I would'nt read them again, though,
      as it is apparent that these books are aimed to a younger readership and therefore I wouldn't enjoy then as much as I did in my young teenage years.</p>
      </Collapsible>
     </td>
     <td>Anthony Horowitz</td>
     <td>Action</td>
     <td>English (Br)</td>
     <td>geh&ouml;rt</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Young James Bond (1-6)">
      <p>My second English book series, lent to me by a good friend.<br>
      These books might have been even more British than the Young Sherlock Holmes books. Again I was drawn into a bygone time and
    taken onto a journey to differnet places of the world. On my <a href="reisen.htm" style="color:blue">first journey to England</a> I even visited the
    setting of the school - Eton College. These books were certainly more action-packed than the Young Sherlock Holmes books and thus uniquely thrilling.</p>
  </Collapsible>
     </td>
     <td>Charlie Higson</td>
     <td>Action</td>
     <td>Englisch (Br)</td>
     <td>gelebt</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="What If?">
      <p>"What If" is also one of the first English books that I read. The author Randall Munroe is the creator of the web comic <a href="https://xkcd.com/">XKCD</a>. He studied physics and used to work at NASA. Since 2006 he
        dedicates his time to his web comic and answers the most absurd questions that are posted on the internet. "What If" is a humorous collection of scientific answers to questions like: What would happen when you bring all elements of
      the periodic table together? What is the probability that if you call a random person and say "bless you", that they actually just sneezed? If an asteroid was very small but supermassive, could you really live on it like the Little Prince?
      And MANY more!<br>
      In 9th grade I once made a Power Point presentation about the book which, as I find, offers a very good glance at the book to those, who are interested.<br>
      <a href="@/assets/What If.pptx" download>Download Presentation</a><br>
      </p>
      </Collapsible>
     </td>
     <td>Randall Munroe</td>
     <td>Science</td>
     <td>Englisch (Am)</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Elfenlied (1-12)">
      <p>Ich habe zuerst den Anime dazu geschaut. Das war mit 14 Jahren spannend, weil das wegen dem ganzen Blut und so erst ab 18 war. ^^<br>
      Habe seit dem die <a href="https://www.youtube.com/watch?v=MPVq30bPq6I">Titelmelodie</a> nicht mehr aus dem Kopf gekriegt, sie ist einfach wundersch&ouml;n!<br>
      Danach habe ich die Mangas (komplett!) gelesen. Es ist zwar schon verdammt lange her, aber ich wei&szlig; definitiv noch, dass es eine sehr mitreißende und hoch spannende (thrilling) Geschichte war.
      Was ich damals &uuml;brigens noch nicht verstanden hatte ist, dass die Mangas haupts&auml;chlich auf Jungs ausgerichtet sind, die leicht bekleidete Frauen sehen wollen.
      Die Mangas sind ein bisschen voll damit...</p>
    </Collapsible>
     </td>
     <td>Lynn Okamoto</td>
     <td>Manga, Thriller</td>
     <td>Deutsch</td>
     <td>gelesen</td>
     <td>(9/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Assassination Classroom (1-9)">
      <p>Ein lustiger, netter Manga, von dem ich damals einige Teile lesen durfte, aber doch nicht die Motivation hatte, alle 20 Teile, oder so, zu Ende zu lesen.</p>
    </Collapsible>
     </td>
     <td>Y&#363;sei Matsui</td>
     <td>Manga</td>
     <td>Deutsch</td>
     <td>angebrochen</td>
     <td>(7/10)</td>
   </tr>
   <tr>
     <td>
      <Collapsible label="Young Sherlock Holmes (1-8)">
      <p>These were the first English books I read in my life, lent to me by a good friend. With 14 years I started to read the first chapter
      of the first book and I had to look up words every two to three sentences. There were strange and new words like "pillar", "ornaments" or "reluctance"
      but also old and fancy words like "luncheon" or "acquaintance". I loved how I was drawn into a completely different period of time - the Victorian age.
      The details of the setting and the circumstances at the time were thoroughly researched and elaborately illustrated. All eight books were full and
      round stories. With these books I fell in love with the country of England and its languange.</p>
     </Collapsible>
     </td>
     <td>Andrew Lane</td>
     <td>Detective</td>
     <td>Englisch (Br)</td>
     <td>gelesen</td>
     <td><b>(10/10)</b></td>
   </tr>
 </table>
 </div>
</template>

<style scoped>
.bookTable {
 border-spacing: 0;
 width: 100%;
 border: 1px solid #ddd;
}

.zamonien:hover {
  background-color:#f2f2f2;
}

p{
  width:400px
}

li {
 padding: 5px;
}

th {
 cursor: pointer;
}

th, td {
 text-align: left;
 padding: 10px;
}

tr:nth-child(even) {
 background-color: #f2f2f2
}

   #hui
   {
   width:400px;
   list-style-type:none;
   background: #FFFFFF;
   }
</style>

<script language="javascript" type="text/javascript">
import Collapsible from '../../components/Collapsible.vue';

export default{
  name: 'Buecher',
  components: {
    Collapsible,
  },

  methods: {
    refresh: function(){
      window.location.reload();
    },
    sortTable: function(n) {
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById("myTable");
      switching = true;
      //Set the sorting direction to ascending:
      dir = "asc";
      /*Make a loop that will continue until
      no switching has been done:*/
      while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /*Loop through all table rows (except the
        first, which contains table headers):*/
        for (i = 1; i < (rows.length - 1); i++) {
          //start by saying there should be no switching:
          shouldSwitch = false;
          /*Get the two elements you want to compare,
          one from current row and one from the next:*/
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];
          /*check if the two rows should switch place,
          based on the direction, asc or desc:*/
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              //if so, mark as a switch and break the loop:
              shouldSwitch= true;
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              //if so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          /*If a switch has been marked, make the switch
          and mark that a switch has been done:*/
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          //Each time a switch is done, increase this count by 1:
          switchcount ++;
        } else {
          /*If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again.*/
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    }

  }
}

</script>
