<template>
 <div style="position:relative; width:830px; margin: 0px 0px 0px 50px; font-size:19px; text-align:justify">
 <p style="font-weight:bold; font-size:1.7em; margin-bottom:10px;">Mensch und Welt</p>
 <p><i>Sch&ouml;n, dass du dich in diese etwas versteckte Sektion verirrt hast!</i><br><br>
 Diese Sektion ist dazu gedacht, Gedanken mit der Welt zu teilen. Ich ver&ouml;ffentliche hier primär meine Gedanken, aber auch gerne welche, die mir von euch
 Lesenden zugesendet werden.<br>
 Im Abschnitt "Gesellschaft" m&ouml;chte ich Meinungen, Gedanken und Denkanst&ouml;&szlig;e &uuml;ber Gesellschaftliches, Politik oder andere Lebensaspekte teilen.<br>
 Im Abschnitt "Philosophie" behandle ich tiefergehende Themen wie Gott, den Sinn des Lebens, Gl&uuml;ck, das menschliche Bewusstsein und au&szlig;erirdisches Leben.</p>
 <div style="width:450px; position:relative; margin: 0 auto;">
 <router-link to="/gesellschaft" class="tooltip" style="cursor: pointer;"><img src="@/assets/images/Gesellschaft.png" style="width: 200px; padding:30px 10px 40px 15px;"/><span class="tooltiptext left"><i>Gesellschaftliches, Politik und andere Lebensaspekte</i></span></router-link>
 <router-link to="/philosophie" class="tooltip" style="cursor: pointer;"><img src="@/assets/images/Philosophie.png" style="width: 200px; padding:30px 10px 40px 10px;"/><span class="tooltiptext right"><i>Gott, Leben, Tod und Existenz</i></span></router-link>
 </div>
</div>
</template>

<style scoped>


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  color: black;
  text-align: center;
  padding: 5px 0; }

.left{
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 105%;
}

.right{
  position: absolute;
  z-index: 1;
  top: 43px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
