<template>
<div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:20px; text-align:justify">
   <p style="font-weight:bold; font-size:24px">
     Das Beet 2017
   </p>
   <p style="text-align:justify; font-size:21px">
     Jenes  Jahr  lief  einiges  besser.    Ich  hatte  das  Jahr  davor Erdbeeren  eingepflanzt,  diese  trugen  dieses  Jahr  ein  paar Fr&uumlchte.   Au&szligerdem   habe   ich   auf   dem   Gem&uumlseberg   jetzt keine  komplizierten  Gem&uumlse  mehr  angepflanzt,  stattdessen Kr&aumluter.   Ich   hatte   dieses   Jahr   auch   viel   Getreide,   Spinat, Gurken  und  Mangold,  zudem  noch  ein  paar  Kartoffeln  und Karotten,   leider   keine   Tomaten.   Im   Hochbeet   konnte   ich wieder    Zucchini    ernten.    Ich    habe    auch    mit    Spiegeln experimentiert,  die  das  Sonnenlicht  auf  die  Schattigen  Stellen in  meinem  Beet  leiten  sollen.  Ich  habe  mich  aber  zu  wenig damit besch&aumlftigt, als dass es funktionierte.
   </p>
<div style="display: block; margin-left: auto; margin-right: auto">
 <img src="@/assets/images/garten/168.jpg" alt="" title="" style="width: 796px;"/>
 <div>
 <img src="@/assets/images/garten/169.jpg" alt="" title="" style="width: 286px; height:510px"/>
 <img src="@/assets/images/garten/170.jpg" alt="" title="" style="width: 510px;"/>
</div>
 <img src="@/assets/images/garten/171.jpg" alt="" title="" style="width: 796px;"/>
 <img src="@/assets/images/garten/PeeingPot2.jpeg" alt="" title="" style="float:left; width: 200px; margin-bottom:25px; margin-right:20px"/>
 <p style="font-size:20px;">Eine Kuriostit&auml;t:<br> Der "Peeing Pot"</p>
 <router-link to="/garten/beet2018">
   <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 160px 0 25px 0; width: 150px;"/>
 </router-link>
</div>

</div>
</template>
