<template>
  <div style="position:relative; width:855px; margin: 10px 0px 0px 50px; text-align:justify">
    <div style="margin: 40px 30px 30px 10px">
   <p>Hey du!<br><br>
     Willkommen auf meinem Blog! Hier kannst du einen Einblick in die Dinge bekommen, die ich so tue. Du kannst dich &uuml;ber
     meine Bau- und Gartenprojekte informieren, dich durch Reiseberichte und B&uuml;cherempfehlungen klicken und noch vieles mehr.<br><br>
     Viel Spa&szlig; dabei!
   </p>
   <p style="padding-top:20px; padding-bottom:10px">
     <b>Hinweis!</b><br>
     Diese Webseite befindet sich noch im Aufbau. Wenn ein Link nicht funktioniert, etwas komisch dargestellt ist oder ihr einen Rechtschreibfehler entdeckt, benachrichtigt mich bitte sofort!
   </p>
  </div>
  </div>
</template>

<script>
export default {
}
</script>
