<template>
 <div style="position:relative; width:855px; margin: 0px 0px 40px 40px; text-align:justify">

 <h1>Ukulele</h1>

 <p><span>
   Als ich etwa 17 Jahre alt war, habe ich mir in den Kopf gesetzt, eine Ukulele zu bauen.
   Ich hatte mir zuerst sämtliche YouTube-Videos dazu angeschaut, wie Gitarren und Ukulelen gebaut werden und mir dann überlegt, wie ich dies selbst umsetzen kann.
   Ich habe mich außerdem dazu entschieden, eine Baritonukulele zu bauen. Diese ist deutlich größer als eine konventionelle Sopranukulele und klingt auch dementsprechend tiefer.<br>
   Ich musste mir erstmal überlegen, wie ich den geschwungenen Körper bauen würde. Als Lösung erwiesen sich hier MDF-Platten &ndash; ein Material, das hauptsächlich aus Holzfasern und Leim besteht.
   Gibt man sie in heißes Wasser, lassen sie sich wunderbar wie Papier verbiegen und werden nach dem Trocknen wieder so fest wie vorher. <br>
   Ich habe also eine Form aus Holz angefertigt und damit die nassen, heißen MDF-Streifen in Form gebracht. Um noch mehr Stabilität zu gewährleisten,
   musste in den Korpus jedoch noch ein "Skelett" geklebt werden. Das erwies sich als deutlich schwieriger. Schließlich hat es funktioniert, sehr viele Rillen in einen Stab aus Fichtenholz zu ritzen,
   welcher sich dann in heißes Wasser getränkt gut in die gewünschte Form verbiergen ließ.
 </span></p>

 <div style="margin:0 auto 0 155px">
 <img width="230" style="padding: 0 7px 0 7px"  src="@/assets/images/Ukulele/uku1.jpg" v:shapes="Bild_x0020_8">
 <img width="286" style="padding: 0 7px 0 7px" src="@/assets/images/Ukulele/uku2.jpg" v:shapes="Bild_x0020_7">
 </div>

 <p><span>
   Der Korpus ist mit einfachen Modellholzplatten verschlossen. Nun ging es daran, den Hals zu gestalten. Er ist aus einem einzelnen Stück gesägt und gefeilt.
   Besonders das Feilen hat sehr viel Zeit in Anspruch genommen. Er wurde schließlich angeklebt. Für die Optik habe ich mich dazu entschieden, die Vorderseite des Halses
   mit einem Streifen der MDF-Platte zu verkleiden.
 </span></p>

 <div style="margin:0 auto 0 70px">
 <img width="229" height="313" style="padding: 0 7px 0 7px"  src="@/assets/images/Ukulele/uku3.jpg" v:shapes="Bild_x0020_8">
 <img width="450" height="313" style="padding: 0 7px 0 7px" src="@/assets/images/Ukulele/uku4.jpg" v:shapes="Bild_x0020_7">
 </div>

 <p><span>
   Nun war die Ukulele auch schon fast fertig. Ich musste nur noch die Stimmwirbel (die Dinger, an denen man die Saiten aufzieht) einsetzten und die Metallstäbchen
   mit Sekundenkleber auf den Hals kleben. Für den richtigen Abstand gibt es eine mathematische Formel, aber in der echten Welt ist nichts perfekt, also musste ich
   die richtigen Abstände doch durch Raten herausfinden. Dann habe ich nur noch die Saiten aufgezogen und fertig war sie!
 </span></p>

 <div style="margin:0 auto 0 155px">
 <img width="250" style="padding: 0 7px 0 7px"  src="@/assets/images/Ukulele/uku5.jpeg" v:shapes="Bild_x0020_8">
 <img width="252" style="padding: 0 7px 0 7px" src="@/assets/images/Ukulele/uku6.jpg" v:shapes="Bild_x0020_7">
 </div>

</div>
</template>
