<template>
  <div style="position:relative; width:850px; margin: 0px 0px 0px 40px; font-size:21px; text-align:justify">
    <p style="font-size:24px;"><b>Das Beet 2021</b></p>
    <p>
      In diesem Jahr hatte ich eine wirklich reichliche und ertragreiche Ernte wie in noch keinem Jahr!<br>
      Im April packte mich auf einmal wieder die Gartenlust und ich machte mich auf zum Baumarkt und packte das Auto voll.
    </p>
    <img src="@/assets/images/garten/IMG_20210422_185800.jpg" alt="" title="" style="width: 700px; margin: 0 70px 0 70px"/>
    <p>
      Die eine Seite pflanzte ich voll mit Erdbeeren, Salat, Gurken und verschiedenen Kräutern, die andere mit Kartoffeln und Tomaten.
    </p>
    <img src="@/assets/images/garten/IMG_20210424_095415.jpg" style="width: 420px;"/>
    <img src="@/assets/images/garten/IMG-20210604-WA0001.jpeg" style="width: 420px; margin: 0 0px 0 10px"/>
    <p>
      Außerdem war es nun das dritte Jahr, nachdem ich den Spargel eingepflanzt hatte, das heißt, die Pflanzen waren nun endlich
      kräftig genug, um sie zu ernten. Und im April schauten auch schon die ersten Köpfe aus den Dämmen!<br>
      Ich habe ihn aber in meinem Eifer wohl etwas zu früh geerntet. Es stellte sich heraus, dass die Stangen noch sehr dünn waren,
      deshalb war am Ende dann nicht so viel auf dem Teller. Sie schmeckten aber dafür sehr lecker nach Spargel und waren auch nicht holzig.
    </p>
    <img src="@/assets/images/garten/IMG_20210425_111152.jpg" style="width: 380px; height:400px"/>
    <img src="@/assets/images/garten/IMG_20210501_184032.jpg" style="width: 225px; margin: 0 0px 0 10px"/>
    <img src="@/assets/images/garten/IMG_20210502_122800.jpg" style="width: 225px; margin: 0 0px 0 10px"/>
    <p>
      Die restliche Ernte war jedoch ein voller Erfolg. Über viele Wochen konnte ich Salat, reichlich Minze, Zitronenmelisse und Rukola pflücken.
      Ich konnte sicher fünf oder sechs Salatgurken ernten und eine von denen war richtig fett!<br>
      Im August habe ich einen ganzen Eimer Kartoffeln geerntet und Tomaten hatte ich auch unzählige.<br>
      Es war allerdings ein Fehler, die Tomaten direkt neben die Kartoffeln zu setzten. Genau das soll man eigentlich nicht tun, weil sich
      dann die Fäule von den Kartoffeln auf die Tomaten überträgt. So musste ich dann tatsächlich jeden Tag die faulen Früchte und Blätter entfernen,
      damit sie sich nicht verbreitet.
      <br>Auch Erdbeeren habe ich nicht übermäßig viele geerntet, weil ich die Pflanzen ja auch erst in diesem Jahr gesetzt hatte.
      Alles in allem war es also ein sehr erfolgreiches Jahr!
    </p>
    <img src="@/assets/images/garten/IMG_20210521_141144.jpg" style="width: 420px;"/>
    <img src="@/assets/images/garten/IMG-20210604-WA0003.jpeg" style="width: 420px; margin: 0 0px 0 10px"/>
    <img src="@/assets/images/garten/IMG_20210801_173858.jpg" style="width: 420px;"/>
    <img src="@/assets/images/garten/IMG_20210625_153923.jpg" style="width: 420px; margin: 5px 0px 0 10px"/>
    <img src="@/assets/images/garten/IMG_20210723_122030.jpg" style="width: 420px;"/>
    <img src="@/assets/images/garten/IMG_20210812_121258.jpg" style="width: 420px; margin: 5px 0px 0 10px"/>
    <br><br>

   </div>
</template>
<style scoped>
p{
  font-size:21px;
}
</style>
