<template>
  <div style="position:relative; width:835px; margin: 0px 0px 0px 50px; font-size:19px; text-align:left">
    <p class="normaltext" style="font-size: 20px">
      <i>Here is my first interactive Russian text for language learners.<br>
        Hover over a word to find out its meaning or the case or tense it is in.</i><br>
      </p>
      <input type="checkbox" class="hackbox mehr_lesen" id="mehr_lesen_lesbarkeit"><label class="mehr_lesen" for="mehr_lesen_lesbarkeit">Show translation</label><br>

      <div class="volltext" style="width:400px; float:right; margin-right:15px; ">
        <p class="normaltext" style="line-height:210%; width:430px">
          <b>Alex's day</b><br>
          Yesterday in the morning Alex drove away very early on a car, because he wanted to build a small house.<br>
          When he came, he began to work.<br>
          He has much time right now, because he doesn't have to go to school.<br>
          But he can't work the whole day, because he also has to study for exams.<br>
          Today Alex ate a cake. Then he left the house with his dog. That's why she is happy. But Alex is very tired and wants to sleep.<br>
          Tomorrow Alex will teach Nikhita the Russian language. One day she'll learn to speak Russian. But if she won't learn words, she can forget it.
        </p>
      </div>

      <p style="line-height:210%; width:400px">
        <b>&#1057;&#1072;&#1096;&#1080;&#1085; &#1076;&#1077;&#1085;&#1100;</b><br>
        <a  class="tooltip">&#1042;&#1095;&#1077;&#1088;&#1072;&nbsp; <span class="tooltiptext">Yesterday</span></a>
        <a  class="tooltip">&#1091;&#1090;&#1088;&#1072;&#1084;<span class="tooltiptext">from &#1091;&#1090;&#1088;&#1086;</span></a>
        &#1057;&#1072;&#1096;&#1072; &#1086;&#1095;&#1077;&#1085;&#1100; &#1088;&#1072;&#1085;&#1086;
        <a  class="tooltip">&#1091;&#1077;&#1093;&#1072;&#1083;&nbsp;<span class="tooltiptext">&#1091; + &#1077;&#1093;&#1072;&#1090;&#1100; (to drive)</span></a>
        <a  class="tooltip">&#1085;&#1072;<span class="tooltiptext">on</span></a>
        <a  class="tooltip">&#1084;&#1072;&#1096;&#1080;&#1085;&#1077;<span class="tooltiptext">prep. of &#1084;&#1072;&#1096;&#1080;&#1085;&#1072; (car)</span>
        </a>, &#1087;&#1086;&#1090;&#1086;&#1084;&#1091;-&#1095;&#1090;&#1086; &#1086;&#1085; &#1093;&#1086;&#1095;&#1077;&#1090;
        <a  class="tooltip">&#1087;&#1086;&#1089;&#1090;&#1088;&#1086;&#1080;&#1090;&#1100;<span class="tooltiptext">perf. <i>to build</i> (car)</span></a>
        &#1084;&#1072;&#1083;&#1077;&#1085;&#1100;&#1082;&#1080;&#1081; &#1076;&#1086;&#1084;.<br>
        &#1050;&#1086;&#1075;&#1076;&#1072; &#1086;&#1085; &#1087;&#1088;&#1080;&#1096;&#1105;&#1083;, &#1086;&#1085;&nbsp;
        <a  class="tooltip">&#1085;&#1072;&#1095;&#1072;&#1083;<span class="tooltiptext">past tense of <i>to begin</i></span></a>
        &nbsp;&#1088;&#1072;&#1073;&#1086;&#1090;&#1072;&#1090;&#1100;.<br>
        &#1059; &#1085;&#1077;&#1075;&#1086; &#1089;&#1077;&#1081;&#1095;&#1072;&#1089; &#1077;&#1089;&#1090;&#1100; &#1084;&#1085;&#1086;&#1075;&#1086;
        <a  class="tooltip">&#1074;&#1088;&#1077;&#1084;&#1077;&#1085;&#1080;<span class="tooltiptext">gen. of &#1074;&#1088;&#1077;&#1084;&#1103;</span>
        </a>, &#1087;&#1086;&#1090;&#1086;&#1084;&#1091;-&#1095;&#1090;&#1086; &#1077;&#1084;&#1091; &#1085;&#1077; &#1085;&#1072;&#1076;&#1086; &#1093;&#1086;&#1076;&#1080;&#1090;&#1100; &#1074; &#1096;&#1082;&#1086;&#1083;&#1091;.<br>
        &#1053;&#1086; &#1086;&#1085; &#1085;&#1077; &#1084;&#1086;&#1078;&#1077;&#1090; &#1088;&#1072;&#1073;&#1086;&#1090;&#1072;&#1090;&#1100;
        <a  class="tooltip">&nbsp;&#1074;&#1077;&#1089;&#1100;&nbsp;<span class="tooltiptext">(the) whole</span></a>
        <a  class="tooltip">&#1076;&#1077;&#1085;&#1100;<span class="tooltiptext">day</span>
        </a>, &#1087;&#1086;&#1090;&#1086;&#1084;&#1091;-&#1095;&#1090;&#1086; &#1077;&#1084;&#1091; &#1090;&#1086;&#1078;&#1077; &#1085;&#1091;&#1078;&#1085;&#1086; &#1091;&#1095;&#1080;&#1090;&#1100;&#1089;&#1103; &#1076;&#1083;&#1103; &#1101;&#1082;&#1079;&#1072;&#1084;&#1077;&#1085;&#1099;.<br />
        &#1057;&#1077;&#1075;&#1086;&#1076;&#1085;&#1103; &#1057;&#1072;&#1096;&#1072;
        <a  class="tooltip">&#1089;&#1098;&#1077;&#1083;<span class="tooltiptext">perf. of &#1077;&#1089;&#1090;&#1100; (to eat)</span></a>
        &#1087;&#1080;&#1088;&#1086;&#1075;. &#1055;&#1086;&#1090;&#1086;&#1084; &#1086;&#1085; &#1091;&#1096;&#1105;&#1083; &#1089;&#1086;
        <a  class="tooltip">&#1089;&#1074;&#1086;&#1077;&#1081; &#1089;&#1086;&#1073;&#1072;&#1082;&#1086;&#1081;&nbsp;<span class="tooltiptext">instr. case</span></a>
        <a  class="tooltip">&#1080;&#1079;&nbsp;<span class="tooltiptext">out of</span></a>
        <a  class="tooltip">&#1076;&#1086;&#1084;a<span class="tooltiptext">gen. of &#1076;&#1086;&#1084;</span></a>.
        &#1055;&#1086;&#1101;&#1090;&#1086;&#1084;&#1091; &#1086;&#1085;&#1072;
        <a  class="tooltip">&#1089;&#1095;&#1072;&#1089;&#1090;&#1083;&#1080;&#1074;&#1072;&#1103;.<span class="tooltiptext">happy (fem.)</span></a>
        &#1053;&#1086; &#1057;&#1072;&#1096;&#1072; &#1086;&#1095;&#1077;&#1085;&#1100;
        <a  class="tooltip">&#1091;&#1089;&#1090;&#1072;&#1083;&#1099;&#1081;<span class="tooltiptext">tired (masc.)</span></a>
        &#1080; &#1093;&#1086;&#1095;&#1077;&#1090; &#1089;&#1087;&#1072;&#1090;&#1100;.<br />
        &#1047;&#1072;&#1074;&#1090;&#1088;&#1072; &#1057;&#1072;&#1096;&#1072; &#1073;&#1091;&#1076;&#1077;&#1090; &#1091;&#1095;&#1080;&#1090;&#1100; &#1053;&#1080;&#1082;&#1080;&#1090;&#1091; &#1088;&#1091;&#1089;&#1089;&#1082;&#1080;&#1081; &#1103;&#1079;&#1099;&#1082;. &#1054;&#1076;&#1080;&#1085; &#1076;&#1077;&#1085;&#1100; &#1086;&#1085;&#1072; &#1085;&#1072;&#1091;&#1095;&#1080;&#1090;&#1089;&#1103; &#1088;&#1072;&#1079;&#1075;&#1086;&#1074;&#1072;&#1088;&#1080;&#1074;&#1072;&#1090;&#1100; &#1087;&#1086; &#1088;&#1091;&#1089;&#1089;&#1082;&#1080;&#1081;. &#1053;&#1086; &#1077;&#1089;&#1083;&#1080; &#1086;&#1085;&#1072; &#1085;&#1077; &#1073;&#1091;&#1076;&#1077;&#1090; &#1091;&#1095;&#1080;&#1090;&#1100;
        <a  class="tooltip">&#1089;&#1083;&#1086;&#1074;&#1072;,<span class="tooltiptext">pl. of &#1089;&#1083;&#1086;&#1074;o</span></a>
        &#1086;&#1085;&#1072; &#1084;&#1086;&#1078;&#1077;&#1090; &#1077;&#1075;&#1086;
        <a  class="tooltip">&#1079;&#1072;&#1073;&#1099;&#1090;&#1100;.<span class="tooltiptext">perf. of <i>to forget</i></span></a>
      </p>
    </div>
  </template>

 <style scoped>

a{color: black}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  left:0px;
  color: black;
  background-color: white;
  text-align: left;
  min-width: 90%;
  width:max-content;
  padding: 5px;
  padding-bottom: 3px;
  border: 1px solid black;
  font-size: 10px;
  line-height: 100%;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 28px;
  right: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* default-Zustand */
.volltext {
  display:none;
}

/* bei gesetztem Haken */
input.mehr_lesen:checked ~ .volltext {
  display:inline;
}

 </style>
