<template>
<div id="page">
<div class="lightBlueBackground">

<!--Local Buttons!-->
<SubMenu/>

<!--White Background!-->
<div id="whiteBackground" :style="`width: ${getWidth}`">

<div v-if="this.$route.name == 'gendern'" style="margin: 12px 12px 32px 12px; padding-top: 10px; width: 906px; height: 400px;">
  <img src="@/assets/images/innen.jpg" style="width: 906px"/>
  <p id="bildquelle" style="font-family:Arial; color:grey; margin-top:0px;">Foto: Imago/Steinach</p>
</div>

<div v-if="this.$route.name.match(/russian/g)" style="margin: 12px 12px 0px 12px; padding-top: 10px; width: 906px; height: 400px;">
  <img src="@/assets/images/moscow.jpg" style="width: 906px"/>
</div>

<GardenSlideshow v-if="this.$route.name.match(/beet/g)" style="z-index: -1"/>
<Slideshow v-else-if="!this.$route.path.match(/gesellschaft/) && !this.$route.path.match(/philosophie/) && !this.$route.name.match(/russian/g) && this.$route.name != 'menschundwelt'" style="z-index: -1"/>
<Header/>

<router-view></router-view>

<footer v-if="this.$route.path.match(/gesellschaft/) || this.$route.path.match(/philosophie/) || this.$route.name == 'menschundwelt'"><img src="./assets/images/footerBlue.jpg" alt="" title="" :style="`width: ${getWidth}`" style="height: 60px; margin-bottom:-5px"/></footer>
<footer v-else><img src="./assets/images/footer.jpg" alt="" title="" :style="`width: ${getWidth}`" style="height: 60px; margin-bottom:-5px"/></footer>

</div>
</div>
</div>
</template>

<script>
import Header from './components/Header'
import SubMenu from './components/SubMenu'
import SideMenu from './components/SideMenu'
import Slideshow from './components/Slideshow.vue'
import GardenSlideshow from './components/GardenSlideshow.vue'

export default {
  name: 'App',
  components: { /*to register my components*/
    Header,
    SubMenu,
    SideMenu,
    Slideshow,
    GardenSlideshow
  },
  computed: {
    getWidth(){
      if(this.$route.name == 'Buecher'){
        return "1200px";
      } else {
        return "930px";
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
}
body {background-color:#99ccff;font-family:Arial,sans-serif;color:#000000;}

#page{
  width: 1300px;
  margin: 0 auto;
}

.lightBlueBackground{
  background-color: #cce6ff;
  width: 1325px;
  margin-top: -10px
}

#whiteBackground{
  position: relative;
  margin-top:10px;
  margin-left: 198px; 
  box-shadow: 5px 5px 6px rgba(0,0,0,0.25); 
  background-color: #FFFFFF; 
  z-index:0
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #page{
    width: 930px;
  }

  .lightBlueBackground{
    width: 930px;
  }

  #whiteBackground{
    margin-left: -5px;
  }

  #bildquelle{
    margin-bottom: 10px;
    font-size: 15px;
  }
}
</style>
