<template>
  <button @click="openNav" class="mobileMenu" :style="`background-color: ${getHeaderColor}`">&#x2630;</button>
  <div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
  <a @click="closeNav"><p>{{ subMenuContent[0] }}</p></a>
  <hr>
  <a @click="closeNav" v-for="elem in subMenuContent[1]" :key="elem"><router-link :to="elem[0]" style="color:white">{{elem[1]}}</router-link></a>
</div>
</template>

<script>
export default {
  name: 'SideMenu',

  data() {
      return {
        content: [],
      }
  },

  computed: {
    subMenuContent() {
      const routeName = this.$route.name;
      const routePath = this.$route.path;
      if(routeName == 'Home') {
        this.content = ["Home",
        [
          ["/projekte/bachboot", "Bachboot"],
          ["/buecher", "Bücher"],
          ["/learn-russian1", "Learn Russian"],
          ["/mensch-und-welt", "Mensch und Welt"],
          ["/projekte/tinyhouse", "Tinyhouse"]
        ]]
      }
      if(routePath.match("ueber-mich") || routeName == 'Buecher' || routeName == 'zamonien') {
        this.content = ["Über Mich",
        [
          ["/ueber-mich/steckbrief", "Steckbrief"],
          ["/buecher", "Bücher"],
        ]]
      }
      if(routePath.match("projekte")){
        this.content = ["Projekte",
        [
          ["/projekte/bachboot", "Bachboot"],
          ["/projekte/tinyhouse", "Tinyhouse"],
          ["/projekte/ukulele", "Ukulele"],
        ]]
      }
      //if(routeName.match(/beet/g) || routeName == 'loch' || routeName == 'hochsitz') {
      if(routePath.match("garten")){
        this.content = ["Garten",
        [
          ["/garten/beet2021", "Beet 2021"],
          ["/garten/beet2020", "Beet 2020"],
          ["/garten/beet2019", "Beet 2019"],
          ["/garten/beet2018", "Beet 2018"],
          ["/garten/beet2017", "Beet 2017"],
          ["/garten/beet2016", "Beet 2016"],
          ["/garten/beet2015", "Beet 2015"],
          ["/garten/loch", "Das Loch"],
          ["/garten/sonnenbeet", "Sonnenbeet"],
          ["/garten/hochsitz", "Hochsitz"]
        ]]
      }
      if(routePath.match("reisen")){
        this.content = ["Reisen",
        [
          ["/reisen/holland", "Holland Roadtrip"],
          ["/reisen/interrail", "Interrail-Trip"],
          ["/reisen/daenemark", "Dänemark"],
          ["/reisen/belgien", "Belgien"],
          ["/reisen/foehr", "Föhr"],
          ["/reisen/berlin-hamburg-nordsee", "Berlin - Hamburg - Nordsee"]
        ]]
      }
      if(routePath.match("russian")) {
        this.content = ["Russian",
        [
          ["/learn-russian1", "Сашин день"],
          ["/learn-russian2", "Москва"],
          ["/learn-russian3", "Маша и медведь"],
        ]]
      }
      if(routePath.match("impressum")) {
        this.content = ["Impressum",[]]
      }
      if(routePath.match("mensch-und-welt") || routePath.match("gesellschaft") || routePath.match("philosophie")) {
        this.content = ["Mensch und Welt",
        [
          ["/gesellschaft", "Gesellschaft"],
          ["/philosophie", "Philosophie"]
        ]]
      }
      return this.content
    },
    getHeaderColor(){
        if(this.$route.path.match(/gesellschaft/) || this.$route.path.match(/philosophie/)|| this.$route.path.match(/mensch-und-welt/)){
          return "#259BFA";
        } else {
          return "#84D82A";
        }
      }
  },

  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "50%";
    },
    /* Set the width of the side navigation to 0 */
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    }
  },
};
</script>

<style scoped>
  @import '../assets/styles/styles.css';

  /* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 40px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav p {
  font-size: 55px;
  color: #ffffff;
  padding-left:25px;
}

.mobileMenu{
  display: none;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 50px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media only screen and (max-width: 480px), only screen and (max-device-width: 480px){
  .mobileMenu {
    display: block;
    height: 100px;
    padding: 18px 25px 18px 21px;
    color: white;
    font-size: 30px;
    border: 1px solid white;
    border-radius: 5px;
  }
}

</style>
