<template>
 <div style="position:relative; width:885px; margin: 0px 0px 0px 15px; font-size:20px; text-align:justify">
   <p style="font-weight:bold; font-size:24px; margin-left:20px">
     Das Beet 2015
   </p>
   <p style="text-align:justify; font-size:21px; width:855px; margin-left:20px">
    Vor  mehr als  sechs  Jahren  kam  ich  auf  die  Idee,  mir  einen  kleinen Nutzgarten anzulegen, der sich seitdem stetig ausweitet. Im  Fr&uumlhjar  2015  bestand  das  Beet  nur  eng  um  <a href="loch1.htm" style="color:blue">das Loch</a> herum und  auf  dem  H&uumlgel  nebendran.  Der  Sinn,  ein  Beet auf  einem  H&uumlgel  anzulegen,  war  meiner  Meinung  nach  der,
    dass  man  mehr  Fl&aumlche  hat.  Leider  musste  ich  mit  den  Jahren merken,   dass   die   Humusschicht   zu   d&uumlnn   war,   die   Erde herunterrutschte,  die  Pflanzen  keinen  Halt  fanden  und  der Standort  generell  zu  Schattig  war.  So  hatte  ich  in  jenem  Jahr zwar   sehr   viele   verschiedene   Gew&aumlchse   eingepflanzt   und ausges&aumlht,
    jedoch nur mager geerntet. Aber immerhin!
   </p>

 <img src="@/assets/images/garten/143.jpg" style="float:right; width: 654px; margin:5px"/>
 <p style="float:left;text-align:right; font-size:18px; width:200px">
  Ansicht des Beetes im Sommer 2015. Links der Berg mit Blumenkohl, Karotten, Salat, Petersilie, Tomaten und Kohlrabi. Etwas tiefer am Hang Physalis. Rechts Mangold, Mais und Erbsen.
 </p>
 <img class="xr_ap" src="@/assets/images/garten/140.jpg" style="float:right; width: 322px; height: 537px; margin:5px"/>
 <img class="xr_ap" src="@/assets/images/garten/141.jpg" style="float:right; width: 322px; height: 537px; margin:5px"/>
 <div style="float: left; margin-top:5px; margin-left:10px">
   <img class="xr_ap" src="@/assets/images/garten/144.jpg" alt="" title="" style="width: 190px;"/>
   <p style="font-size:18px">Magere Ernte 2015</p>
 </div>
 <img class="xr_ap" src="@/assets/images/garten/139.jpg" style="float:right; width: 654px; margin:5px"/>
 <img class="xr_ap" src="@/assets/images/garten/138.jpg" style="float:right; width: 322px; height: 537px; margin:5px"/>
 <img class="xr_ap" src="@/assets/images/garten/142.jpg" style="float:right; width: 322px; height: 537px; margin:5px"/>
 <p style="float:left;text-align:right; font-size:18px; width:200px">
  Die K&uuml;rbispflanze ist an irgendeinem Pilz gestorben. Den Blumenkohl h&auml;tte ich zu diesem Zeitpunkt (Dezember) schon ernten sollen, der ist dann n&auml;mlich einfach braun geworden und erfrohren.
</p>
</div>
<router-link to="/garten/beet2016">
 <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 25px 25px 25px 400px; width: 150px;"/>
</router-link>
</template>
