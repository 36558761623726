<template>
  <div class="Collapsible">
    <button @click="handleClick" :class="{Collapsible__trigger: true}">
      <slot name="trigger" v-if="open">
        <span class="Collapsible__triggerLabel">
          {{label}}
          <img src="@/assets/images/close.png"/>
        </span>
      </slot>

      <slot name="closedTrigger" v-if="!open">
        <slot name="trigger">
          <span class="Collapsible__triggerLabel">
            {{label}}
            <img src="@/assets/images/drop.png"/>
          </span>
        </slot>
      </slot>
    </button>

    <div :class="{Collapsible__content: true}" :style="{height}">
      <div class="Collapsible__contentInner" ref="inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  data() {
    return {
      open: false,
      height: this.open ? 'auto' : '0px',
      closeOnNextTick: false,
      transitioning: false,
    };
  },
  props: {
    label: {
      type: String,
      default: 'Open me',
    },
  },
  methods: {
    handleClick() {
      if(this.open){
        this.height = '0px'
      } else{
        this.height = 'auto'
      }
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.Collapsible__content {
  overflow: hidden;
}

.Collapsible__contentInner {
  height: auto;
}

.Collapsible__trigger {
  appearance: none;
  border: 0;
  background: transparent;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  cursor: pointer;
}
</style>
