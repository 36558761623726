<template>
  <div style="position:relative; width:830px; margin: 35px 0px 0px 50px; font-size:19px; text-align:justify">
    <p style="font-size:21px;"><b>Zamonien</b></p>
    <p>
      Eine große Rolle spielen für mich die acht Zamonien-Romane von Walter Moers. Diese spielen alle auf dem
      erfundenen Kontinent Zamonien, der voller wundersamer Daseinsformen und Orten steckt.<br>
      Was mich an diesen Romanen reizt, ist ebendiese detailvolle, phantasievolle Fülle an sonderbaren Dingen.<br>
      Für mich lassen sich die Bücher schwer einem Genre zuordnen. Sicherlich ist es am ehesten Fantasy, es tritt
      jedoch an keiner Stelle Magie auf. Vielmehr werden die wundersamen Phänomene alle auf eine amüsante, kreative,
      pseudo-wissenschaftliche Weise erklärt.<br>
      Die Erzählweise ist angenehm und beschreibend, wodurch man voll in die Welt hineingezogen wird. Sie hat für
      mich stellenweise auch etwas märchenhaftes.<br>
      Die zahlreichen Illustrationen sind alle von Walter Moers selbst angefertigt und machen einen Teil des Flairs aus.<br>
      Die Bücher stehen alle für sich, es gibt jedoch zwischen ihnen Bezüge zueinander und es finden sich ab und zu
      bestimmte Charaktäre wieder.
    </p>
    <p style="font-size:21px; margin-top:30px"><b>Die Bücher</b></p>
    <p>
      <b>Die 13&#189; Leben des Käpt'n Blaubär</b><br>
      Es geht hier keinesfalls um den Käpt'n Blaubär aus der Sendung mit der Maus. Dieses Buch ist eindeutig an ein jugendliches oder
      erwachsenes Publikum gerichtet.<br>
      Es ist die Reise eines Blaubären durch den Kontinent Zamonien. In seinen verschiedenen Lebensabschnitten findet sich der Blaubär
      an den unterschiedlichsten Orten, mit den unterschiedlichsten Daseinsformen und in den unterschiedlichsten Positionen wieder.<br>
      Seine Reise beginnt im Meer, wo er auf Zwergpiraten, Klabautergeister, sprechende Wellen, einen riesigen Wal und eine kulinarische Insel trifft.
      Weiter geht seine Reise mit dem Rettungssaurier Deus X. Maquina und er erhält seine Bildung bei Prof. Dr. Abdul Nachtigaller in den Finsterbergen -
      einem Eydeten mit 7 Gehirnen.<br>
      Schließlich gelangt er in den großen Wald und muss vor einer Riesen-Spinne fliehen, zieht für einige Zeit mit den Gimpeln durch die süße Wüste und
      fängt dabei eine Stadt ein und gelangt über den Kopf eines Riesen in die Stadt Atlantis.<br>
      Diese grobe und lückenhafte Zusammenfassung beschreibt jedoch in keinster Weise die Fülle und den Detailreichtum des gesamten Buches
      und verrät noch viel zu wenig. Ich finde auch, dass man in keinem nachfolgenden Buch mehr Zamonien auf eine so vielfältige Weise erlebt.<br><br>

      <b>Ensel und Krete</b><br>
      Dieses Buch wird als vom fiktiven, zamonischen Dichter Hildegunst von Mythenmetz verfasst ausgegeben und als Märchen bezeichnet.<br>
      Es handelt von zwei Kindern, die sich im großen Wald verirren.<br><br>

      <b>Rumo und die Wunder im Dunkeln</b><br>
      Ein ebenfalls grandioses Buch über das Leben des Wolpertingers Rumo. Es erzählt, wie er groß wird und schließlich nach Untenwelt hinabsteigt und
      dabei natürlich zahlreiche Abenteuer erlebt.<br><br>

      <b>Die Stadt der träumenden Bücher</b><br>
      Dies war mein erstes Zamonien-Buch und es ist ein wundervolles, welches ich nun schon so oft gelesen und gehört habe.<br>
      Es handelt vom jungen Dichter Hildegunst von Mythenmetz, der in die sagenumwogene Stadt Buchhaim reist.</p>
      <p style="text-align:center">
      <i>In tiefen, kalten, hohlen räumen<br>
        Wo Schatten sich mit Schatten paaren<br>
        Wo alte Bücher Träume träumen<br>
        Von Zeiten, als sie Bäume waren<br>
        Wo Kohle Diamant gebiert<br>
        Man weder Licht noch Gnade kennt<br>
        Dort ist's, wo jener Geist regiert<br>
        Den man den Schattenkönig nennt</i>
      </p>
      <p>
        Buchhaim ist die verwunschene Stadt, in der sich alles nur um Bücher dreht. Es gibt Antiquariate, Buchhandlungen und Bücher in den
        verschiedensten, nur erdenklichen Formen. Unterhalb von Buchhaim befindet sich jedoch ein riesiges Netz von Katakomben, in denen die
        schrecklichsten Kreaturen lauern. Doch gerade dort befinden sich die wertvollsten Bücher, die von den berüchtigten Bücherjägern zu Tage
        gefördert werden.<br>
        Nun kommt es, dass unser Held in gerade diese Katakomben hinabsteigen muss. Wie wird er sich wohl darin schlagen? Und erfährt er auch, was es
        mit dem gefürchteten Schattenkönig auf sich hat?<br>
        Dieses Buch ist eine Liebeserklärung an Bücher, Geschichten und die Literatur und sehr liebevoll geschrieben.<br>
        Während "Die 13&#189; Leben des Käpt'n Blaubär" das coolste von allen Büchern ist, so ist dieses hier das schönste.<br><br>

        <b>Der Schrecksenmeister</b><br>
        Dieses Buch ist inspiriert von der Novelle "Spiegel, das Kätzchen" von Gottfried Keller, ist jedoch auf Moers' Weise start ausgeweitet und
        "zamonifiziert". Es handelt von der kleinen Kratze Echo, die mit dem Schrecksenmeister von Sledwaya einen verhägnisvollen Deal eingeht:
        Echo darf einen Monat lang beim Schrecksenmeister wohnen und wird auf das Beste verpflegt. Danach muss er jedoch sterben, weil der Schrecksenmeister
        sein Fett auskochen will.<br>
        Wieder eine sehr gute, spannende, emotionale und kulinarische Geschichte.<br><br>

        <b>Das Labyrinth der träumenden Bücher</b><br>
        Dies ist die Fortsetzung von "Die Stadt der träumenden Bücher" und spielt zu einem viel späteren Zeitpunkt, als Hildegunst von Mythenmetz
        bereits alt und berühmt ist.<br>
        In dem Buch <i>passiert</i> nicht viel in dem Sinne, es ist mehr eine Erlebnistour durch die sich start gewandelte, moderne Stadt Buchhaim.<br>
        Das Buch endet mit einem Cliff-Hänger, als Mythenmetz sich aufs neue unten in den Katakomben wiederfindet.<br>
        Dies ist zum Teil ärgerlich, da man das ganze Buch in der Erwartung gelesen hat, dass die Handlung nun richtig in Gange kommt.
        Auf diese Weise fühlt sich das Buch wie eine lange Einleitung an, die nicht zum Eigentlichen kommt. Befreit man sich jedoch von dieser Haltung, ist das
        Buch dennoch lesenswert.<br>
        Der dritte Teil der Buchhaim-Serie soll "Das Schloss der träumenden Bücher" heißen. Dessen Erscheinung ist jedoch schon seit vielen Jahren bis auf
        unbestimmte Zeit in die Zukunft verschoben.<br><br>

        <b>Prinzessin Insomnia und der albtraumfarbene Nachtmahr</b><br>
        Dieses Buch ist ganz anders als die anderen Zamonien-Romane und handelt von der Prinzessin Dylia, welche an einer mysteriösen Krankheit leidet, die zu wochenlanger Schlaflosigkeit führt,
        und die eines Nachts dem Nachtmahr Havarius Opal begegnet, welcher sie auf eine Reise nach Amigdala, dem Angstzentrum in ihrem Gehirn mitnimmt.<br>
        Von Zamonien sieht man in diesem Roman leider wenig, es ist mehr eine Reise durch den Geist der Prinzessin.<br><br>

        <b>Der Bücherdrache</b><br>
        Das aktuell neuste Buch von Walter Moers ist nicht mehr so besonders wie seine ersten Werke. Man könnte
        meinen, ihn habe das Orm verlassen. Wer "Die Stadt der träumenden Bücher" gelesen hat, weiß, dass ich damit meine,
        dass ihn die göttliche Quelle literarischer Inspiration verlassen hat.<br>
        Es ist immer noch ganz nett zu lesen und kann als Abzweig der Buchheim-Geschichten gesehen werden,
        ist aber bei weitem nicht so großartig, wie die anderen Werke.
    </p>
    <p>
      Nun hoffe ich, dass ich vielleicht Interesse geweckt habe. Wie ihr merkt, bin ich sehr begeistert von den Büchern, aber es ist sehr schwer,
      ihren wahren Geist hier auf dieser Seite auszudrücken. Die reine Inhaltsangabe mag die Bücher eher oberflächlich und kindisch klingen lassen,
      das was sie aber so besonders macht ist eben das, was beim Lesen passiert. Das spielen mit der Sprache, die Erzählweise, die Fülle an Phantasie und die
      liebevollen Details.<br>
      Ich mag die Bücher so sehr!</p>
  </div>
</template>
