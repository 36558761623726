<template>
  <div id="Tabelle" style="position:absolute;">
    <table>
      <tr>
        <td class="td"><router-link to="/philosophie" style="color:white">Philosophie</router-link></td>
      </tr>
      <tr>
        <td class="td"><router-link to="/gesellschaft" style="color:white">Gesellschaft</router-link></td>
      </tr>
    </table>
   </div>
  <div style="position:relative; width:835px; margin: 30px 0px 0px 50px; font-size:19px; text-align:justify">
    <p><strong>Die Bootparabel</strong></p>
    <p>So kam es, dass zwei Menschen auf einem Boot auf Hoher See vom Kurs abgekommen sind. Mittel zur Navigation hatten sie keine.</p>
    <p>So trieben sie Ahnungslos durch die Weiten des Meeres. Des Nachts wurde es h&auml;ufig unangenehm k&uuml;hl.</p>
    <p>So begann der eine schlie&szlig;lich, die Holzplanken aus der Bootswand herauszurei&szlig;en und sie zu verbrennen.</p>
    <p>Entsetzt fragte ihn der andere, warum er das tue.</p>
    <p>&bdquo;Mir ist kalt!&ldquo;, entgegnete der eine.</p>
    <p>&bdquo;Begreifst du denn nicht, dass das Boot untergeht, wenn du so weiter machst? H&ouml;r sofort auf damit! Lass uns wieder auf den richtigen Kurs kommen!&ldquo;</p>
    <p>&bdquo;Daran glaube ich nicht. Lass <em>du</em> es meinetwegen sein, aber <em>ich</em> m&ouml;chte mich nicht erk&auml;lten!&ldquo;</p>
    <p>&bdquo;Sobald zu viel Wasser eindringt, werden wir es nicht mehr aufhalten k&ouml;nnen und <em>beide</em> untergehen!&ldquo;</p>
    <p>So wurde der andere w&uuml;tend: &bdquo;Was du da sagst ist bescheuert. Du bist dumm! Hirngespinste! Ich werde sonst noch krank! Siehst nicht? <em>Ich werde krank!</em>&ldquo;</p>
    <p>Dann fiel er in einen wohlig-warmen Schlaf und konnte von nichts mehr geweckt werden. Der andere musste aber kl&auml;glich miterleben, wie die Wassermassen schlie&szlig;lich das Boot verschlangen und er in den kalten Fluten ertrank.</p>
    <p>&nbsp;</p>

    <p><strong>Erl&auml;uterungen</strong></p>
    <p>In der gesamten Geschichte steht das Boot auf hoher See f&uuml;r die winzig kleine Erde, einsam im endlosen Universum, auf der die Menschheit &bdquo;vom Kurs abgekommen&ldquo; ist.<br/>
      Das ahnungslose Treiben im Ozean steht f&uuml;r die allgemeine Sinn- und Ziellosigkeit der modernen Zeit.
    </p>
    <p>Natürlich spiele ich mit dieser Geschichte auf die globale Klimakatastrophe an. Und die Situation ist so surreal, wie meine Geschichte erscheint.</p>
    <p>Das spontane Bed&uuml;rfnis nach W&auml;rme bewegt den einen Menschen zu der effektiven, f&uuml;r den &auml;u&szlig;eren Betrachter
      jedoch v&ouml;llig absurd scheinenden L&ouml;sung, die Bootsplanken zu verbrennen.<br>
      Der entsetzte Mitfahrer des Bootes m&ouml;chte den anderen wieder zur Vernunft bringen, h&auml;lt ihm die Konsequenzen seines Handelns vor Augen und
      schl&auml;gt die Alternative vor, doch lieber wieder &bdquo;auf den richtigen Kurs&ldquo; zu kommen (und so zum Land zur&uuml;ckzukehren).<br>
      Dies stellt für sein Gegenüber jedoch eine kognitive &Uuml;berforderung dar, und so w&auml;hlt er den so einfachen und so beliebten Weg,
      sich Probleme aus der Welt zu schaffen, in dem er einfach nicht an sie glaubt.<br>
      Schlie&szlig;lich erl&auml;utert der verzweifelte Mensch die Lage noch einmal so einfach und offensichtlich, wie es &uuml;berhaupt nur m&ouml;glich ist.<br>
      Daraufhin reagiert der Sture jedoch schlie&szlig;lich mit Wut und wilden Beleidigungen und beharrt auf seine hinsichtlich der akuten Lage belanglosen Besorgnisse, sich zu erk&auml;lten.</p>

    <p>Die Geschichte erscheint sehr surreal, da man meint, es würde in Wahrheit niemand so beschränkt sein und sich so irrational verhalten.
       Doch traurigerweise ist es genau das, was in Hinblick auf die globale Klimakatastrophe passiert.<br>
       Die Menschen sind blind gegenüber der Katastrophe und lassen sich nicht von rationalen Argumenten übrzeugen, die ihrer Bequemlichkeit und ihrem Luxus im Weg stehen.
       Viel schlimmer noch: traurigerweise gibt es eine erschreckend gro&szlig;e Menge von Menschen, die auf solche Weise den verzweifelten Bem&uuml;hungen von Klimasch&uuml;tzern entgegenkommen.
       Auf Menschen, die bem&uuml;ht sind, aufs &auml;u&szlig;erliche enthaltsam zu leben und sich somit f&uuml;r das Wohl <strong>aller</strong> Lebewesen auf der Erde aufopfern, wird mit fiesen Beleidigungen und Anschuldigungen von Dummheit reagiert.<br/>
       Dies ist zutiefst heuchlerisch und zählt für mich zu den ethisch verwerflichsten Dingen überhaupt.</p>

    <p>Wie der Mensch, der die Bootsplanken verbrennt, um sein augenblickliches Bedürfnis zu stillen, so fällt es auch den realen Menschen sehr schwer,
      größeres zuküngtiges Leid mit kleinerem, gegenwärtigen Leid in Verhälnis zu setzen.<br>
      Häufig wird die Wirtschaft als Argument gegen Klimaschutzmaßnahmen hinzugezogen.<br>
      Es ist offensichtlich und nicht zu umgehen, dass die Wirtschaft und der allgemeine Komfort der westlichen Welt unter angemessenen Klimaschutzma&szlig;nahmen leiden werden.
      Nur ist es ebenso offensichtlich, dass ein Wirtschaftssystem, das auf Ausbeutung und ewiges Wachstum ausgelegt ist, mit begrenzten Ressourcen ganz simpel nicht ewig funktionieren kann.
      Au&szlig;erdem scheint niemand zu begreifen, dass solche Einb&uuml;&szlig;en letztendlich das kleinere &Uuml;bel als eine Globale Abw&auml;rtsspirale sind, die in Umweltkatastrophen, Missernten, Hungersn&ouml;ten, D&uuml;rren, Wassermangel, Massenmigration, neuen Epidemien, Artenaussterben, dem Kollaps von &Ouml;kosystemen, ...  m&uuml;ndet.<br/>
    </p>
    <p>Offensichtlich k&ouml;nnen das jedoch die wenigsten Begreifen, denn so funktionieren nun mal Menschen.
      Und aus diesem Grund verbrennt der Mensch auf dem Boot auch weiter die Planken und f&auml;llt in seinen wohligen,
      vielleicht ewigen Schlaf, w&auml;hrend derjenige, der sich am meisten f&uuml;r den Erhalt des Bootes eingesetzt hat, kl&auml;glich untergeht.</p>

    <p><b>Wir alle sitzen auf demselben Boot. Und dieses Boot sinkt!</b></p>
    <p>Und es kann nicht sein, dass der eine Teil der Menschen alles dafür tut, dass die Erde für <b>alle</b> bewohnbar bleibt, während der andere Teil sie ignorant
      ungestraft weiter zerstört und dabei noch die Gegenseite verhöhnt.</p>
    <p>Die Geschichte soll durch dieses simple Bildnis darlegen, wie kafkaesk das gesamte Weltgeschehen tats&auml;chlich ist.</p>
    <p style="border: solid 1px black; padding:10px">
      Auch mir ist bewusst, dass wir alle - auch ich - zur Zerstörung beitragen. Außerdem grenzt es an Unmöglichkeit, tatsächlich den Lebensstil der ganzen
      Weltbevölkerung derart zu verändern, wie es nötig wäre.<br>
      Ich möchte mit meiner Geschichte nicht bestimmten Leuten die Schuld in die Schuhe schieben, sondern die Surrealität der Situation darstellen.<br>
      Motiviert, diese Geschichte zu verfassen, wurde ich im Jahr 2019, als ich mitbekam, wie und auf welche Weise sich meine älteren Verwandten über
      Gretha Thunberg und grüne Politik beschwerten und ich meinen Ohren nicht trauen konnte, wie Menschen, die ich für so vernünftig gehalten hatte,
      solche Dinge von sich geben konnten.
    </p>
    <br>
  </div>
</template>
<style scoped>
#Tabelle {
float:left;
width:140px;
padding: 10px;
color:white;
margin: -10px 0 0px -180px;
font-size:20px;
 }
.td {
color: white;
background: #193f7d;
padding: 5px 10px 5px 10px;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #Tabelle {
    display: none;
  }
}
</style>
