<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:21px; text-align:justify">
    <p style="font-size:21px;"><b>Das Loch</b></p>
     <p style="text-align:justify;">
        <img src="@/assets/images/garten/255.jpg" alt="" title="" style="float:right; width: 220px; margin: -30px 0 0 15px;"/>
        Ich   wei&szlig;   nicht   mehr   genau   wieso,   aber   als   ich   zw&ouml;lf   Jahre   alt war,   begann   ich,   in   meinem   Sandkasten   ein   Loch   zu   graben. Ich   wollte   wohl   einfach   mal   wissen,   was   so   unter   meinen F&uuml;&szlig;en liegt, also begann ich. Ich    kam    immer    tiefer    und    stie&szlig; auf   immer   neuere   Erdschichten. Neben   mir   wurde   der   Erdhaufen immer h&ouml;her. Ab   einer   Gewissen   Tiefe   war   der herunterrieselnde        Sand        ein Problem,    also    erweiterte    ich    es bis    zum    Erdhorizont    hin    in    die Breite. Meine    Russische    Cousine    kam zu Besuch und half mir dabei.
     </p>
     <img src="@/assets/images/garten/241.jpg" alt="" title="" style="width: 700px;"/>
     <p>
        Alles   &auml;nderte   sich,   als   ich   in   einer   Tiefe   von   etwa   1,80m   auf Lehm   stie&szlig;.   Ich   arbeite   gerne   mit   Ton   und   so   begann   ich  mit Freude,   den   neuen   Schatz   auszuheben.   Insgesammt   habe ich    etwa    einen    halben    Kubikmeter    ausgehoben    und    auch einen   Teil   der   Kermik-AG   gespendet.   Ich   wollte   immer   einen Ofen   daraus   bauen,   das   ist   mir   leider   bisher   aber   noch   nicht gelungen. Zum jetzigen Zeitpunkt existiert der Lehm jedoch nicht mehr.
     </p>
     <img src="@/assets/images/garten/Loch1.jpg" alt="" title="" style="width: 339px; margin-right:5px;"/>
     <img src="@/assets/images/garten/Loch2.jpg" alt="" title="" style="width: 341px; margin-left:5px;"/>
     <p style="float:left; width: 350px; margin-right:5px;">Tiefe des Lochs - 2,50 m</p>
     <p style="float:left; width: 340px; margin-right:5px;">Pflanzen am Loch</p>
     <p style="font-size:21px"><br><br><b>Geologie des Lochs</b></p>
     <p>
       <img src="@/assets/images/garten/244.jpg" alt="" title="" style="float:right; width: 300px; margin: 5px 0 0 15px;"/>
        Vor etwa 30 mio. Jahren, im Oligoz&auml;n, begann sich das Gebiet, in dem ich das Loch gegraben habe,  die Hanau-Seligenstädter-Senke,   abzusenken   und   mit   Wasser zu f&uuml;llen.    Es    entstand    ein    See, welcher   sich   im   Mioz&auml;n   (vor 23   mio. - 5  mio. Jahren) wieder mit Sedimenten auff&uuml;llte.   Daher   kommt der   Lehm   und   der   Kies   und   deshalb ist   es   hier   auch   so   flach.   Außerdem befindet   sich  aus diesem Grund das   Grundgestein   (der C-Horizont)    auch    um    die    hundert Meter    unter    unseren    F&uuml;&szlig;en.    Ich h&auml;tte      also      nie      bis      zu      ihm vordringen k&ouml;nnen. <br>
        Der    Flugsand    breitete sich    w&auml;hrend    der    letzten    Kaltzeit, der   Weichsel-Kaltzeit   im   Pleistoz&auml;n, vor   etwa   115.000   -   10.000   Jahren aus.   Damals   lag   unsere   Region   nicht   weit   von   der   Eisdecke des Nordens  entfernt.   Die Landschaft    war    tundraartig    und    die    Vegetationsdecke    war    d&uuml;nn,    deshalb konnte   der   Sand   frei   vom   Wind   getragen   werden   und   es   entstanden   sogar D&uuml;nen.   Ich   habe   hier   zwei   Karten   der   Sedimente   und   Gesteine   Langstadts, bzw. Deutschlands verlinkt: <br>
      </p>
      <a href="https://geoviewer.bgr.de/mapapps4/resources/apps/geoviewer/index.html?lang=de" style="float:left; width: 340px; margin-right:5px;">Geoviewer</a>
      <a href="http://www.langstadt-aktuell.de/nabu/natur/geologie.htm" style="float:right; width: 340px; margin-right:5px;">NABU Langstadt</a>

      <p style="font-size:21px"><br><br><b>Biologie des Lochs</b></p>
      <p>
        <div style="float: right; width: 394px; margin: 5px 0 0 15px;">
        <img src="@/assets/images/garten/spinne.jpg" alt="" title="" style="width: 193px; height: 223px;"/>
        <img src="@/assets/images/garten/260.jpg" alt="" title="" style="width: 195px; height: 223px;"/>
        <img src="@/assets/images/garten/257.jpg" alt="" title="" style="width: 223px; height: 232px;"/>
        <img src="@/assets/images/garten/frosch.jpg" alt="" title="" style="width: 165px; height: 232px;"/>
        </div>
        &Uuml;ber      die      Jahre      haben      sich      einige Lebewesen    in    meinem    Loch    ein-,    aber auch      wieder      ausgesiedelt.      In      zwei Sommern   haben   sich   Erdhummeln   bei   mir eingenistet    und    ihre    G&auml;nge    gegraben. Das    war    etwas    ganz    Besonderes,    denn Erdhummeln        finden        aufgrund        der intensiven   Landwirtschaft   kaum   noch   einen guten    Ort    zum    Nisten. <br>
        Es    waren    auch andere        Tiere,        wie        Regenw&uuml;rmer, Nacktschnecken,        Ameisen,        Spinnen, Schwarzk&auml;fer,   M&uuml;cken   und   sicher   noch   ein paar     mehr     vorzufinden.  <br>
        Nach     langen Regenzeiten   sammelte   sich   bei   mir   im   Loch immer      eine      betr&auml;chtliche      Menge      an Wasser.    So    kam    es    auch    schon    nicht wenige   Male   vor,   dass   sich   ein   Frosch   bei mir    verirrte    und    nicht    mehr    herauskam. Und einmal fand ich sogar einen Lurch! <br>
        Moose   machten   es   sich   bei   mir   auch   bequem,   aber   auch   Gr&auml;ser   und   andere kleine   Pflanzen.   Sogar   ein   Buschwindr&ouml;sschen   hangelte   sich   einmal   den   Hang herunter.    Auch    St&auml;nder-    und    Schlauchpilze,    wie    den    D&uuml;ngerling    und    die Spitzmorchel,    nutzten    bereits    die    einmaligen,    k&uuml;hlen,    dunklen    und    feuchten      Bedingungen meines Lochs. Und hier das ultimative Lochvideo!
     </p>
     <div><video src="@/assets/images/garten/Loch.mp4" controls style="width: 700px;"/></div>
     <p style="font-size:21px;"><br><b>Das Ende des Lochs</b></p>
     <p style="text-align:justify">
         <img src="@/assets/images/garten/Loch4.jpg" alt="" title="" style="float:right; width: 190px; margin: -45px 0 0 15px;"/>
       Im Jahr 2018 war es dann so weit - ich habe mich von dem legendären Loch getrennt. Eine Palette mit Loch soll einen kleinen Hohlraum &uuml;brig lassen,
       in dem sich auch weiter Wasser sammeln kann. Darauf steckte ich ein 2m-langes Rohr, welches heute noch geb&uuml;hrend an das Loch erinnert. Vielleicht errichte ich an dieser
       Stelle mal einen Brunnen.
    </p>
    <img src="@/assets/images/garten/Loch6.jpg" alt="" title="Lochdenkmal" style="width: 700px; margin-bottom: 10px"/>
   </div>
</template>

<style scoped>
p {
  font-size:21px;
}
</style>
