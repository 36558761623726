<template>
    <div style="position:relative; width:825px; margin: 40px 0px 40px 50px; ">
      <table>
        <tr>
          <td><b>Name:</b></td>
          <td>Alexander Zieschang</td>
        </tr>
        <tr>
          <td><b>Spitzname:</b></td>
          <td>Alex <span style="color:grey">(he/him)</span></td>
        </tr>
        <tr>
          <td><b>Alter:</b></td>
          <td>{{ getAge() }}</td>
        </tr>
        <tr>
          <td><b>Stadt:</b></td>
          <td>Darmstadt</td>
        </tr>
        <tr>
          <td><b>Studium:</b></td>
          <td>Computational Enginnering, TU Darmstadt</td>
        </tr>
        <tr>&nbsp;</tr>
        <tr>
          <td><b>Hobbys:</b></td>
          <td>Dinge konstruieren, Programmieren, Sachen unternehmen, draußen sein, Fahrradfahren, Musik, Singen, Tanzen, Sprachen lernen</td>
        </tr>
        <tr>
          <td><b>Interessen:</b></td>
          <td>Engineering, Informatik, Sprachen, Geographie, Weltraum, Philosophie, Musik</td>
        </tr>
        <tr>
          <td><b>Sprachen:</b></td>
          <td>Deutsch, Englisch, Spanisch, Russisch, Niederländisch, (Französisch)</td>
        </tr>
        <tr>
          <td><b>Werte:</b></td>
          <td>Freundschaft, Umweltschutz</td>
        </tr>
        <tr>
          <td><b>Das mag ich gar nicht:</b></td>
          <td>Konservative, wütende Menschen, Autos, Fußball</td>
        </tr>
        <tr>
          <td><b>Lieblingsmusik:</b></td>
          <td>Latino, Pop, Klassiker</td>
        </tr>
        <tr>
          <td><b>Künstler:</b></td>
          <td>MORAT, Shakira, Coldplay, Bastille, Ed Sheeran, Taylor Swift, Keane, viele mehr</td>
        </tr>
        <tr>
          <td><b>Lieblings-YouTube-Kanäle:</b></td>
          <td>CdeCiencia, Tom Scott, Coldmirror, Numberphile, Ibx2Cat, El Robot de Platón, Kurzgesagt</td>
        </tr>
      </table>
    </div>
  </template>

<style scoped>
  td{
    font-size:19px;
    text-align:left;
    vertical-align: top;
    padding-bottom: 4px;
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    td{
      font-size: 35px;
    }
  }
</style>

<script>
export default{
  name: 'steckbrief',
  components: {},

  methods: {
    getAge(){
      var now = new Date()
      var currentMillis = now.getTime()
      var birthday = new Date()
      birthday.setFullYear(2001)
      birthday.setMonth(9)
      birthday.setDate(30)
      birthday.setHours(0)
      birthday.setMinutes(0)
      birthday.setSeconds(0)
      var age = now - birthday;
      return Math.floor(age/(1000*3600*24*365.25))
    }
  }
}
</script>

  