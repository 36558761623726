<template>
  <header>
    <div style="margin: -5px 0 0 0; display:flex;">
    <SideMenu/>
    <router-link to="/"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`">Home</button></router-link>
    <router-link to="/ueber-mich"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`">Über mich</button></router-link>
    <router-link to="/projekte"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`" style="padding: 18px 22px 18px 21px;">Projekte</button></router-link>
    <router-link to="/garten/beet2021"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`" style="padding: 18px 23px 18px 23px;">Garten</button></router-link>
    <router-link to="/reisen"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`">Reisen</button></router-link>
    <router-link to="/impressum"><button @click="openNav" class="MenuButton" :style="`background-color: ${getHeaderColor}`">Impressum</button></router-link>
    </div>
  </header>
</template>

<script >
  import SideMenu from './SideMenu.vue'

  export default{
    name: 'Header',
    components: {
      SideMenu
    },
    computed: {
      getHeaderColor(){
        if(this.$route.path.match(/gesellschaft/) || this.$route.path.match(/philosophie/) || this.$route.path.match(/mensch-und-welt/)){
          return "#259BFA";
        } else {
          return "#84D82A";
        }
      }
    },

    methods: {
      openNav() {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
          document.getElementById("mySidenav").style.width = "50%";
        }
      },
      /* Set the width of the side navigation to 0 */
      closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }
    },
  }
</script>

<style scoped> /*scoped means it's only for this component*/
  @import '../assets/styles/styles.css';
  header{
    width:930px;
  }
  
</style>
