<template>
  <div style="margin: 30px 30px 30px 30px; min-height:300px">
    <p style="font-weight:bold;">
        Meine Reisen
      </p>
      <p style="text-align:justify">
        Hier habe ich vor, nach und nach von bedeutsamen Reisen zu berichten. Momentan gibt es 
        noch nicht so viele Einträge, aber es werden nach und nach mehr dazukommen.
      </p>
 </div>
</template>
