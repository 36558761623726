<template>
  <div style="position:relative; width:855px; margin: 25px 0px 0px 40px; text-align:justify">
    <h2>Das Sonnenbeet</h2>
    <p style="text-align:justify; line-height:1.4">
    Als   ich   im   Herbst   des   Jahres   2015   durch   die   Weinlandschaft Gro&szlig;-Umstadts   wanderte,   lernte   ich   eine   interessante   Sache: Der    Wein    wird    auf    H&auml;ngen    angebaut,    damit    die    Sonne m&ouml;glichst   im   rechten   Winkel   auf   den   Boden   strahlt   und   den Boden   optimal   erw&auml;rmt.
    </p>

    <div class="side-text">
      <p style="text-align:justify;">
       So   entschied   ich   mich,   soetwas   in   meinem Garten nachzubauen. Auf   den   Resten   meines   ehemaligen Baumhauses      befestigte      ich      10 Holzpf&auml;hle,         auf         denen         ich anschließend   Bretter   im   40°-Winkel befestigte   und   die   L&uuml;cken   mit   kleinen Querbrettern    verschloss.    Durch die   40°-Neigung addiert zu   der 40° mittleren Neigung      des   Bodens   zur   Sonne am    50-sten    Breitengrad,    trifft    die Sonne    in    einem    80°-Winkel  (&pm;23° Erdneigung)  auf mein    Beet    und    ist    somit    fast optimal konzentriert. Sobald   ich   eine   Ebene   geschaffen hatte,   baute   ich   einen   Rand,   schaffte Widerstand       durch       St&ouml;cke       und begann   sie   aufzuf&uuml;llen.   Ich   pflanzte auch    sofort    Gras    und    Gr&uuml;nd&uuml;nger an, um Halt zu schaffen. Im   Sommer   2018     habe ich   f&uuml;nf    Weinpflanzen gepflanzt,   von   denen   2   gekommen sind.
       Im Jahr 2019 pflanzte ich noch zwei weitere Pflanzen, um die L&uuml;cken aufzuf&uuml;llen. Im Herbst des Jahres hatte ich auch meine erste kleine Ernte: etwa zwei H&auml;nde voll blauer Weinbeeren, die durch die Kerne zwar sehr bitter schmeckten, aber deren Saft zuckers&uuml;&szlig; war.
       Zu meinem Geburtstag im Herbst habe ich von Tabea einen Abk&ouml;mmling ihrer Pflanze geschenkt bekommen, weil ich einmal meinte, ihre Weintrauben seien die leckersten, die ich je probiert h&auml;tte. Ich hatte sie nur f&uuml;r ein paar vertrocknete St&ouml;ckchen, die in Erde gesteckt waren, gehalten, aber tats&auml;chlich habe ich diesen Fr&uuml;hling (2020) Knospen an ihr entdeckt!
       Mal sehen, was dieses Jahr bringen wird...</p>
      <p>Seit 2021 existiert das Sonnenbeet nicht mehr.</p>
    </div>

    <div>
    <img src="@/assets/images/garten/358.jpg" alt="" title="" class="side-image"/>
    <img src="@/assets/images/garten/359.jpg" alt="" title="" class="side-image"/>
    <img src="@/assets/images/garten/360.jpg" alt="" title="" class="side-image"/>
    <img src="@/assets/images/garten/362.jpg" alt="" title="" class="side-image"/>
    <div>
    <img src="@/assets/images/garten/361.jpg" alt="" title="" class="narrow-side-image"/>
    <img src="@/assets/images/garten/363.jpg" alt="" title="" class="narrow-side-image"/>
    </div>
    <img src="@/assets/images/garten/364.jpg" alt="" title="" class="side-image"/>
    </div>
   </div>
</template>

<style scoped>
  .side-text{
    float:left; width:410px; line-height:1.4; margin: -25px 12px 85% 0px
  }

  .side-image{
    width: 430px;
  }

  .narrow-side-image{
    width: 215px;
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    .side-text{
      float:left; width:855px; line-height:1.4; margin: -25px 12px 0 0px
    }

    .side-image{
      width: 854px;
    }

    .narrow-side-image{
      width: 427px;
    }
  }
</style>
