<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:21px; text-align:justify">
    <p style="font-size:24px"><b>Das Beet 2019 - Spargel</b></p>
    <p style="font-size:21px;text-align:justify">
      Im Jahr 2018 war ich nicht so ambitioniert und habe mir recht einfache Gem&uuml;se zum Anbauen ausgesucht: Kartoffeln, Karotten, Salat - auf dem Gem&uuml;seberg habe ich
      Erdbeeren, Schnittlauch und Petersilie angebaut.<br>
      Ein abmitioniertes Projekt hatte ich jedoch: Ich habe in diesem Jahr den Spargel ausges&auml;ht - ein Projekt, dass sich noch &uuml;ber Jahre hingwegziehen wird.
    </p>
    <img src="@/assets/images/garten/Beet2018-3.jpg" alt="" title="" style="width: 700px;"/>
    <img src="@/assets/images/garten/Beet2018-1.jpg" alt="" title="" style="width: 700px;"/>
    <img src="@/assets/images/garten/Beet2018-2.jpg" alt="" title="" style="width: 700px;"/>
    <p>
      Aus den schmalen, flauschigen Pfl&auml;nzchen unten wird einmal Spargel!
    </p>
    <p>
      Ich habe mich auch daran gemacht, das Problem mit dem Schatten zu L&ouml;sen. Ein gro&szlig;er Teil meines Beetes liegt n&auml;mlich fast die gesamte Zeit des Tages
      im Schatten eines Haselnussstrauchs.<br>
      Ich habe also versucht, die Bereiche mit Spiegeln zu beleuchten. Ich vermute aber, dass das keinen gro&szlig;en Unterschied gemacht hat.
    </p>
    <img src="@/assets/images/garten/Spiegel.jpg" alt="" title="" style="width: 700px;"/><br>
    <router-link to="/garten/beet2019">
     <img src="@/assets/images/garten/weiter.png" alt="Weiter" style="float:right; margin: 25px 0 25px 0; width: 150px;"/>
   </router-link>
   </div>
</template>
