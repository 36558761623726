<template>
  <div class="reise-div">
  <h1>Dänemark</h1>
  <h2>Vorgeschichte</h2>
  <p>
    Da der <router-link to="/reisen/belgien">Belgienurlaub</router-link> so schön gewesen ist, beschlossen wir, auch im September 2022 wieder gemeinsam
    in den Urlaub zu fahren. Und so machten wir uns zu siebt, diesmal in einer etwas anderen Konstellation, auf nach Dänemark. Die Autofahrt dauerte
    recht lange. Kurz vor der Grenze machten wir noch einen Großeinkauf im Supermarkt, da in Dänemark die Produkte etwas teurer sind als in Deutschland.
    An der Grenze wurden wir sogar noch vom Zoll angehalten, aber zum Glück wurden wir durchgelassen.
  </p>
  <h2>Haus in Bogense</h2>
  <p>
    Wir hatten eine sehr gemütliche, zweigeschossige Wohnung in einem Ferienhaus an der Küste in Bogense Sogn auf der Insel Fyn und freuten uns schon darauf, 
    gemeinsam die nächsten Tage dort zu verbringen. Auch diesmal gab es am ersten Abend die traditionelle große Portion Bolognese.<br>
    Rund um Bogense gibt es eigentlich nicht viel zu sehen. Es gab ein paar größere Ausflüge, die wir geplant hatten, aber zwischendrin hatten 
    wir immer einen Tag Pause, den wir einfach nur gemütlich im Haus verbrachten, denn im Urlaub soll man sich ja auch ausruhen können. 
    Wir hatten in der Wohnung sogar ein großes Bad mit Whirlpool und Sauna und zwei meiner Freunde und ich machten uns daher einmal sogar 
    einen Wellnessabend.<br>
    Wir hatten einen guten Supermarkt in der Nähe, der fußläufig erreichbar war. Ansonsten gab es in dem Ort noch einen Sandstrand und einen 
    kleinen Hafen.
  </p>
  <div style="display: flex; margin-bottom: 8px;">
    <img src="@/assets/images/Daenemark/yachthafen.jpg" alt="Yachthafen" style="width: 309px; margin: 0 4px 0 0px;"/>
    <img src="@/assets/images/Daenemark/bogense.jpg" alt="Hafen in Bogense" style="width: 301px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/alex_kocht.jpg" alt="Alex kocht" style="width: 128px; height:201px; margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex; margin-bottom: 8px;">
    <img src="@/assets/images/Daenemark/bollerwagen.jpg" alt="Bollerwagen" style="height:197px; margin: 0 4px 0 0px;"/>
    <img src="@/assets/images/Daenemark/ferienhaeuser.jpg" alt="Ferienhaussiedlung" style="height:197px; width:322px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/jakob_am_strand.jpg" alt="Jakob am Strand" style="height:197px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/ferienhaeuser1.jpg" alt="Alex kocht" style="height:197px; margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex; margin-bottom: 24px;">
    <img src="@/assets/images/Daenemark/bogense2.jpg" alt="Hafen in Bogense" style="height:112px; width:169px; margin: 0 4px 0 0px;"/>
    <img src="@/assets/images/Daenemark/esstisch0.jpg" alt="Wir sitzen am Esstisch" style="height:112px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/esstisch2.jpg" alt="Wir sitzen am Esstisch" style="height:112px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/esstisch1.jpg" alt="Wir sitzen am Esstisch" style="height:112px; margin: 0 0 0 4px;"/>
  </div>
  <h2>Odense</h2>
  <p>
    Unser erster Ausflug ging in die schöne Stadt Odense (Aussprache <a href="https://upload.wikimedia.org/wikipedia/commons/d/d9/Da-Odense.ogg">\'oðˀnsə\</a>). 
    Als wir dort ankamen, hatte ich einen kleinen Kulturschock: Alles war so unglaublich sauber, die Straßenbahnen
    sahen so modern aus, die Hausfassaden waren makellos und die Straßen so schön gefliest, als wäre alles nur ein Modell. <br>
    Unser erstes Ziel war das Hans-Christian-Andersen-Museum, denn Odense ist die Geburtsstadt des berühmten dänischen Autors, dessen Geschichten "Die Kleine Meerjungfrau", "Die Prinzession auf der Erbse", 
    "Das hässliche Entlein" und "Die Eiskönigin" weltberühmt sind. Das Museum war kreativ und 
    interaktiv gestaltet, es wurden viele Elemente aus seinen Geschichten genommen und verkünstelt und man hat etwas über seine Lebensgeschichte erfahren.
  </p>
  <div style="display: flex;">
  <div>
  <div style="margin-bottom:4px; width:426px">
    <img src="@/assets/images/Daenemark/odense1.jpg" alt="Odense" style="height: 209px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/odense0.jpg" alt="Odense" style="height: 209px; margin: 0 4px 0 4px;"/>
  </div>
  <img src="@/assets/images/Daenemark/odense_kirche.jpg" alt="Odense Kirche" style="width: 421px;  margin: 0 4px 0 0;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/odense_museum.jpg" alt="Vor dem Hans-Christian-Andersen-Museum" style="width: 325px; height:248px; margin: 0 0 4px 4px;"/>
    <img src="@/assets/images/Daenemark/strassenbahn.jpg" alt="Odenser Straßenbahn" style="width: 325px;  margin: 0 0 0 4px;"/>
  </div>
  </div>
  <p>
    Gegen Mittag waren wir mit einem Einheimischen verabredet. Ich kannte Lasse von einem internationalen Discord-Server und wie der Zufall es wollte, 
    wohnt er in Odense und war bereit, uns eine Führung durch die Stadt zu geben. Da er zur Hälfte Deutsch ist, konnten wir uns auf Deutsch 
    unterhalten. Es ist etwas ganz Besonderes, sich im Ausland mit Einheimischen zu unterhalten, da sie einem ein Bild davon geben, wie es <i>tatsächlich</i>
    ist, in dem Land zu leben. <br>
    Unser erster Halt war der Hafen, an dem wir ein paar Bilder schießen konnten. Als nächstes wurden wir zur einer hippen Streetfood-Halle geführt.
    Dänemark ist unglaublich fortgeschritten in Sachen bargeldlosem bezahlen. Auf einem deutschen Markt ist man es gewohnt, dass man selbstverständlich 
    nur bar bezahlen kann. In dieser Streetfood-Halle konnte man nur mit Karte bezahlen.
  </p>
  <div style="display: flex;">
  <div>
    <img src="@/assets/images/Daenemark/Anne_am_Hafen.jpg" alt="Anne am Hafen" style="width:275px; margin: 0 4px 4px 0;"/>
    <img src="@/assets/images/Daenemark/odense_hafen1.jpg" alt="Hafe von Odense" style="width:275px; margin: 0 4px 0 0;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/street_food.jpg" alt="In der Street-Food-Halle" style="width:224px; margin: 0 4px 4px 4px;"/>
    <img src="@/assets/images/Daenemark/essen.jpg" alt="Street Food" style="width:224px;  margin: 0 4px 0 4px;"/>
  </div>
  <img src="@/assets/images/Daenemark/mit_lasse.jpg" alt="Alex und Lasse" style="width:240px; height:344px; margin: 0 0 0 4px;"/>
  </div>
  <p>
    Frisch gestärkt liefen wir noch eine ganze Weile durch die Stadt, schauten uns den schönen Park und die Kirche an und lernten viel über das
    Leben in Dänemark. Am Abend setzten wir uns noch in einen Irish Pub, der sich in einer uhrigen Kneipengasse befand, und ließen den 
    Abend ausklingen.
  </p>
  <div style="display: flex;">
  <div>
    <img src="@/assets/images/Daenemark/kneipenstrasse.jpg" alt="Odense" style="width:231px; margin: 0 4px 4px 0;"/>
    <img src="@/assets/images/Daenemark/irish_pub.jpg" alt="Lasse und Alex im Irish Pub" style="width:231px; margin: 0 4px 0 0;"/>
  </div>
  <div>
  <div>
    <img src="@/assets/images/Daenemark/kirche.jpg" alt="Kirche von Odense" style="width:145px; height:265px; margin: 0 4px 4px 4px;"/>
    <img src="@/assets/images/Daenemark/odense2.jpg" alt="Straße in Odense" style="width:145px; height:265px; margin: 0 4px 4px 4px;"/>
  </div>  
    <img src="@/assets/images/Daenemark/odense_gruppenbild.jpg" alt="Gruppenbild vor der Kirche" style="width:298px;  margin: 0 4px 0 4px;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/park2.jpg" alt="Odenser Park" style="width:205px; margin: 0 0 4px 4px;"/>
    <img src="@/assets/images/Daenemark/park0.jpg" alt="Odenser Park" style="width:205px; margin: 0 0 4px 4px;"/>
    <img src="@/assets/images/Daenemark/park1.jpg" alt="Odenser Park" style="width:205px; margin: 0 0 4px 4px;"/>
  </div>
  </div>
  <h2>Legoland</h2>
  <p>
    Eine große Attraktion Dänemarks ist zumindest für Einige das Legoland und LEGO wurde ja bekanntlich auch in Dänemark erfunden. 
    So machten wir uns nach Billund auf, um dem Park einen Besuch abzustatten. Bewundernswert waren natürlich die vielen kleinen, 
    detailreichen Modelle vieler Sehenswürdigkeiten Dänemarks und der ganzen Welt. Aber das ist nur ein kleiner Bereich des Legolands, zum größten 
    Teil ist es ein Freizeitpark mit Attraktionen und so waren wir dort auch so ziemlich die einzigen in unserer Altersgruppe. 
    Ich bin dort auch zum ersten Mal Achterbahn gefahren. Sagen wir so: es war auszuhalten, aber Spaß hat es mir nicht unbedingt gemacht.
    Am coolsten Fand ich dafür das Spiegellabyrinth. 
  </p>
  <div>
  <div style="margin-bottom: 4px;">
    <img src="@/assets/images/Daenemark/achterbahn.jpg" alt="Achterbahn" style="width:143px; height:337px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/legoland_gruppenbild.jpg" alt="Gruppenbild im Legoland" style="width:450px; height:337px; margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/wildwasserbahn.jpg" alt="Wildwasserbahn" style="width:143px; height:337px; margin: 0 0 0 4px;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/anne_und_arne_boot.jpg" alt="Boot" style="width:246px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/legopalast.jpg" alt="Palast aus Lego" style="width:244px;  margin: 0 4px 0 4px;"/>
    <img src="@/assets/images/Daenemark/jakob_im_boot.jpg" alt="Boot" style="width:246px;  margin: 0 0 0 4px;"/>
  </div>
  </div>
  <h2>Kopenhagen &ndash; København</h2>
  <p>
    Die Hauptstadt Dänemarks konnten wir uns nicht entgehen lassen. So machten wir uns einen anderen Tag auf den langen Weg. 
    Die Autofahrt dauerte etwa zwei Stunden und dabei überquerten wir die riesige Storebælt-Brücke, die eine Verbindung nach 
    Sjælland bildet.<br>
    Kopenhagen ist eine sehr schöne, skandinavische Stadt und wir hatten an dem Tag auch schönes Wetter. Als erstes schauten
    wir uns das Schloss Rosenborg an, welches in einem schönen Park liegt, in dem man gut Cappuccino trinken konnte. Dann 
    statteten wir der großen Frederiks Kirche einen Besuch ab und liefen dann zum Schloss Amalienborg &ndash; der Stadtresidenz der 
    dänischen Königin Margarethe II. Nun wollten wir uns aber endlich zur bekanntesten Sehenswürdigkeit, der kleinen Meerjungfrau, begeben.
    Auf dem Weg dorthin kamen wir noch durch das Kastell von Kopenhagen &ndash; einer sternförmigen Zitadelle, die von grasbewachsenen Dämmen
    umgeben war. Und dann kamen wir endlich an! Die Meerjungfrau war&hellip; klein, aber das machte nichts, sie war ein schönes Motiv für ein
    wunderschönes Gruppenbild!
  </p>
  <div style="display: flex;">
  <div>
    <img src="@/assets/images/Daenemark/rosenborg.jpg" alt="Schloss Rosenborg" style="width:207px; margin: 0 4px 4px 0;"/>
    <img src="@/assets/images/Daenemark/alex_im_park.jpg" alt="Alex im Park" style="width:207px;  margin: 0 4px 4px 0;"/>
    <img src="@/assets/images/Daenemark/kopenhagen_hafen.jpg" alt="Kopenhagen Hafen" style="width:207px;  margin: 0 4px 0 0;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/gruppenbild_meerjungfrau.jpg" alt="Gruppenbild vor der Meerjungfrau" style="width:322px; margin: 0 4px 0 4px;"/>
  </div>
  <div>
    <img src="@/assets/images/Daenemark/eis_im_park.jpg" alt="Eis im Park" style="width:210px; margin: 0 0 4px 4px;"/>
    <img src="@/assets/images/Daenemark/kaffee_im_park.jpg" alt="Cappouccino im Park" style="width:210px; height: 211; margin: 0 0 0 4px;"/>
  </div>
  </div>
  <p>
    Am Abend hatten wir noch eine Bootsrundfahrt durch den Hafen geplant. Vorher stärkten wir uns noch in einem asiatischen Restaurant.
    Die Hafentour war sehr schön, man hat viel über die Stadt erfahren und auch viel gesehen und als es so langsam dunkel wurde, 
    begann die Stadt auch langsam zu leuchten.<br>
    Die eine Hälfte der Gruppe ist noch geblieben, um sich in eine Bar zu setzten, die andere Hälfte, mich eingeschlossen, ist schon etwas 
    früher nach Hause gefahren. Insgesamt war es ein sehr schöner Tag.
  </p>
  <div>
  <div style="display: flex; margin-bottom:8px;">
    <img src="@/assets/images/Daenemark/kopenhagen3.jpg" style="height: 250px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/kopenhagen1.jpg" style="height: 250px; margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex;">
    <img src="@/assets/images/Daenemark/amalienborg.jpg" alt="Schloss Amalienborg" style="height: 242px;  margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/kopenhagen0.png" style="height: 242px;  margin: 0 4px 0 4px"/>
    <img src="@/assets/images/Daenemark/kopenhagen2.jpg" style="height: 242px;  margin: 0 0 0 4px;"/>
  </div>
  </div>
  <h2>Fyns Hoved Klippen</h2>
  <p>
    Am letzten Tag machten wir noch einen Ausflug in die Natur und besuchten die Klippen, welche uns Lasse empfohlen hatte. Es war eine 
    sehr schöne Wanderung durch die Natur der Insel und ich lasse hier lieber die Bilder für sich sprechen.
  </p>
  <div>
  <div style="display: flex; margin-bottom:8px;">
    <img src="@/assets/images/Daenemark/klippen0.jpg" style="height: 120px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/klippen1.jpg" style="height: 120px;  margin: 0 4px 0 4px"/>
    <img src="@/assets/images/Daenemark/klippen2.jpg" style="height: 120px;  margin: 0 4px 0 4px"/>
    <img src="@/assets/images/Daenemark/klippen3.jpg" style="height: 120px; margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex; margin-bottom:4px;">
    <img src="@/assets/images/Daenemark/klippen4.jpg" style="height: 254px;  margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/klippen5.jpg" style="height: 254px; width: 452px;  margin: 0 4px 4px 4px"/>
    <img src="@/assets/images/Daenemark/klippen7.jpg" style="height: 254px;  margin: 0 0 0 4px;"/>
  </div>
  <div style="display: flex;">
    <img src="@/assets/images/Daenemark/klippen6.jpg" style="height: 168px; width: 372px; margin: 0 4px 0 0;"/>
    <img src="@/assets/images/Daenemark/klippen8.jpg" style="height: 168px;  width: 373px; margin: 0 0 0 4px;"/>
  </div>
  </div>
  <h2>Fazit</h2>
  <p>
    Insgesamt hatten wir wieder einen wunderschönen Urlaub, den ich fast sogar noch schöner fand als Belgien. Bemerkenswert war wieder, 
    dass wir, obwohl wir eine Woche lang zu siebt in einer Wohnung lebten, uns so gut verstanden und harmonisch miteinander existiert haben.
    Wir sind uns alle nach den vielen Jahren einfach schon so vertraut geworden und haben einfach dieselbe Energie und Gemütlichkeit.
    Und das ist etwas, das ich an dieser Gruppe sehr schätzen.
  </p>
  <img src="@/assets/images/Daenemark/alex_money.jpg" style="float: right; width: 100px; margin: 4px 2px 0 12px;"/>
  <p>
    Einen Tipp möchte ich noch geben: Wenn ihr mal in Dänemark seid, hebt kein Bargeld ab! Ich wusste zwar von Lasse, dass man dort überall 
    mit Karte bezahlen kann, aber als Deutscher fühlt man sich immer etwas seltsam, komplett ohne Bargeld aus dem Haus zu gehen. Nun war es 
    aber tatsächlich so, dass wir überall ausschließlich mit Karte bezahlt haben, so hatte ich am Ende noch 400 Dänische Kronen, etwa 53&thinsp;€,
    übrig. Diese konnte ich aber zum Glück in Deutschland bei meiner Bank abgeben.
  </p>
  </div>
</template>
