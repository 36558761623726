<template>
  <div style="position:relative; width:830px; margin: 0px 0px 0px 50px; text-align:justify">
    <p>
      Alexander Zieschang<br>
      contact@alexadventures.de<br>
      +49 (0) 1517 0176649<br><br>
      Wenn Sie aus rechtlichen Zwecken (die mir nicht ergr&uuml;ndlich sind) meine Adresse haben wollen,
      so kontaktieren Sie mich bitte über obenstehende Angaben.
    </p>
  </div>
</template>
