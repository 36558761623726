<style scoped>
#overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: #000;
 filter:alpha(opacity=70);
 -moz-opacity:0.7;
 -khtml-opacity: 0.7;
 opacity: 0.7;
 z-index: 100;
 display: none;
 }
 .cnt223 a{
 text-decoration: none;
 }
 .popup{
 width: 100%;
 margin: 0 auto;
 display: none;
 position: fixed;
 z-index: 101;
 }
 .cnt223{
 min-width: 600px;
 width: 600px;
 min-height: 90px;
 margin: 100px auto;
 background: #f3f3f3;
 position: relative;
 z-index: 103;
 padding: 25px 35px;
 border-radius: 5px;
 box-shadow: 0 2px 5px #000;
 }
 .cnt223 p{
 clear: both;
     color: #555555;
     /* text-align: justify; */
     font-size: 20px;
     font-family: sans-serif;
 }
 .cnt223 p a{
 color: green;
 font-weight: bold;
 }
 .cnt223 .x{
 float: right;
 height: 35px;
 left: 22px;
 position: relative;
 top: -25px;
 width: 34px;
 }
 .cnt223 .x:hover{
 cursor: pointer;
 }

 .Inhalt{
  font-size: 19px;
 }

 .tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: black;
  background-color: white;
  text-align: left;

  padding: 5px 0px 5px 10px; }

.left{
  width: 200px;
  border: 2px solid black;
  font-size: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 50%;
}

.quelle{
  width: 288px;
  border: 1px solid black;
  font-size: 10px;
  position: absolute;
  z-index: 1;
  top: 135px;
  right: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#amphi{margin: -20px 0 0 15px}

#bachtours{width:100px; margin: 0 0 -4px -3px;}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #amphi{margin: 0px 0 0 15px}
  #bachtours{width: 180px; margin: 0 0 -8px -3px;}
}
 </style>


<template>
  <div style="position:relative; width:855px; margin: 0px 0px 0px 40px; font-size:19px; text-align:justify">
  <h1>Das Bachboot</h1>
  <p>
  Das Bachboot (auch Bachkreuzfahrtschiff oder GABI) ist seit seiner Entstehung und bis zum heutigen Tag eine Legende und ein untrennbarer Teil meines Lebens geworden.
  Dieser Artikel ist da zum Erinnern, Schmunzeln, zur Information und vor allem, um seine Geschichte mit all ihren Einzelheiten und Details zu erz&auml;hlen und zu dokumentieren.
  </p>
  <div style="float:right; width:350px; background-color: #E5E5E5; border: 1px solid black">
  <p style="margin:5px"><b>Technische Daten</b></p>
  <table style="padding-left:5px; line-height:166%">
  <tr>
  <td>Name:</td>
  <td>GABI</td>
  </tr>
  <tr>
    <td style="width:150px">Aktuelle Version:</td>
    <td>5.0</td>
  </tr>
  <tr>
  <td>L&auml;nge:</td>
  <td>2,45 m</td>
  </tr>
  <tr>
    <td>Breite:</td>
    <td>55 cm</td>
  </tr>
  <tr>
  <td>H&ouml;he:</td>
  <td>20 cm</td>
  </tr>
  <tr>
  <td>Gewicht:</td>
  <td>22 kg</td>
  </tr>
  <tr>
    <td>Kapazit&auml;t:</td>
    <td>2 Passagiere</td>
  </tr>
  <tr>
    <td>Tiefgang:</td>
    <td>15 cm*</td>
  </tr>
  <tr>
  <td>Geschwindigkeit:</td>
  <td>0,5 Knoten*</td>
  </tr>
  <tr>
  <td colspan="2"><hr></td>
  </tr>
  <tr>
  <td colspan="2" style="line-height:120%; padding-bottom: 10px; font-size:10px">* Bei 2 Passagieren ohne Str&ouml;mung</td>
  </tr>
  </table>
  </div>
  <div style="width:320px; background-color: #E5E5E5; border: 1px solid black; padding-left:5px">
  <p style="margin:5px;"><b>Inhaltsverzeichnis</b></p>
  <table>
  <tr>
  <td><a href="#1" class="Inhalt">1.</a></td>
  <td><a href="#1" class="Inhalt">Die Idee</a></td>
  </tr>
  <tr>
    <td><a href="#2" class="Inhalt">2.</a></td>
    <td><a href="#2" class="Inhalt">Der Entwurf</a></td>
  </tr>
  <tr>
  <td><a href="#3" class="Inhalt">3.</a></td>
  <td><a href="#3" class="Inhalt">Der Bau</a></td>
  </tr>
  <tr>
    <td><a href="#4" class="Inhalt">4.</a></td>
    <td><a href="#4" class="Inhalt">1. Bachkreuzfahrt</a></td>
  </tr>
  <tr>
  <td><a href="#41" class="Inhalt">5.</a></td>
  <td><a href="#41" class="Inhalt">Der Name</a></td>
  </tr>
  <tr>
    <td><a href="#5" class="Inhalt">6.</a></td>
    <td><a href="#5" class="Inhalt">Bachboot 2.0</a></td>
  </tr>
  <tr>
  <td><a href="#51" class="Inhalt">7.</a></td>
  <td><a href="#51" class="Inhalt">2. Bachkreuzfahrt</a></td>
  </tr>
  <tr>
    <td><a href="#6" class="Inhalt">8.</a></td>
    <td><a href="#6" class="Inhalt">Bachboot 3.0 und Gabietta</a></td>
  </tr>
  <tr>
  <td><a href="#7" class="Inhalt">9.</a></td>
  <td><a href="#7" class="Inhalt">3. Bachkreuzfahrt</a></td>
  </tr>
  <tr>
    <td><a href="#8" class="Inhalt">10.</a></td>
    <td><a href="#8" class="Inhalt">Bachboot 4.0</a></td>
  </tr>
  <tr>
  <td><a href="#9" class="Inhalt">11.</a></td>
  <td><a href="#9" class="Inhalt">4. Bachkreuzfahrt</a></td>
  </tr>
  <tr>
    <td><a href="11" class="Inhalt">12.</a></td>
    <td><a href="#11" class="Inhalt">Bachboot 5.0</a></td>
  </tr>
  <tr>
  <td><a href="#12" class="Inhalt">13.</a></td>
  <td><a href="#12" class="Inhalt">Aspirationen f&uuml;r die Zukunft</a></td>
  </tr>
  <tr>
    <td><a href="#13" class="Inhalt">14.</a></td>
    <td><a href="#13" class="Inhalt">Siehe auch</a></td>
  </tr>
  </table>
  </div>
  <p id="1" class="headline"><b>Die Idee</b></p>
  <p>Jede Legende hat einen Anfang und das Bachboot nahm seinen Anfang auf einer Mittelmeerkreuzfahrt im Jahre 2014 auf der ich gro&szlig;es Heimweh versp&uuml;rte. Ich habe mich damals gefragt, ob es nicht
  m&ouml;glich sei, Kreuzfahrten in unserer Heimat zu unternehmen und die Sch&ouml;nheit unserer Region zu ehren.<br>
  Sicherlich, es gibt bei uns den Main. Aber damals dachte ich in viel kleineren Dimensionen. Zu "unserer sch&ouml;nen Region" z&auml;hlte f&uuml;r mich maximal der Landkreis Darmstadt-Dieburg. Und hier
  haben wir nunmal nur die <a href="https://de.wikipedia.org/wiki/Gersprenz">Gersprenz</a> als einzigen gr&ouml;&szlig;eren "Fluss". Abgesehen davon haben wir hier nur B&auml;che, die daf&uuml;r aber durch
  malerische Ortschaften f&uuml;hren. Die Idee des Bachkreuzfahrtschiffs war geboren.</p>
  <p id="2" class="headline"><b>Der Entwurf</b></p>
  <p>
  Meine erste Priorit&auml;t war also gewesen, ein Gef&auml;hrt zu entwickeln, das es vermag, sich um enge Bachwindungen zu <i>schl&auml;ngeln</i>. Die Inspiration nahm ich vom Prinzip des
  <a href="http://schiffundtechnik.com/lexikon/k/koppelverband.html">Koppelverbands</a>, der in der Flussschifffahrt (ein deutsches Monsterwort) angewendet wird. <br>
  Mit meiner bl&uuml;henden Phantasie im Alter von 13 Jahren stellte ich mir vor, dieses Prinzip auf enge B&auml;che &uuml;bertragen zu k&ouml;nnen und dabei auch noch Personen zu bef&ouml;rdern.
  Ich hatte sogar in Erw&auml;gung gezogen, die Schwimmk&ouml;rper mit Helium zu bef&uuml;llen, damit das Boot im seichten Bachwasser nicht zu sehr einsinke...
  </p>
  <p id="3" class="headline"><b>Der Bau</b><br></p>
  <p>
    Im Januar 2015 begann ich mit den Bauarbeiten. Dies sind die Bretter, mit denen alles begann. Ich schnitt sie m&uuml;hevoll mit einer Hands&auml;ge aus Holzpaletten heraus. Ein gro&szlig;es Shoutout hierf&uuml;r f&uuml;r meinen
    Nachbarn Rainer, der bei einer Spedition arbeitet und mich stets mit ihnen versorgte!<br>
    Es handelt sich um Fichtenholz und ist damit ein traditionelles Holz f&uuml;r den Bootsbau. Fichte ist allerdings nicht sehr gut zum Biegen geeignet. Zudem bedarf es
    beim Biegen eines Holzes mit mindestens 20&percnt;-iger Feuchtigkeit und eines Dampfraumes mit einer Raumtemperatur von 100 &deg;C. Da ich beides nicht besa&szlig;,
    versuchte ich vergeblich, das Holz mithilfe von kochendem Wasser und einer Schubkarre zum Biegen zu bringen. Die Knicke sind letztendlich durch Ans&auml;gen
    und leichtes Anbrechen gelungen. <br>
    Die Holzbretter werden gleicherma&szlig;en von wasserdichtem Holzleim und kleinen N&auml;geln zusammengehalten. Diese Verarbeitung hat seit je her zuverl&auml;ssig und stabil gehalten. Der Korpus
    wurde zum Schluss ganz traditionell mit Bootslack lackiert.
  </p>
  <img src="@/assets/images/Bachboot/Anfaenge.jpg" style="width:855px"/>
  <p>
  Als n&auml;chstes galt es, die vier Einzelteile mit "Gelenken" zu verbinden. Dazu verwendete ich lediglich ein paar blaue M&uuml;llt&uuml;ten,
  die ich mit Holzleim anklebte und mit N&auml;geln annagelte. Es gab Scharniere, die man w&auml;hrend der Fahrt &ouml;ffnen k&ouml;nnen sollte,
  wenn man um eine enge Biegung herumfahren wollte. Diese Konstruktion war zum Scheitern verurteilt.
  </p>
  <img src="@/assets/images/Bachboot/Boot5.jpg" style="width:855px"/>
  <p>
  Immerhin war mir bewusst, dass das Boot Schwimmk&ouml;rper f&uuml;r den n&ouml;tigen Auftrieb ben&ouml;tigte. Diese fertigte ich aus Plastikrohren mit 10 cm Durchmesser,
  die ich mit einer Plastikt&uuml;te und ein paar Streifen Klebeband "abdichtete". Befestigt wurden diese Schwimmk&ouml;rper mit Spangurten und Ringschrauben.<br>
  Der erste Test wurde im Schwimmbad meiner Gro&szlig;eltern durchgef&uuml;hrt. Ich denke, ich muss nicht erkl&auml;ren, warum das Boot, damals zu meinem Entsetzen,
  sofort in sich zusammensank, sobald ich mich auf es draufsetzte.<br>
  Das Problem wurde dann notd&uuml;rftig durch einsetzbare Metallstangen gel&ouml;st.<br>
  Ein gro&szlig;es Defizit hatte das Boot allerdings noch: Es gab keinen Antrieb. Paddeln war undenkbar bei der labilen Konstruktion.
  Die L&ouml;sung bestand schlie&szlig;lich darin, ein Seil am Bug zu befestigen und es &uuml;ber den Fluss zu ziehen. <br>
  Ein praktischer Nebeneffekt der einzelnen Elemente war, dass man so das Boot zusammenklappen konnte, um es in einem Auto zu transportieren. Die erste Bachkreuzfahrt konnte beginnen.
  </p>
  <p id="4" class="headline"><b>1. Bachkreuzfahrt</b><br></p>
  <p>
  Am 28. M&auml;rz 2015 sollte es dann zur ersten Bachkreuzfahrt kommen. Ich rief meine Freunde zusammen, um das Boot im Dieburger Schlossgarten zu Wasser
  zu lassen.<br>
  Zun&auml;chst musste das Boot zusammengebaut werden.
  </p>
  <img src="@/assets/images/Bachboot/IMG-20150430-WA0001.jpg" style="width:855px"/>
  <p>
  Ich bewundere das Vertrauen, das meine Freunde in mich hatten. Zwei Mutige setzten sich auf das Boot, w&auml;hrend ich an der Flussbiegung
  bereitstand, um das Boot die etwa 60 m lange Strecke entlangzuziehen.<br>
  Die hintere Passagierin fiel direkt beim Anfahrtsruck r&uuml;ckw&auml;rts ins Wasser, die vordere sah sich gezwungen, nach 10 m selbst abzuspringen.
  </p>
  <img src="@/assets/images/Bachboot/Bkf1.jpg" style="width:855px"/>
  <p>
  Der Schl&uuml;ssel lag darin, das Gewicht auf alle vier Elemente zu verteilen. So gab es an diesem Tag doch noch einen Erfolg!
  </p>
  <img src="@/assets/images/Bachboot/IMG-20150430-WA0013.jpg" style="width:855px"/>
  <p id="41" class="headline"><b>Der Name - GABI</b><br></p>
  <p>
  Schiffe und Boote haben traditionell weibliche Namen und auch mein Boot brauchte einen Namen. Ich entschied mich f&uuml;r GABI.
  Das ist ein Akronym, zusammengesetzt aus den W&ouml;rtern <b>G</b>ersprenz, <b>A</b>lexander, <b>B</b>ach und <b>I</b>nnovation.
  </p>
  <p id="5" class="headline"><b>GABI 2.0</b><br></p>
  <p>
  Ich w&auml;re nicht ich, h&auml;tte ich es dabei belassen. So machte ich mich sofort an die Verbesserungsarbeiten.<br>
  Das Boot wurde um ein Element verl&auml;ngert, um f&uuml;r mehr Auftrieb zu sorgen. Au&szlig;erdem fasste ich jeweils zwei Elemente zusammen,
  sodass das Boot nur noch aus drei Teilen bestand. Das hatte den Vorteil, dass man es immer noch zusammenklappen konnte. Ich brachte
  auch ein <i>Beweisvideo</i> heraus, um meinen Freunden zu <i>beweisen</i>, dass es diesmal klappen w&uuml;rde!
  </p>
  <img src="@/assets/images/Bachboot/Gabi2.0.jpeg" style="width:855px"/>
  <img src="@/assets/images/Bachboot/IMG-20150704-WA0000.jpeg" style="width:410px;"/>
  <img src="@/assets/images/Bachboot/Boot12.png" style="width:439px;"/>
  <p id="51" class="headline"><b>2. Bachkreuzfahrt</b><br></p>
  <p>
  <a  class="tooltip" style="float:right; margin: 5px 0 0 15px;"><img src="@/assets/images/Bachboot/Boot29.jpg" style=" width: 290px;"><span class="tooltiptext left">Ja nat&uuml;rlich war dort wirklich eine Meerjungfrau! Das ist B&auml;rbel, die Gersprenznixe, die wohnt da.</span></a>
  Am 05. Juli 2015, dem hei&szlig;esten Tag des Jahres, fand endlich die zweite Bachkreuzfahrt im Schlossgarten statt! Zu sechst trafen
  wir uns an jenem Hochsommertag in Badesachen gekleidet. Das Boot wurde zu Wasser gelassen und wir setzten uns zu zweit drauf mit je einem
  Paddel f&uuml;r je eine Seite. <br>
  Vielleicht lag es daran, dass ich nun zum ersten Mal selbst auf dem Boot sa&szlig; und mein Traum in Erf&uuml;llung ging, die Gersprenz
  zu bereisen, dass ich so &uuml;berw&auml;ltigt war. Es ist jedoch au&szlig;er Frage, dass die au&szlig;erordentliche, wundersame Urwaldatmosph&auml;re
  uns vollkommen unerwartet in ihren Bann zog. Vor uns wog sich ein dicht zugewachsener, gr&uuml;ner Tunnel durch das Dickicht und unter den B&auml;umen hindurch.
  W&auml;hrend man durch das dunkle Gew&auml;sser fuhr oder neben dem Boot her watete, sich unter herunterh&auml;ngenden &Auml;sten seinen Weg bahnte
  und ab und zu blauen Libellen begegnete, hatte man ein authentisches Gef&uuml;hl, im Urwald des Amazonas gelandet zu sein.
  </p>
  <img src="@/assets/images/Bachboot/Urwald.png" style="width:420px; height:252px"/>
  <img src="@/assets/images/Bachboot/Urwald3.jpg" style="width:429px; height:252px"/>
  <p style="margin-top:30px"><b>Erinnerung</b><br></p>
  <p>
  <img src="@/assets/images/Bachboot/Kecksboot.jpeg" style="float:right; margin: -40px 0 0 25px; width: 200px;"/>
  Mit dieser kleinen Kecksspeise gedenkte ich zum Jahrestag am 28. M&auml;rz 2016 der ersten Bachkreuzfahrt.
  </p>
  <p id="6" style="margin-top:30px"><b>Bachboot 3.0 und Gabietta</b><br></p>
  <p>
  Nach der zweiten Bachkreuzfahrt waren die Rohre voller Wasser und &uuml;ber den Winter hinweg &ouml;ffneten sich hier und da einige L&ouml;cher,
  die geflickt werden mussten. Zum n&auml;chsten Jahr bekam das Bachboot einen pfiffigen, gr&uuml;nen Anstrich und passte sich somit farblich ausgezeichnet seinem Einsatzgebiet an.
  Des Weiteren kamen Sitze hinzu, sodass man bei Tiefgang nicht im Wasser sitzen musste.<br>
  Im Dieburger Schlossgarten kommt man maximal etwa 200 m weit, bis man an die n&auml;chste M&uuml;hle st&ouml;&szlig;t, die n&auml;chste Bachkreuzfahrt
  sollte allerdings etwas weiter gehen. In Hergershausen gibt es einen Abschnitt der Gersprenz, der verh&auml;ltnism&auml;&szlig;ig viel Wasser f&uuml;hrt, da vor ihm (fast) alle
  Nebengr&auml;ben der Gersprenz wieder in sie hineinf&uuml;hren. Hier sollte die n&auml;chste Bachkreuzfahrt stattfinden. So wurde das Bachboot zu meinen Gro&szlig;eltern nach Hergershausen gebracht.<br>
  Dort konstruierte ich einen fahrenden Untersatz, mit dem das Boot &uuml;ber Land bef&ouml;rdert werden konnte.
  </p>
  <img src="@/assets/images/Bachboot/Boot30.jpeg" style="width:182px; height:287px"/>
  <img src="@/assets/images/Bachboot/Boot31.jpeg" style="width:479px; height:287px"/>
  <img src="@/assets/images/Bachboot/Boot32.jpeg" style="width:182px; height:287px"/>
  <p>
  Anschlie&szlig;end konstruierte ich das Beiboot "Gabietta", das unser Gep&auml;ck bei der n&auml;chsten Bachkreuzfahrt bef&ouml;rdern sollte. Der Name
  Gabietta ist dabei die Italienische, weibliche Verniedlichung des Namen Gabi.
  </p>
  <img src="@/assets/images/Bachboot/Gabiette.jpg" style="width:855px"/>
  <p id="7" class="headline"><b>3. Bachkreuzfahrt</b><br></p>
  <p>
  Nur zu dritt trafen wir uns diesmal am 18. Juni 2016 und machten uns vollbepackt auf den Weg, durch das ganze Dorf Hergershausen durch bis zur Gersprenz.
  </p>
  <img src="@/assets/images/Bachboot/IMG-20161108-WA0006.jpg" style="width:855px;"/>
  <p>
  Dort, wo die Semme in die Gersprenz m&uuml;ndet, lie&szlig;en wir unser Boot zu Wasser. Es bereitete allerdings dieses Mal Schwierigkeiten, sich zu zweit auf das
  Boot zu setzen. Das Boot konnte sich zu dieser Zeit noch biegen und die hintere Person sank sofort ins Wasser ein. Vermutlich lag das an der ver&auml;nderten Gewichtsverteilung
  durch die neu hinzugekommenen Sitze. So mussten wir uns damit zufriedengeben, sich abwechselnd immer nur alleine hinaufzusetzten. Die Anderen konnten jedoch nicht wie
  letztes Mal neben dem Boot her waten: Die Gersprenz kann nach Regentagen an manchen Stellen an die 2 m tief sein und es war ungew&ouml;hnlich kalt und regnerisch f&uuml;r die Zeit, so
  sahen wir uns gezwungen, an einigen Stellen durch das kalte Wasser zu <i>schwimmen</i>.
  </p>
  <img src="@/assets/images/Bachboot/Semmemundung.png" style="height:253px; margin-right:8px"/>
  <img src="@/assets/images/Bachboot/TabeaUnterWasser1.png" style="height:253px "/>
  <p>
  <img src="@/assets/images/Bachboot/Mullhaufen.jpg" style="float:right; margin: 7px 0 0 15px; width: 320px;"/>
  Gabietta war w&auml;hrenddessen beladen mit diversen Dingen, u.a. Wechselklamotten, Gummistiefeln, einem Lappen und einem Schwamm zum Wasseraussch&ouml;pfen.
  Au&szlig;erdem diente sie als Kameraboot und dokumentierte die gesamte Fahrt.<br>
  Innerhalb einer Stunde konnten wir eine Strecke von 590 m zur&uuml;cklegen. Allerdings mussten wir auch &uuml;ber das Wasser wieder zur&uuml;ck und das erwies sich als
  sehr schwierig, da wegen des vielen Wassers die Str&ouml;mung auch ziemlich stark war. So geschah es, dass Gabietta in einer Stromschnelle kenterte und in zwei Teile zerbrach und
  wir eine ganze Menge an Sachen verloren. Auch die wasserdichte Kamera, die sich auf Gabi befand, fiel ins Wasser und liegt vermutlich noch immer da und ich warte immer noch auf den Moment,
  dass sie jemand findet und an mich zur&uuml;ckgibt.<br>
  Mit einem solch professionellen Equipment wir losgingen, mit einem solchen M&uuml;llhaufen kehren wir zur&uuml;ck.
  </p>
  <p id="8" class="headline"><b>Das Bachboot 4.0 - Ein neues Konzept</b><br></p>
  <p>
  Wenn das Bachboot eine Zukunft haben wollte, musste ein grundlegend neues Konzept her. So beschloss ich, nun letztendlich
  auf die Faltbarkeit zu verzichten, der Stabilit&auml;t zu Gunsten. Au&szlig;erdem haben sich abgeklebte Rohre als Schwimmk&ouml;rper nicht bew&auml;hrt und PET-Flaschen erwiesen sich als viel wasserdichter.
  W&uuml;rde ich heute nochmal ein Boot bauen, w&uuml;rde ich mich sofort f&uuml;r diese wesentlich umweltfreundlichere Variante entscheiden. So begann ich, zu sammeln, bis ich 55 Flaschen zusammengesammelt hatte.
  </p>
  <img src="@/assets/images/Bachboot/IMG-20160725-WA0000.jpeg" style="width:424px; height:594px"/>
  <img src="@/assets/images/Bachboot/IMG-20160725-WA0002.jpeg" style="width:425px; height:594px"/>
  <p id="9" class="headline"><b>4. Bachkreuzfahrt - Die erste richtige Kreuzfahrt</b><br></p>
  <p>
  <img src="@/assets/images/Bachboot/Aufbruch.jpg" style="float:right; width:400px; margin: 5px 0 0 15px"/>
  Am 26. Mai 2017 sollte es noch ein letztes Mal auf Tour gehen. Bei dieser Tour legten wir eine sehr weite Strecke zur&uuml;ck und hielten auch an markanten Stellen an, wie bei einer richtigen Kreuzfahrt und so, wie
  ich es mir von Anfang an vorgestellt hatte.<br>
  Wir trafen uns wieder zu dritt bei meinen Gro&szlig;eltern, diesmal viel professioneller mit Neoprenanz&uuml;gen,
  einem Surfbrett und einem frisch renovierten Boot.<br>
  An derselben Stelle stachen wir wieder in See. Am Anfang sind wir jedoch zweimal gekentert - die Flaschen schienen,
  das Gleichgewicht ver&auml;ndert zu haben. So mussten wir links und rechts unsere Beine heraush&auml;ngen lassen, um das Gleichgewicht zu halten.
  Auf diese Weise kamen wir jedoch stetig voran. <br>
  Die Bedingungen waren dieses Mal viel besser: Es war genug Wasser in der Gersprenz, aber es herrschte so gut wie keine Str&ouml;mung. Zwischen Hergershausen und Sickenhofen
  verl&auml;uft die Gersprenz f&uuml;r ein gutes St&uuml;ck kerzengerade und ist gleichzeitig auch sehr tief. An dieser Stelle war es sogar problemlos m&ouml;glich,
  flussaufw&auml;rts zu paddeln. <br>
  <a class="tooltip" style="float:right; margin: 2px 0 0 15px;"><img src="@/assets/images/Bachboot/Muehle.jpg" style=" width: 300px; height:169px"/><span class="tooltiptext quelle">Bildquelle: https://www.main-echo.de/storage/image/3/9/6/5/3695693_artikeldetail-bild_1spjxj_zWyo7r.jpg</span></a>
  Unseren ersten Halt machten wir an der Konfurter M&uuml;hle. Die Konfurter M&uuml;hle l&auml;sst sich bis ins Mittelalter zur&uuml;ckverfolgen. Vermutlich befand sich an dieser Stelle sogar einmal eine
  kleine Siedlung, die aber im 30-j&auml;hrigen Krieg untergegangen ist. An der Konfurter M&uuml;hle treffen gewaltige Wassermassen aufeinander. Nicht nur die Wasser der Gersprenz, auch die des Richer Baches und des L&auml;nderbaches
  kommen hier zusammen. So war die Konfurter M&uuml;hle eine der ergiebigsten M&uuml;hlen der Region. Heute noch findet man dort ein gewaltiges Wehr vor und das gro&szlig;e M&uuml;hlrad im Inneren der M&uuml;hle l&auml;sst sich heute noch bestaunen.
  Momentan befinden sich dort ein Pferdehof und eine Tierarztpraxis.<br>
  Bei unserem halt haben ich J. interviewt:
  </p>
  <p><i>Wie hast du die Fahrt bis jetzt wahrgenommen?</i></p>
  <p><b>J.</b> Es war eine sehr sch&ouml;ne Bachtour, bis jetzt. Ich denke, es wird auch noch mindestens genauso sch&ouml;n bei der Weiterreise. Es hat alles super geklappt und war entspannend.</p>
  <p><i>Wie findest du die Gersprenz?</i></p>
  <p><b>J.</b> Die Gersprenz... ja, ist ein sehr, sehr sch&ouml;nes, kleines Fl&uuml;sschen.</p>
  <p><i>Das finde ich auch. Und wie l&auml;uft es denn mit deinem Surfbrett?</i></p>
  <p><b>J.</b> Das l&auml;uft ganz gut, man kann sich drauflegen, draufknien, draufsetzten und dann mit dem Paddel paddeln oder mit den Armen.</p>
  <p>
  <img src="@/assets/images/Bachboot/Hexenturm.jpg" style="float:left; width:197px; margin: 5px 10px 0 0"/>
  Jenseits der Konfurter M&uuml;hle verl&auml;uft die Gersprenz sehr schlangenlinig und wird immer undurchdringbarer wegen zahlreichen Str&auml;uchern und B&auml;umen.
  An einer Flussbiegung hat man jedoch einen einzigartigen Ausblick auf die sonst sehr versteckte Altstadt Babenhausens mit dem Hexenturm, dem Schloss und einigen Fachwerkfassaden.<br>
  Danach f&uuml;hrt der Fluss entlang einer Schrebergartensiedlung in das Stadtgebiet Babenhausens hinein. Am Babenh&auml;user Hexenturm machten wir unseren n&auml;chsten Halt, weil die Babenh&auml;user
  Stadtm&uuml;hle uns den Weg versperrte.<br>
  <img src="@/assets/images/Bachboot/Hexenturm3.jpg" style="float:right; width:198px; margin: 5px 0 0 10px"/>
  Der Babenh&auml;user Hexenturm wurde vermutlich im 13. Jahrhundert im Zuge der Befestigung der Stadt Babenhausen errichtet und war einer von sieben Wehrt&uuml;rmen.
  Seinen Namen erlangte er, weil er einige Zeit lang als Stadtverlie&szlig; benutzt wurde. Und ja, auch Hexen wurden hier verurteilt, allerdings nur sehr wenige, im Gegensatz zu den
  Katholischen Ortschaften (<i>ehem ehem... Dieburg...</i>).<br>
  Die Babenh&auml;user Stadtm&uuml;hle l&auml;sst sich ebenfalls bis ins 13. Jahrhundert zur&uuml;ckverfolgen. Seit je her wurde in ihr Mehl gemahlen. Heute gibt es dort eine Gastst&auml;tte und eine Turbine
  erzeugt Strom mit der Kraft der Gersprenz.<br>
  Hinter der M&uuml;hle setzten wir unser Boot wieder ins Wasser und fuhren nun durch die Babenh&auml;user Innenstadt. Das Eiscaf&eacute; "Galileo" hat eine gro&szlig;e Glasfassade am Ufer der Gersprenz.
  F&uuml;r die dort sitzenden G&auml;ste waren wir eine kuriose Attraktion. Sie winkten uns fr&ouml;hlich zu.<br>
  Wir unterquerten die Platanenallee und gelangten nun in den langen Abschnitt der starken Stromschnellen nahe dem Feuerwehrturm. Sie zu durchqueren
  war ein aufregendes Abenteuer! Erst auf der H&ouml;he der Joachim-Schumann-Schule nahmen sie ein Ende. Nun mussten wir uns langsam Gedanken machen, wie wir den Fluss wieder verlassen k&ouml;nnten.
  Wir waren nun n&auml;mlich endlich in der N&auml;he des Wohngebiets Ost-II, wo meine Gro&szlig;tante wohnt und wo unsere Reise ihr Ende nehmen sollte.<br>
  Leider steht der folgende Teil der Bachkreuzfahrt jedoch in einem dunkleren Licht. Das Ufer lag in gro&szlig;er H&ouml;he und die H&auml;nge waren Steil und mit Brennnesseln bewachsen.
  Also fuhr J. voraus um nach einer M&ouml;glichkeit zum Aussteigen zu suchen. Uns gelang es aber w&auml;hrenddessen dennoch, das Boot den steilen Hang hinaufzutragen, so warteten wir am Ufer. Das war als wir uns aus den Augen verloren hatten
  und wir kehrten auch nicht mehr gemeinsam Heim.<br>
  Meine Gro&szlig;tante war sehr verwundert, als wir bei ihr aufkreuzten und fuhr uns zur&uuml;ck zu meinen Gro&szlig;eltern. Auch J. fand wieder zur&uuml;ck und die
  Geschichte nahm ein gutes Ende.
  </p>
  <p>
  Insgesamt legten wir an jenem Tag eine Strecke von etwa 7 km zur&uuml;ck, von denen wir uns etwa 5 km auf dem Wasser bewegten.
  </p>
  <p>
    Leider stellte diese Bachkreuzfahrt nicht nur die erste, sondern auch die letzte <i>richtige</i> Bachkreuzfahrt dar. Im Sommer 2019 fuhr ich alledings mit einem guten Freund dieselbe Strecke
    noch einmal mit einem I-SUP ab.
  </p>
  <p class="headline"><b>5. Bachkreuzfahrt</b><br></p>
  <p>
  Es ist noch zu erw&auml;hnen, dass im Juni 2017 eine f&uuml;nfte, untergegangene Bachkreuzfahrt im Dieburger Schlossgarten stattfand. Sie diente vielmehr dazu,
  die beleidigten Seelen zu bes&auml;nftigen, die bei den letzten Bachkreuzfahrten nicht dabei sein konnten. Die Gersprenz hatte zu dieser Zeit allerdings einen derart niedrigen Wasserstand,
  dass man wortw&ouml;rtlich im Matsch versank. So musste diese Bachkreuzfahrt im Grunde abgebrochen werden.
  </p>
  <p id="11" class="headline"><b>Bachboot 5.0 - Ein Amphibienfahrzeug</b><br></p>
  <p>
  <img id="amphi" src="@/assets/images/Bachboot/Fahrrad.jpg" style="float:right; width:200px; "/>
  Im Sommer 2019 sollte das Bachboot sein vorerst letztes Revival erleben. Da es nun komplett starr ist, l&auml;sst es sich nur noch sehr schwer mit dem Auto transportieren
  und die Bef&ouml;rderung mittels des fahrbaren Untersatzes ist sehr langwierig und anstrengend. Eine neue L&ouml;sung musste her und die nannte sich Amphibienfahrzeug.<br>
  An das Boot wurden gro&szlig;e Gummir&auml;der moniert, sodass man es bequem mit dem Fahrrad transportieren konnte. Wir kamen damit Problemlos von Langstadt bis in das
  3 km entfernte Dorf Sickenhofen, wo wir das Boot zum letzten Mal in seiner bisherigen Geschichte in der Gersprenz zu Wasser lie&szlig;en.
  </p>
  <p id="12" class="headline"><b>Aspirationen f&uuml;r die Zukunft</b><br></p>
  <p>
  <img src="@/assets/images/Bachboot/Gabietta5.jpg" style="float:right; width:180px; margin: -25px 0 0 15px"/>
  Zurzeit ruht das Bachboot bei mir im Garten. Auch Gabietta hat einen sch&ouml;nen, dekorativen Platz gefunden.
  Es gibt aber noch so viele sch&ouml;ne und sehenswerte Abschnitte der Gersprenz, die es wert sind, befahren zu werden, wie die 23,5 km zwischen Reichelsheim
  und Dieburg, die 7 km zwischen Dieburg und Hergershausen oder die 11 km zwischen Babenhausen und Mainhausen, wo die Gersprenz in den Main m&uuml;ndet.
  Es lohnt sich ein Blick auf den <a href="@/assets/images/Bachboot/Schifffahrtsplan%20Flussysthem%20Gersprenz.png">schematischen Flussplan</a> oder in den Artikel &uuml;ber die Gersprenz, den ich eines Tages schreiben m&ouml;chte.
  </p>
  <p id="13" class="headline"><b>Siehe auch</b><br></p>
  <p>
  Im Zuge der Bachkreuzfahrten entstanden einige Artikel und anderes Material, so z.B. auch eine PowerPoint-Pr&auml;sentation &uuml;ber das Bachboot
  auf Spanisch, die hier zum Download zur Verf&uuml;gung steht.<br>
  Einige werden wissen, dass bei jeder Bachkreuzfahrt auch jedes Mal ein Video entstand. Ich behalte mir es aber vor, sie nicht zu ver&ouml;ffentlichen.
  Alle anderen Artikel, Materialien und Buchungsbescheinigungen von meiner Reisagentur <img id="bachtours" src="@/assets/images/Bachboot/Bachtours.png"/>,
  inklusive aller Rechtschreibfehler und mit geschw&auml;rzten Namen k&ouml;nnen jedoch hier eingesehen werden:
  </p>
  <a href="../downloadable/Bkf1-1.jpg" target="_blank">Artikel zur 1. Bachkreuzfahrt (Teil 1)</a><br>
  <a href="../downloadable/Bkf1-2.jpg" target="_blank">Artikel zur 1. Bachkreuzfahrt (Teil 2)</a><br>
  <a href="../downloadable/Info_zu_Bachboot2.0.jpg" target="_blank">Info zum Bachboot 2.0</a><br>
  <a href="../downloadable/Buchungsbestaetigung.jpg" target="_blank">Buchungsbest&auml;tigung</a><br>
  <a href="../downloadable/Schifffahrtsplan_Flussystem_Gersprenz.png" target="_blank">Schifffahrtsplan Flusssysthem Gersprenz</a><br>
  <a href="../downloadable/El barco del Arroyo.pptx" download>El barco del Arroyo (pptx)</a><br>
  <p class="headline"><b>Fan-Art</b></p>
  <img src="@/assets/images/Bachboot/Brettchen.jpg" alt="" title="" style="width: 430px; margin-right:5px;"/>
  <img src="@/assets/images/Bachboot/LebkuchenBachboot.jpg" alt="" title="" style="width: 201px; margin-left:5px;"/>
  <p style="float:left; width: 400px; text-align:left">Ein Schneidebrettchen von Anne</p>
  <p style="float:right; width: 410px; text-align:left">Ein Lebkuchen-Bachboot von Tabea</p>
  <p style="margin-top:65px">
  Schreibt mir eure Fragen, Kommentare und Erinnerungen &uuml;ber das Bachboot und sie werden hier auf Wunsch ver&ouml;ffentlicht!
  </p>
</div>
</template>
