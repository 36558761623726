<template>
  <div class="reise-div">
  <h1>Föhr 2020</h1>
  <p>
    Das Jahr 2020 ist in vielerlei Hinsicht ein außergewöhnliches Jahr gewesen. Ich hatte zu Beginn des ersten
    Corona-Lockdowns mein Abitur geschrieben und im Juni meine mündlichen Prüfungen hinter mich gebracht.
    Im Juli habe ich dann für eine Woche lang mit meiner Mutter auf meiner Lieblingsinsel Föhr Urlaub gemacht.
    Die Coronasituation hatte sich in den Sommermonaten zum Glück etwas gelegt, so dass dieser Urlaub überhaupt
    erst möglich war. 
  </p>
  <h2>Reise in den hohen Norden</h2>
  <p>
    Hin sind wir mit dem Auto gefahren. Da wir erst abends ankamen, übernachteten wir noch eine Nacht in 
    Hattstedtermarsch &ndash; eine sehr dünn besiedelte Gemeinde an der Küste nördlich von Husum, bestehend aus grünem Marschland
    mit Schafen und vereinzelten Bauernhöfen.
  </p>
  <img src="@/assets/images/Foehr/haus_im_marsch.jpg" alt="Haus im Hattenstedter Marsch" style="width: 90%; margin-left: 40px;"/>
  <p>
    Am nächsten Morgen nahmen wir dann die Fähre auf die Insel.
  </p>
  <img src="@/assets/images/Foehr/faehre.jpg" alt="Fähre" style="width: 90%; margin-left: 40px;"/>
  <h2>Die Insel</h2>
  <p>
    Auf Föhr hatten wir ein wunderschönes, friesisches Ferienhaus "Zum Glücklichen Matthias" im kleinen Dorf Oldsum. Der Glückliche 
    Matthias war einst ein erfolgreicher Wahlfänger, der dort gelebt haben soll.
  </p>
  <img src="@/assets/images/Foehr/ferienhaus0.jpg" style="height: 404px; margin-left: 40px;"/>
  <img src="@/assets/images/Foehr/ferienhaus1.jpg" style="height: 404px; margin-left: 8px"/>
  <p>Föhr ist eine sehr grüne Insel mit vielen Weiden, Kühen und Schafen.</p>
  <img src="@/assets/images/Foehr/kuehe.jpg" alt="Kühe" style="width: 90%; margin-left: 40px;"/>
  <p>Und die Gärten auf der Insel sind wunderschön und knuffig angelegt mit vielen Stockrosen und Hagebutten.</p>
  <img src="@/assets/images/Foehr/garten1.jpg" style="height: 328px; margin-left: 0px;"/>
  <img src="@/assets/images/Foehr/garten2.jpg" style="height: 328px; margin-left: 8px"/>
  <img src="@/assets/images/Foehr/garten3.jpg" style="height: 328px; margin-left: 8px"/>
  <p>
    Die einzige Stadt auf der Insel ist Wyk. Wyk ist ein typischer, ruhiger Nordsee-Ferienort mit hübschen Häuschen, süßen Gassen und Strandpromenade.
  </p>
  <img src="@/assets/images/Foehr/wyk2.jpg" style="height: 447px; margin-left: 40px; margin-bottom: 3px;"/>
  <img src="@/assets/images/Foehr/wyk1.jpg" style="height: 447px; margin-left: 9px; margin-bottom: 3px;"/>
  <img src="@/assets/images/Foehr/wyk0.jpg" style="width: 90%; margin-left: 40px;"/>
</div>
</template>
