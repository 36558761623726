<template>
  <div style="position:relative; width:855px; margin: 25px 0px 50px 40px; text-align:justify">
    <h2>Der Gartenturm</h2>
    <p style="text-align:justify; line-height:1.3;">
    Eines   sch&ouml;nen   Sommertages   im   Jahre   2016   hatte   ich   eine sch&ouml;ne  Zeit  mit  jemandem  auf  dem  Plateau,  das  von  meinem Baumhaus   &uuml;briggenblieben   war.   Wir   hatten   einen   Tisch   und St&uuml;hle  dort  oben  aufgestellt  und  ein  Abendessen  zwischen  den B&auml;umen genossen.
    </p>

  <div class="side-text">
    <p>
    Als ich mit den Bauarbeiten der <router-link to="/garten/sonnenbeet">Schräge</router-link> begann, entschied ich, dass ich wieder einen Hochsitz mit Tisch und St&uuml;hlen brauchte, um zwischen den Baumkronen zu speisen. So begann ich im Jahr darauf mit dem Bau.
    <br>Die erste Konstruktion bestand aus vier 3,15&thinsp;m langen, 10x10&thinsp;cm dicken Holzbalken, die in Metallverankerungen in der Erde befestigt waren. Jeweils zwei Balken waren oben durch Querbalken miteinander verbunden, auf denen wiederum drei orthogonale Querbalken lagen. Diese waren unten durch zwei Querbretter und oben durch den Boden, bestehend aus zahlreichen Querbrettern, stabilisiert. Der Boden bot etwas mehr als 2&thinsp;m&sup2; Aussichts- und Entspannungsbereich. Es gab zwei St&uuml;hle an einem Tisch, so bot die Aussichtsplatform Platz f&uuml;r zwei Personen.
  </p>
  <p>
    Da die Aussichtsplatform doch sehr hoch und <i>in your face</i> &uuml;ber die Hecke zu den Nachbarn ragte, entschloss ich mich im Sommer 2019 dazu, die Plattform um etwa 30&thinsp;cm zu "stutzen".
    Au&szlig;erdem war mir der l&ouml;chrige Boden mit den lose angenagelten Brettern auf die Dauer dann doch etwas zu unsicher, deshalb ist er nun mit gro&szlig;en und breiten Brettern sicher angeschraubt.
    Erreichbar ist die Plattform immer noch &uuml;ber eine Leiter. Neu dazugekommen ist der hochklappbare Tisch in Form eines stilisierten Blattes, an dem drei Personen einen einzigartigen Platz finden. Dekoration und Sichtveredelung bieten die Ranken des wilden Weins, die Rose in einem Blumentopf auf Stelzen, sowie der Wein des <router-link to="/garten/sonnenbeet">Sonnenbeets</router-link>, der Flieder, der die Plattform liebevoll umarmt und ein weiterer bl&uuml;hender Busch.
    Von dem 3&thinsp;m hohen Aussichtspunkt aus hat man einen hervorrgenden Blick auf den Garten, das Beet und die umliegenden B&auml;ume, die da sind: Ein Kirschbaum, eine Eiche, ein Pflaumenbaum und ein wei&szlig;bl&uuml;hender Baum. Des Weiteren hat man einen Blick auf die Nachbarsg&auml;rten und deren B&auml;ume und Blumen (und Bewohner). In der Ferne sieht man ein gro&szlig;es Feld mit einem gro&szlig;en Wald im Hintergrund.
    Auf der anderen Seite l&auml;sst sich der 35&thinsp;m hohe, im neugothischen Stil erbaute Kirchturm der evangelischen Kirche Langstadt bewundern. Es ist ein Flaschenzug am Hochsitz befestigt, mit dem sich schwerere Objekte nach oben bef&ouml;rdern lassen.
  </p>
  <p>
    Leider kann der Gartenturm nicht mehr bestiegen werden: im Sommer 2020 traf ich den Entschluss, ihn abzurei&szlig;en. Dies hatte mehrere Gr&uuml;nde:
    Auch wenn es die Nachbarn nie gest&ouml;rt hat, war es mir irgendwann peinlich, dass ich diesen Turm gebaut habe, der so sehr &uuml;ber ihre Hecke ragte. Des Weiteren habe ich ihn im ganzen letzten Jahr vielleicht 2-3 mal benutzt und dann kam noch dazu, dass ich das Baumaterial gut f&uuml;r das
    <router-link to="/projekte/tinyhouse">Tinyhome</router-link> verwenden konnte.
  </p></div>

 <img src="@/assets/images/garten/374.jpg" id="left-img" style="margin-right:4px"/>
 <img src="@/assets/images/garten/378.jpg" id="right-img"/>
 <img src="@/assets/images/garten/375.jpg" class="side-image"/>
 <img src="@/assets/images/garten/376.jpg" class="side-image"/>
 <img src="@/assets/images/garten/dabex.jpg" class="side-image"/>
</div>
</template>

<style scoped>
  .side-text{
    float:left; width:410px; line-height:1.3; margin: -20px 12px 0 0px
  }

  .side-image{
    width: 430px;
  }

  #left-img{
    width: 222px;
  }

  #right-img{
    width: 204px;
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    .side-text{
      float:left; width:855px; line-height:1.4; margin: -30px 12px 20px 0px
    }

    .side-image{
      width: 854px;
    }

    #left-img{
      width: 443px;
    }

    #right-img{
      width: 407px;
    }
  }
</style>

