<template>
  <div style="position:relative; width:835px; margin: 0px 0px 0px 50px; font-size:19px; text-align:left">
    <p class="normaltext" style="font-size: 20px">
      <i>This is my second interactive Russian text for language learners with a focus on the genitive.<br>
        Hover over a word to find out its meaning or the case or tense it is in.</i><br>
      </p>
      <input type="checkbox" class="hackbox mehr_lesen" id="mehr_lesen_lesbarkeit"><label class="mehr_lesen" for="mehr_lesen_lesbarkeit">Show translation</label><br>

      <div class="volltext" style="width:400px; float:right; margin-right:0px">
        <p class="normaltext" style="line-height:210%; width:400px">
          <b>Moscow</b><br>
          Moscow is the capital of Russia and the biggest city of Europe.<br>
          Moscow is the centre of politics, economy, science and culture.<br>
          In the old part stands the Kremlin. Putin lives in it.<br>
          Next to it is the red square and a big, beautiful church.<br>
          In Moscow there is a big metro.
        </p>
      </div>

      <p class="normaltext" style="line-height:210%; width:430px">
        <b>&#1052;&#1086;&#1089;&#1082;&#1074;&#1072;</b><br>
        &#1052;&#1086;&#1089;&#1082;&#1074;&#1072;
        <a class="tooltip">&#1089;&#1090;&#1086;&#1083;&#1080;&#1094;&#1072;<span class="tooltiptext">capital</span></a>
        &#1056;&#1086;&#1089;&#1089;&#1080;&#1080; &#1080;
        <a class="tooltip">&#1089;&#1072;&#1084;&#1099;&#1081; &#1073;&#1086;&#1083;&#1100;&#1096;&#1086;&#1081;<span class="tooltiptext">the biggest (masc.)</span></a>
        &#1075;&#1086;&#1088;&#1086;&#1076; &#1045;&#1074;&#1088;&#1086;&#1087;&#1077;.<br>
        &#1052;&#1086;&#1089;&#1082;&#1074;&#1072; &#1094;&#1077;&#1085;&#1090;&#1088; &#1087;&#1086;&#1083;&#1080;&#1090;&#1080;&#1082;&#1080;, &#1101;&#1082;&#1086;&#1085;&#1086;&#1084;&#1080;&#1080;,
        <a class="tooltip">&#1085;&#1072;&#1091;&#1082;&#1077;<span class="tooltiptext">gen. science</span></a> &#1080; &#1082;&#1091;&#1083;&#1100;&#1090;&#1091;&#1088;&#1077;.<br>
        &#1042; <a class="tooltip">&#1089;&#1090;&#1072;&#1088;&#1086;&#1081;&nbsp;<span class="tooltiptext">prep. fem. old</span></a>
        <a class="tooltip">&#1095;&#1072;&#1089;&#1090;&#1080;<span class="tooltiptext">prep. of &#1095;&#1072;&#1089;&#1090;&#1100; (part)</span></a>
        &#1075;&#1086;&#1088;&#1086;&#1076;&#1072;
        <a class="tooltip">&#1089;&#1090;&#1086;&#1080;&#1090;<span class="tooltiptext">stands</span></a>
        &#1050;&#1088;&#1077;&#1084;&#1083;&#1100;. &#1042; &#1085;&#1105;&#1084; &#1078;&#1080;&#1074;&#1105;&#1090; &#1055;&#1091;&#1090;&#1080;&#1085;.<br>
        <a class="tooltip">&#1056;&#1103;&#1076;&#1086;&#1084;<span class="tooltiptext">next to</span></a>
        &#1089; &#1085;&#1080;&#1084;
        <a class="tooltip">&#1082;&#1088;&#1072;&#1089;&#1085;&#1072;&#1103; &#1087;&#1083;&#1086;&#1097;&#1072;&#1076;&#1100;<span class="tooltiptext">red square</span></a> &#1080; &#1073;&#1086;&#1083;&#1100;&#1096;&#1072;&#1103;, &#1082;&#1088;&#1072;&#1089;&#1080;&#1074;&#1072;&#1103;
        <a class="tooltip">&#1094;&#1077;&#1088;&#1082;&#1086;&#1074;&#1100;<span class="tooltiptext">church</span></a>.<br>
        &#1042; <a class="tooltip">&#1052;&#1086;&#1089;&#1082;&#1074;&#1077;<span class="tooltiptext">prep.</span></a>
        &#1077;&#1089;&#1090;&#1100; &#1073;&#1086;&#1083;&#1100;&#1096;&#1086;&#1077; &#1084;&#1077;&#1090;&#1088;&#1086;.
      </p>

    </div>
  </template>

  <style scoped>

  a{color: black}

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    left:0px;
    color: black;
    background-color: white;
    text-align: left;
    min-width: 90%;
    width:max-content;
    padding: 5px;
    padding-bottom: 3px;
    border: 1px solid black;
    font-size: 10px;
    line-height: 100%;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 28px;
    right: 0%;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  /* default-Zustand */
  .volltext {
    display:none;
  }

  /* bei gesetztem Haken */
  input.mehr_lesen:checked ~ .volltext {
    display:inline;
  }

  </style>
