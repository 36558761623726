<template>
  <div id="helper">
     <table id="Legende">
       <tr>
         <th style="font-size:20px"><b>Key</b></th>
       </tr>
       <tr>
         <td style="font-weight:bold">Preposition</td>
       </tr>
       <tr>
         <td style="background:yellow">Genitive</td>
       </tr>
       <tr>
         <td style="background:DeepSkyBlue">Dative</td>
       </tr>
       <tr>
         <td style="background:#2DA64D">Accusative</td>
       </tr>
       <tr>
         <td style="background:orange">Instrumental</td>
       </tr>
       <tr>
         <td style="background:#E92954">Prepositional</td>
       </tr>
     </table>
     <button style="margin: 9px 5px 0px 5px; font-size:10px; width:96px;" @click="showtrans()">Show translation</button>
     <button style="margin: 0 5px 5px 5px; font-size:10px; width:96px;" @click="hidetrans()">Hide translation</button>
    </div>

  <div style="position:relative; width:835px; margin: 0px 0px 0px 50px; font-size:19px; text-align:left">
    <p style="margin: 20px 0px 20px 0px; width:840px; font-size: 20px;">
   <i>This is a traditional Russian folklore. If you only have a very basic knowlege of Russian, you might find it difficult to understand the story.
   For this, however, there is the translation. The story is, however, very valuable for getting a feel of the russian language and for seeing the cases and verbal prefixes in use!<br>
   Furthermore the correct use of prepositions can be trained here. <a style="color:blue;" href="http://www.study-languages-online.com/grammar/tables/prepositions-by-case">Here</a> you have a table that tells you, which cases come after which preposition!</i>
   </p>
   <input type="checkbox" class="mehr_lesen" id="mehr_lesen_lesbarkeit"><label>Show translation</label><br>
   <input type="checkbox" id="CheckCases" @click="showCases()"><label>Highlight cases</label><br>

   <div id="trans" class="volltext" style="width:400px; float:right; margin-right:0px">
   <p class="normaltext" style="line-height:210%; width:400px">
   <b>Masha and the bear</b><br>
   Once upon a time there lived a grandfather and a grandmother. They had a granddaughter Mashenka. <br>
   - <i> Grandfather, grandmother, </i> - says Masha, - <i> let me go into the forest with my friends! </i> <br>
   Grandfather and grandmother answer: <br>
   - <i> Go, just look, don’t leave your friends, otherwise you will get lost. </i> <br>
   The girls came to the forest, began to pick mushrooms and berries. And so Masha went far, far from her friends. <br>
   She began to call them. But her friends do not hear, do not answer. <br>
   So Masha went, went through the forest - and got completely lost. <br>
   She sees that there is a hut. Mashenka knocked on the door - they did not answer. She pushed the door, the door opened. <br><br>
   Mashenka entered the hut and sat on the bench by the window. <br>
   She sat down and thinks: <br>
   "Who lives here? Why is no one to be seen? ..."<br>
   And in that hut lived a huge bear. Only then he was not at home: he was walking through the woods. The bear returned in the evening, saw Mashenka and was delighted. <br>
   "Yeah," he says, "now I won’t let you go!" You will live with me. You will heat the stove, you will cook porridge and feed me porridge. <br>
   There is nothing to be done, Masha thought. She began to live with the bear in a hut. <br>
   The bear goes off into the forest for the whole day, and warns Mashenka not to leave the hut without him. <br>
   - <i> And if you leave, </i> - he says, - <i> I’ll catch you anyway and then I’ll eat you! </i> <br>
   Mashenka began to think how to escape from the bear. Around is forest, which way one goes - she does not know, there is no one to ask ... <br>
   She thought, thought, and came up with (an idea). <br>
   The bear comes from the forest and Mashenka says to him: <br>
   - <i> Bear, bear, let me go to the village for a day: I will bring a present to my grandparents.</i> <br><br>
   - <i> No, </i> - the bear says, - <i> you will get lost in the forest. Come on, I’ll bring them myself! </i> <br><br>
   And Mashenka needs that! <br>
   She baked pies, took out a large box and said to the bear: <br>
   - <i> Here, look: I’ll put pies in the box, and you take them to grandmother and grandfather. Do not open the box, do not eat the pies! I’ll climb a tree, I’ll watch you! </i> <br>
   - <i> Okay, </i> - the bear answers, - <i> give me the box! </i> <br>
   Only the bear didn’t look, Mashenka immediately got into the box. <br>
   He took it on his back and went to the village. <br>
   The bear walked and walked through the forest, got tired and said: <br> <br>

   I'll sit down on a stump, <br>
   Eat the pie! <br> <br>

   A Masha from the box: <br> <br>

   I see, I see! <br>
   Don't sit on a stump, <br>
   Do not eat the pie! <br>
   Take it to Grandma, <br>
   Take it to Grandpa! <br> <br>

   - <i> What a big-eyed, </i> - the bear says, - <i> sees everything! </i> <br>
   He picked up the box and went on. Walked, walked, walked, walked, stopped, sat down and said: <br> <br>

   I'll sit down on a stump, <br>
   Eat the pie! <br> <br>

   And Masha from the box again: <br> <br>

   I see, I see! <br>
   Don't sit on a stump, <br>
   Do not eat the pie! <br>
   Take it to Grandma, <br>
   Take it to Grandpa! <br> <br>

   The bear was surprised: <br>
   - <i> She's so tricky! Sitting high, looking far! </i> <br><br>
   He got up and went fast. <br>
   He came to the village, found the house where grandfather and grandmother lived and began to knock on the door: <br>
   - <i> Knock knock knock! Open! I brought you a gift from Masha. </i> <br>
   But no one is opening <br>
   He put the box at the door and went away into the forest again. <br>
   Grandfather and grandmother came out to the door. They see, there stands a box. <br>
   - <i> What is it in the box? </i> - says the grandmother. <br>
   And grandfather lifted the lid, looks and does not believe his eyes: Masha is sitting in the box. <br>
   Grandfather and grandmother rejoiced. They began to hug Masha, kiss, and call her clever.
   </p>
   </div>

   <p class="normaltext" style="line-height:210%; width:420px">
  <b>Маша и медведь</b><br>
  Жили-были дедушка и бабушка. Была <span name="prep">у</span> <span name="gen">них</span> внучка Машенька.<br><br>
  - <i>Дедушка, бабушка,</i> - говорит Машенька, - <i>отпустите <span name="akk">меня</span> в лес <span name="prep">c </span> <span name="ins">подружками</span>!</i><br>
  Дедушка <span name="prep">с</span> <span name="ins">бабушкой</span> отвечают:<br>
  - <i>Иди, только смотри, <span name="prep">от</span> <span name="gen">подружек</span> не уходи, а то заблудишься.</i><br>
  Пришли девушки в лес, стали собирать грибы и ягоды. Вот Машенька и ушла далеко-далеко <span name="prep">от</span> <span name="gen">подружек</span>.<br>
  Стала она <span name="akk">их</span> звать. А подружки не слышат, не отвечают.<br>
  Ходила, ходила Машенька <span name="prep">по</span> <span name="dat">лесу</span> - совсем заблудилась.<br>
  Видит-стоит избушка. Постучала Машенька в дверь - не отвечают. Толкнула она дверь, дверь и открылась.<br><br>
  Вошла Машенька <span name="prep">в</span> <span name="akk">избушку</span>, села у окна <span name="prep">на</span> <span name="akk">лавочку</span>.<br>
  Села и думает:<br>
  „Кто же здесь живёт? Почему <span name="akk">никого</span> не видно?...“<br>
  А <span name="prep">в</span> <span name="pre">той избушке</span> жил большущий медведь. Только <span name="gen">его</span> тогда дома не было: он <span name="prep">по</span> <span name="dat">лесу</span> ходил. Вернулся вечером медведь, увидел <span name="akk">Машеньку</span>, обрадовался.<br>
  - Ага, - говорит, - теперь не отпущу тебя! Будешь <span name="prep">у</span> <span name="gen">меня</span> жить. Будешь печку топить, будешь кашу варить, меня кашей кормить.<br>
  Да ничего не поделаешь - подумала Маша. Стала она жить <span name="prep">у</span> <span name="gen">медведя</span> <span name="prep">в</span> <span name="pre">избушке</span><br>
  Медведь на целый день уходит в лес, а Машеньке наказывает никуда <span name="prep">без</span> <span name="gen">него</span> <span name="prep">из</span> <span name="gen">избушки</span> не выходить.<br>
  - <i>А если уйдёшь,</i> - говорит, - <i>всё равно поймаю и тогда уж съем!</i><br>
  Стала Машенька думать, как <span name="dat">ей</span> <span name="prep">от</span> <span name="gen">медведя</span> убежать. Кругом лес, <span name="prep">в</span> <span name="akk">какую сторону</span> идти - не знает, спросить не <span name="prep">у</span> <span name="gen">кого</span>...<br>
  Думала она, думала и придумала. <br><br>
  Приходит раз медведь <span name="prep">из</span> <span name="gen">леса</span>, а Машенька и говорит <span name="dat">ему</span>:<br>
  - <i>Медведь, медведь, отпусти <span name="akk">меня</span> на день в деревню: я <span name="dat">бабушке</span> и <span name="dat">дедушке</span> подарок принесу.</i><br>
  - <i>Нет,</i> - говорит медведь, - <i>ты в лесу заблудишься. Давай подарок я <span name="dat">им</span> сам отнесу!</i><br>
  А <span name="dat">Машеньке</span> <span name="gen">того</span> и надо!<br>
  Напекла она пирожки, достала большой короб и говорит <span name="gen">медведю</span>:<br>
  - <i>Вот, смотри: я в короб положу пирожки, а ты отнеси <span name="akk">их</span> <span name="prep">к</span> <span name="dat">бабушке</span> и <span name="dat">дедушке</span>. Короб не открывай, пирожки не съедай! Я на дерево влезу, <span name="prep">за</span> <span name="ins">тобой</span> следить буду!</i><br>
  - <i>Ладно,</i> - отвечает медведь, - <i>давай короб!</i><br>
  Только медведь не посмотрел, Машенька сейчас же залезла в короб.<br>
  Он взял <span name="akk">его</span> на спину и пошёл <span name="prep">в</span> <span name="akk">деревню</span>.<br>
  Шёл-шёл медведь через лес, устал и говорит:<br><br><br>

  Сяду на пенёк,<br>
  Съем пирожок!<br><br>

  А Машенька <span name="prep">из</span> <span name="gen">короба</span>:<br><br>

  Вижу, вижу!<br>
  Не садись на пенёк,<br>
  Не ешь пирожок!<br>
  Неси <span name="dat">бабушке</span>,<br>
  Неси <span name="dat">дедушке</span>!<br><br>

  - <i>Какая глазастая,</i> - говорит медведь, - <i>всё видит!</i><br>
  Поднял он короб и пошёл дальше. Шёл-шёл, шёл-шёл, остановился, сел и говорит:<br><br><br>

  Сяду на пенёк,<br>
  Съем пирожок!<br><br>

  А Машенька из короба опять:<br><br>

  Вижу, вижу!<br>
  Не садись на пенёк,<br>
  Не ешь пирожок!<br>
  Неси <span name="dat">бабушке</span>,<br>
  Неси <span name="dat">дедушке</span>!<br><br>

  Удивился медведь:<br>
  - <i>Вот какая хитрая! Высоко сидит, далеко глядит!</i><br>
  Встал и пошёл скорее.<br>
  Пришёл <span name="prep">в</span> <span name="akk">деревню</span>, нашёл дом, где дедушка <span name="prep">с</span> <span name="ins">бабушкой</span> жили, и стал стучать в дверь:<br><br>
  - <i>Тук-тук-тук! Oткрывайте! Я вам <span name="prep">от</span> <span name="gen">Машеньки</span> подарок принёс.</i><br>
  Но никто не открывает.<br>
  Поставил короб <span name="prep">у</span> <span name="gen">двери</span> и ушёл опять в лес.<br><br>
  Вышли тут дедушка и бабушка <span name="prep">к</span> <span name="dat">двери</span>. Видят - короб стоит.<br>
  -<i> Что это <span name="prep">в</span> <span name="pre">коробе</span>? </i>- говорит бабушка.<br>
  А дедушка поднял <span name="akk">крышку</span>, смотрит и <span name="dat">глазам своим</span> не верит: <span name="prep">в</span> <span name="pre">коробе</span> Машенька сидит.<br><br>
  Обрадовались дедушка и бабушка. Стали <span name="akk">Машеньку</span> обнимать, целовать, <span name="dat">умницей</span> называть.
   </p>

  </div>
</template>

<script>
export default{
  name: 'Buecher',

  methods: {
    showtrans: function(){
      var trans = document.getElementById("trans");
      trans.style.display = "inline";
    },

    hidetrans: function(){
      var trans = document.getElementById("trans");
      trans.style.display = "none";
    },

    makeBold: function(name, style){
      var items = document.getElementsByName(name);
      for (var i=0; i < items.length; i++) {
        items[i].style.fontWeight = style;
      }
    },

    changeStyle: function(name, color){
      var items = document.getElementsByName(name);
      for (var i=0; i < items.length; i++) {
        items[i].style.background = color;
      }
    },

    showCases: function(){
      // Get the checkbox
      var checkBox = document.getElementById("CheckCases");
      // Get the output text
      var prep = document.getElementsByName("prep");
      var gen = document.getElementsByName("gen");
      var ins = document.getElementsByName("ins");
      var akk = document.getElementsByName("akk");
      var pre = document.getElementsByName("pre");
      var dat = document.getElementsByName("dat");

      var key = document.getElementById("helper");

      // If the checkbox is checked, display the output text
      if (checkBox.checked == true){
        this.makeBold("prep", "bold");
        this.changeStyle("gen", "yellow");
        this.changeStyle("ins", "orange");
        this.changeStyle("akk", "#2DA64D");
        this.changeStyle("pre", "#E92954");
        this.changeStyle("dat", "DeepSkyBlue");
        key.style.display = "block";
      } else {
        this.makeBold("prep", "normal");
        this.changeStyle("gen", "white");
        this.changeStyle("ins", "white");
        this.changeStyle("akk", "white");
        this.changeStyle("pre", "white");
        this.changeStyle("dat", "white");
        key.style.display = "none";
      }
    }
  }
}
</script>

<style scoped>
#helper{
   display: none;
   float:left;
   position:fixed;
   width:104px;
   margin: 100px 0 3px -151px;
 }


 #Legende {
 text-align: left;
 background: white;
 border: 1px solid black;
 font-size:17px;
 }

a{color: black}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  left:0px;
  color: black;
  background-color: white;
  text-align: left;
  min-width: 90%;
  width:max-content;
  padding: 5px;
  padding-bottom: 3px;
  border: 1px solid black;
  font-size: 10px;
  line-height: 100%;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 28px;
  right: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* default-Zustand */
.volltext {
  display:none;
}

/* bei gesetztem Haken */
input.mehr_lesen:checked ~ .volltext {
  display:inline;
}

</style>
