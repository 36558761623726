<template>
  <div id="Tabelle" style="position:absolute;">
    <table>
      <tr>
        <td class="td"><router-link to="/philosophie" style="color:white; text-decoration: none">Philosophie</router-link></td>
      </tr>
      <tr>
        <td class="td"><router-link to="/gesellschaft" style="color:white; text-decoration: none">Gesellschaft</router-link></td>
      </tr>
    </table>
   </div>
  <div style="position:relative; width:835px; margin: 0px 0px 0px 50px; font-size:19px; text-align:justify">
    <p>
      <br>
      <b>Artikel:</b><br>
      <br>
      <router-link to="/gesellschaft/gendern">Gendern - Haben wir das wirklich nötig?</router-link>
      <br>
      <br>
      <router-link to="/gesellschaft/bootparabel">Bootparabel</router-link>
      <br>
      <br>
    </p>
  </div>
</template>
<style scoped>
a{
  text-decoration: underline;
}
#Tabelle {
width:140px;
padding: 10px;
color:white;
margin: -10px 0 0px -180px;
font-size:20px;
 }
.td {
color: white;
background: #193f7d;
padding: 5px 10px 5px 10px;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #Tabelle {
    display: none;
  }
}
</style>
