<template>
  <div class="reise-div">
  <h1>Berlin - Hamburg - Nordsee</h1>
  <h2>Berlin</h2>
  <p>
  Im Hochsommer 2019 bin ich mit Anne durch Norddeutschland gereist. Unsere erste Station war die Hauptstadt Berlin, dort ging es mit dem
  FlixTrain hin!<br>
  Als wir an der Station Tiergarten ankamen, waren wir direkt im Gro&szlig;stadt-Rush! Unsere Unterkunft lag jedoch im ruhigen, aber sehr
  eleganten Stadtteil Grunewald. Es war das Jugendg&auml;stehaus St. Michaels. Nachdem wir dort unsere Sachen abgelegt hatten, ging es sofort
  wieder los in die gro&szlig;e Stadt! <br>
  Unser erster Halt war die Berliner East-Side-Gallery - dort wo die Mauer mit zahlreichen Kunstwerken bemalt ist und wo auch das ber&uuml;hmte
  Bild vom "Bruderkuss"  zu finden ist. Dann ging es weiter zum Alexanderplatz.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190722_165106606.jpg" alt="Sonnenuhr Alexanderplatz" style="width: 700px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190722_165308257.jpg" alt="Berliner Fernsehturm" style="width: 175px; position:absolute; right:0px;"/>
  <p>
  Standarttouristen, die einen Blick auf Berlin haben wollen, geben gew&ouml;hnlich viel Geld aus und stehen lange in der schlange, um auf den
  Berliner Fernsehturm zu steigen. Kenner wie ich wissen, dass es am Alexanderplatz das Park Inn Hotel gibt und dass man f&uuml;r wenig Geld auf dessen Dach fahren
  kann und einen ebenso sch&ouml;nen Ausblick auf Berlin erleben kann. Und dort gibt es sogar Liegest&uuml;hle und eine Bar.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190722_171723558.jpg" alt="Aussicht auf Berlin" style="width: 755px;"/>
  <p>
  Dann haben wir uns den Potsdamer Platz angeschaut, dort gehen immer die Promis hin und dort stehen ein paar Hochh&auml;user. Wir kamen auch am
  ber&uuml;hmten "Checkpoint Charlie" vorbei, der Ort, an dem fr&uuml;her drei Besatzungszonen zusammenliefen.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190722_183134209.jpg" alt="Tafel mit den drei Sprachen der Besatzer" style="width: 755px;"/>
  <p>
  Zu Abend gegessen haben wir im gem&uuml;tlichen und uhrigen Nikolaiviertel.<br>
  Am n&auml;chsten Tag war wundersch&ouml;nes Wetter und wir machten uns auf den Weg zum Bundestag. Wer auf die Kuppel hoch m&ouml;chte, muss einen Tag
  vorher reservieren, sonst gibt es keinen Weg hinein.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190723_102822354.jpg" alt="Bundestag" style="width: 755px;"/>
  <p>Danach gelangten wir zum Brandenburger Tor, welches leider gerade saniert wurde.</p>
  <img src="@/assets/images/Nordsee/IMG_20190723_104218739.jpg" alt="Brandenburger Tor" style="width: 755px;"/>
  <p>Der n&auml;chste halt sollte der Kurf&uuml;rstendamm sein - die ber&uuml;hmte Einkaufsmeile. Ich habe nur leider ins Handy "Kurf&uuml;rstenstra&szlig;e"
  eingegeben und als wir dort ankamen, waren wir sehr verwundert. Zum Gl&uuml;ck war unser eigentliches Ziel nicht allzu weit weg. <br>
  Es war inzwischen schon sehr hei&szlig; geworden, doch zur Abrundung des Tages haben wir noch einen Ausflug in die Barocke Stadt Potsdam unternommen,
  der es definitiv Wert war. Auf dem folgenden Bild ist der ber&uuml;hmte Park "Sans Souci" (ohne Sorgen) von Friedrich dem Gro&szlig;en zu sehen.</p>
  <img src="@/assets/images/Nordsee/IMG_20190723_143921572.jpg" alt="Park Sans Souci" style="width: 755px;"/>
  <p>
  Wer schnell und f&uuml;r wenig Geld von Berlin nach Hamburg gelangen m&ouml;chte, der kann den IRE (Interregionalexpress) nehmen. Er f&auml;hrt zweimal am
  Tag und eine Einzelfahrt kostet 19,90 &euro;, jedoch manchmal auch nur 14,90 &euro; zum Spezialpreis.
  </p>
  <h2>Hamburg</h2>
  <img src="@/assets/images/Nordsee/Screenshot_20200108-194914.png" alt="Singender Fisch" style="width: 190px; height:300px; float: right; padding: 5px 0 10px 20px;"/>
  <p>Wir kamen abends in Hamburg an und man merkte sofort, dass wir in einer ganz anderen Stadt waren. Mein erster Eindruck von Hamburg war der einer dreckigen
  und lauten Gro&szlig;stadt. Das lag aber auch nur daran, dass unser Hotel - die Superbude - in einem solchen Teil der Stadt lag. <br>
  Das Hotel hatte einen sehr alternativen, modernen, jugendlichen und schrillen Stil. Wir hatten einen singenden Fisch in unserem Bad! <br>
  Und dann war das Zimmer fast so klein, dass man, wenn man zur T&uuml;r reinkam, schon fast im Bett lag.
  </p>
  <p>
  Am n&auml;chsten Tag war wieder wundersch&ouml;nes Wetter und ich konnte die Stadt von ihrer sch&ouml;nen Seite sehen. Als erstes f&uuml;hrte mich Anne in
  die Hafenstadt. Als wir eine Weile zwischen gro&szlig;en Backsteinbauten und &uuml;ber zahlreiche Kan&auml;le wanderten, tauchte vor uns pl&ouml;tzlich die Elbphilharmonie
  auf. Sie z&auml;hlt f&uuml;r mich seitdem zu den sch&ouml;nsten Geb&auml;uden Deutschlands.
</p>
  <img src="@/assets/images/Nordsee/IMG_20190724_114030058.jpg" alt="Elbphilharmonie" style="width: 755px;"/>
  <p>
  Die Elbphilharmonie ist ein Konzerthaus. Der untere Teil passt sich wunderbar der Hafencity an, der obere Teil ist wie eine fantastische, magische Welle!
  Auf die Mittelebene, dort wo die Leute stehen, gelangt man &uuml;ber die l&auml;ngste Rolltreppe Europas. Von dort aus hat man eine herrliche Aussicht auf Hamburg!
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190724_104737994.jpg" alt="Hafenstadt" style="width: 246px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190724_120502566.jpg" alt="Rathaus" style="width: 246px; margin: 0 8px 0 8px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190724_182832412.jpg" alt="Michel" style="width: 246px;"/>
  <p>
  Unser n&auml;chster Halt war die Hamburger Innenstadt, wo das ber&uuml;hmte Rathaus steht. Es war malwieder ein wundersch&ouml;ner, sonniger Tag
  und die Innenstadt Hamburgs war sehr h&uuml;bsch und angenehm.<br>
  Zu Mittag gegessen haben wir im Fischrestaurant "Daniel Wischer".<br>
  Hamburg hat ein meiner Meinung nach sehr gut Ausgebautes S- und U-Bahnnetz. Was mich bis heute jedoch noch stets wundert ist, dass die S-Bahnen in der Stadt zu
  meisten Teilen unterirdisch und die U-Bahnen wiederum &uuml;berirdisch auf Stelzen mit Ausblick auf die Stadt fahren. Es sind also gewisserma&szlig;en &Uuml;-Bahnen!<br>
  In das Miniaturwunderland konnten wir erst abends hinein, deshalb haben wir noch ein Picknick vor dem Michel unternommen. Au&szlig;erdem wollte Anne mir noch die Reeperbahn zeigen...
  </p>
  <p>
  Am Abend gingen wir dann ins Miniaturwunderland hinein. Die Liebe zum Detail ist einfach grandios! Unz&auml;hlige Regionen und Wahrzeichen der Welt sind im Kleinen
  nachgestellt, manchmal sind es aber auch frei erfundene Orte und Szenen. Alle paar Minuten wird es Nacht im Miniaturwunderland und dann ist alles erleuchtet.<br>
  &Uuml;berall sind kleine Kn&ouml;pfe, die die Figuren und Modelle dazu bringen, sich zu bewegen. Man muss es einfach erleben!
  </p>
  <h2>Helgoland</h2>
  <p>
  Donnerstag, der 25. Juni 2019 war der hei&szlig;este Tag in Deutschland seit Beginn der Wetteraufzeichnungen (Stand: Januar 2020) mit Temperaturen teilweise
  um die 42 &deg;C im Inland. An diesem Tag fuhren wir hinaus auf hohe See auf die Insel Helgoland.
  </p>
  <p>
  Helgoland l&auml;sst sich in wenigen S&auml;tzen nicht beschreiben - zu gro&szlig; sind seine Geschichte, seine Natur und seine weiteren Besonderheiten!
  </p>
  <p>
  Am Morgen machten wir uns auf den Weg zu den Landungsbr&uuml;cken. Von dort aus konnte man noch einen wundersch&ouml;nen, letzten Blick auf die Elbphilharmonie werfen.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_080024546.jpg" alt="Landungsbr&uuml;cken" style="width: 755px;"/>
  <p>
  Wir stiegen an Bord eines hochmodernen und luxuri&ouml;sen Katamarans von Helgoline.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_080632097.jpg" alt="Halunder Jet" style="width: 755px;"/>
  <p>
  Wir hatten komfortable Sitze am Fenster und es gab auch etwas zu Essen. Trotzdem verbrachten wir die meiste Zeit auf dem Au&szlig;endeck, weil das Wetter
  einfach so herrlich warm war! Immer weiter fuhren wir die Elbe entlang, immer weiter weg erschien die Stadt Hamburg und immer breiter wurde der Fluss, bis er pl&ouml;tzlich
  ganz ins Meer &uuml;berging.<br>
  Der "Halunder Jet" erreicht eine Geschwindigkeit von 35 Knoten (65 km/h). Und so schnell sauste er auch &uuml;ber das Wasser! Der Wind, der dabei erzeugt wurde, war herrlich erquickend
  und der Wasserstrahl, den er hinter sich erzeugte war von enormer Kraft!<br>
  Wir kamen an einigen gro&szlig;en Frachtschiffen vorbei.<br>
  Nach einiger Zeit lie&szlig; sich am Horizont ein sanfter, unscheinbarer Fleck ausmachen - das war Helgoland!
  </p>
  <p>
  Der Archipel <a href="https://de.wikipedia.org/wiki/Helgoland">Helgoland</a> hat eine Fl&auml;che von nur gerade einmal 1 km&sup2; und unterteilt sich in die felsige Hauptinsel Helgoland und die
  sandige Nebeninsel "D&uuml;ne", die 1721 durch eine Sturmflut von der Hauptinsel getrennt wurde.<br>
  Auf Helgoland leben zurzeit etwa 1265 Menschen. Im Laufe der Geschichte war die Insel in Deutschem und D&auml;nischen Besitz, zeitweilens war sie sogar Britische Kolonie, bis Deutschland sie
  schlie&szlig;lich wieder gegen die Inseln von Sansibar eintauschte.<br>
  Im Zweiten Weltkrieg wurde Helgoland zu einem Milit&auml;rst&uuml;tzpunkt mit U-Boothafen und Flugzeugtr&auml;ger-Charakter ausgebaut. Nach dem Krieg wurde die Insel
  von den Amerikanern vollst&auml;ndig gesprengt, damit sie nie wieder als Milit&auml;rbasis genutzt werden kann. Es handelt sich um die gr&ouml;&szlig;te nicht-nukleare Explosion der Welt.
  Ein Video davon findet ihr <a href="https://www.youtube.com/watch?v=Z6hKwjoKa-c">hier</a>. Seitdem ist die Landschaft der Insel von tiefen Kratern gepr&auml;gt. Noch heute kann man sich die alten Bunkeranlagen anschauen.
  </p>
  <p>
  Als wir ankamen, stiegen wir zuerst hinauf auf das Oberland - einem Felsplateau etwa 30 m aus dem Meer ragend. Von dort aus hatten wir einen wundervollen und tollen Ausblick auf das Meer und schon
  auf so gut wie die gesamte Insel. (Das hatte man von ziemlich &uuml;berall, die Insel war nicht besonders gro&szlig;.)<br>
  Wir liefen ein St&uuml;ck durch den Ort und kamen an dem kleinsten EDEKA vorbei, den ich je gesehen habe. Wir mussten Sonnencreme kaufen, die Sonne brannte vom Himmel! Die G&auml;nge im EDEKA waren
  so klein, dass zwei Personen nicht aneinander vorbeipassten - eindeutig Platzmangel auf der Insel.<br>
  Dadurch, dass Helgoland nicht Teil der Europ&auml;ischen Zollunion und auch nicht des Deutschen Steuerraums ist, kann man hier zoll- und mehrwertsteuerfrei einkaufen.
  Spirituosen, wie J&auml;germeister, aber auch Schokolade und Parf&uuml;m bekommt man hier sehr g&uuml;nstig.<br>
  Als wir weiterliefen, kamen wir auch an der Kirche auf Helgoland vorbei, die einen authentischen Seefahrtsflair hatte.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_133428994.jpg" alt="Kirche Helgoland" style="width: 755px;"/>
  <p>
  Entlang an malerischen Klippen, traumhaften Meeresimpressionen, knuffigen Schreberg&auml;rtchen und vorbei an Schafen auf gr&uuml;nen Weiden machten wir uns auf den Weg zur "Langen Anna" &ndash; dem Wahrzeichen der Insel.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_140150459.jpg" alt="Lange Anna" style="width: 755px;"/>
  <p>
  Die "Lange Anna" ist ein roter Sandsteinfelsen im Norden der Insel. Helgoland ist ein Paradies und ein Kleinod f&uuml;r die verschiedensten Vogelarten. Die lassen sich dort an den Felsen
  besonders gut bestaunen.
  </p>
  <p>
  Vom "Pinneberg" &ndash; dem inoffiziellen Namen der h&ouml;chsten Erhebung Helgolands &ndash; hat man einen Panoramablick auf wirklich die gesamte Insel und man bekommt einen Eindruck davon,
  was f&uuml;r ein winziger Fleck im weiten Meer die Insel doch eigentlich ist.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_141006615.jpg" alt="Helgoland" style="width: 755px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190725_141124880.jpg" alt="Pinneberg" style="width: 755px;"/>
  <p>
  Aufgrund des Hochseeklimas und beg&uuml;nstigt durch den Golfstrom, sind auf Helgoland die Winter so mild,  dass Palmen das ganze Jahr &uuml;ber im Freien gedeihen k&ouml;nnen.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_145029941.jpg" alt="Palme auf Helgoland" style="width: 755px;"/>
  <p>
  Der Ort auf Helgoland ist in zwei Teile unterteilt: ein Teil auf dem Oberland und ein Teil auf dem Unterland. Von dem einen Teil zu dem anderen kann man mit einem Fahrstuhl gelangen.
  Das haben wir getan und uns dabei kurz mit einer waschechten Helgol&auml;nderin unterhalten.<br>
  Auf Helgoland gibt es keine Autos; Verbrennungsmotoren sind grunds&auml;tzlich verboten. Es ist auch verboten, Fahrrad zu fahren, denn sonst m&uuml;sste man
  Verkehrsschilder aufstellen und so beh&auml;lt die Insel auch ihren ruhigen Charakter. Dementsprechend besteht der Ort aus kleinen Str&auml;&szlig;chen und G&auml;sschen.
  Nachdem wir uns eine Weile die H&auml;uschen auf Helgoland angeschaut hatten, begaben wir uns wieder in Richtung Hafen. Bevor wir aber wieder an Bord gingen, war noch
  etwas Zeit, sich in das Caf&eacute; "Bunte Kuh" zu setzen, wo ich den weltbesten Kaffee getrunken habe!
  </p>
  <img src="@/assets/images/Nordsee/IMG-20190725-WA0016.jpg" alt="Palme auf Helgoland" style="width: 755px;"/>
  <p>
  Und dann mussten wir uns auch schon wieder von Helgoland verabschieden, denn unsere Reise ging weiter nach Amrum.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190725_162337198.jpg" alt="Helgoland" style="width: 755px;"/>
  <p>
  Von Amrum aus ging es dann auf die letzte F&auml;hre nach Dageb&uuml;ll. Dort war endlich unsere Unterkunft. <br>
  Den ganzen Tag sind wir zu Wasser gereist und haben die Deutsche Bucht erkundet.
  </p>
  <img src="@/assets/images/Nordsee/KarteReise.png" alt="Karte Reise" style="width: 755px;"/>
  <h2>Amrum &amp; Sylt</h2>
  <p>
  Die sch&ouml;nste Unterkunft hatten wir in Dageb&uuml;ll! In einer wundersch&ouml;nen, friesisch-l&auml;ndlichen Landschaft gelegen lag unsere Pension
  "To Olen Sl&uuml;&uuml;s" (Zur Alten Schleuse) an einem knuffigen Bahn&uuml;bergang, wie aus einer Modelleisenbahnlandschaft! Von unserem Fenster aus hatte man einen Ausblick auf
  eine Wiese mit Schafen und auf den Deich und die Farbe der Bettw&auml;sche im Zimmer machte einen einfach gl&uuml;cklich!<br>
  Von der Klassenfahrt in der 9. Klasse kannten wir die Inseln F&ouml;hr und Amrum und sind seither in Nordfriesland verliebt.
  An jenem warmen Sommertag stiegen wir wieder auf die gro&szlig;e F&auml;hre und nahmen Kurs auf Amrum.<br>
  Als erstes machten wir uns auf den Weg zum Kniepsand. Das ist eine gewaltige Sandbank im Westen Amrums, in der man sich wie in einer W&uuml;ste f&uuml;hlt,
  weil man in alle Richtungen weit und breit nur Sand sieht. Der Kniepsand hat eine Fl&auml;che von 10 km&sup2; und ist damit zehnmal gr&ouml;&szlig;er als Helgoland.
  Hier kann man einfach die Zeit und die Welt vergessen und man befindet sich pl&ouml;tzlich in einer Traumwelt und hinterl&auml;sst seine Spuren im Sand und sieht,
  welchen Weg man im Leben gegangen ist...
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190726_123402898_1.jpg" alt="Kniepsand" style="float: left; width: 373px; margin-right: 6px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190726_130744382.jpg" alt="Spuren im Sand" style="width: 375px;"/>
  <p>
  Nach etwa einem Kilometer gelangt man zu den D&uuml;nen und hat einen Ausblick auf den Amrumer Leuchtturm. Es ist ein Nordseeparadies! Diese Einsamkeit!
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190726_125050039.jpg" alt="Amrumer Leuchtturm" style="width: 755px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190726_125727392.jpg" alt="Anne in den D&uuml;nen" style="width: 755px;"/>
  <p>
  Ich bereue es, dass wir dort nicht mehr gebadet haben. Wir sind stattdessen weiter quer durch den Kniepsand in Richtung Leuchtturm gelaufen. Der Sand strahlte derart hell,
  dass man keinerlei Kontraste am Boden erkennen konnte. Man konnte nicht erkennen, wo sich der Boden absenkt und wo kleine H&uuml;gel sind und wurde somit beim Auftreten stets
  &uuml;berrascht. <br>
  Schlie&szlig;lich kamen wir bei den gr&ouml;&szlig;eren und bewachsenen D&uuml;nen an und wandelten &uuml;ber einen Holzsteg. Es war sehr hei&szlig;.
  </p>
  <p>
  Die zweite Tagesh&auml;lfte verbrachen wir auf Sylt. Mit dem Schiff ging es dorthin und wir hatten nochmal einen letzten Blick auf Amrum!
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190726_164121506.jpg" alt="Amrum" style="width: 755px;"/>
  <p>
  Von Sylts S&uuml;dspitze aus fuhren wir mit dem Bus &uuml;ber die lange, lange Stra&szlig;e durch die D&uuml;nen nach Westerland. Westerland ist eine etwas gr&ouml;&szlig;ere
  Stadt und ist nicht so vertr&auml;umt, wie die anderen St&auml;dtchen in Nordfriesland. Hier gibt es mehr Party.</p>
  <img src="@/assets/images/Nordsee/IMG_20190726_182902226.jpg" alt="Westerland" style="width: 755px;"/>
  <p>
  Von Westerland aus fuhren wir mit dem Zug &uuml;ber den Hindenburgdamm zur&uuml;ck aufs Festland zu unserer Unterkunft in Dageb&uuml;ll. <br>
  Im Nachhinein w&auml;re es vielleicht angenehmer gewesen, wenn wir einfach den ganzen Tag auf Amrum verbracht h&auml;tten, von Sylt haben wir letztendlich nicht sehr
  viel gesehen. Aber immerhin k&ouml;nnen wir jetzt sagen, dass wir mal auf Sylt waren!
  </p>
  <h2>F&ouml;hr &amp; Hallig Hooge</h2>
  <p>
  Am n&auml;chsten Tag verabschiedeten wir uns von den netten Leuten von der Unterkunft und stiegen wieder auf die F&auml;hre nach F&ouml;hr. Erst dort an Bord fiel mir auf,
  dass ich noch den Zimmerschl&uuml;ssel in meiner Tasche hatte. Ab dem Punkt konnte ich den Tag nicht mehr genie&szlig;en! Wir mussten nun also bei der Unterkunft anrufen und das denen erkl&auml;ren.
  So schnell konnten wir nun nicht zur&uuml;ck, wir waren ja auf dem Weg nach F&ouml;hr. Dann fuhren wir erstmal weiter auf die Hallig Hooge und alles war wieder gut! Die Hallig Hooge ist ein
  Halligparadies! Weite Graslandschaften durchzogen von unz&auml;hligen Kan&auml;len! Und ein stetiger, endloser Wind. Dieser Ort schien nicht von dieser Welt!<br>
  In einem wundersch&ouml;nen Caf&eacute;, das einem Liebesroman h&auml;tte entsprungen sein k&ouml;nnen, speisten wir.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190727_142418257.jpg" alt="Halligcaf&eacute;" style="width: 755px;"/>
  <p>
  Die H&auml;user auf den Halligen stehen auf kleinen H&uuml;gelchen, den sogenannte Warften. Wenn die Halligen &uuml;berflutet werden, was mehrmals im Jahr geschieht,
  ragen nur noch die Warften wie winzig kleine Inselchen aus dem Wasser hervor, siehe <a href="https://i.pinimg.com/originals/89/80/4e/89804ee2c853d793fccac99fca5fff29.jpg">hier</a>!
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190727_142819041.jpg" alt="Backenswarft" style="width: 755px;"/>
  <p>
  Auch auf Hooge haben wir uns die Kirche angeschaut. Sie erz&auml;hlt Geschichten von dem Leben inmitten der Naturgewalten des Meeres.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190727_153355286.jpg" alt="Kirche auf Hooge" style="width: 755px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190727_153108678.jpg" alt="Vater Unser auf Friesisch" style="float: left; width: 279px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190727_153209310.jpg" alt="Kreuz in den Fluten" style="width: 475px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190727_153222913.jpg" alt="H&auml;ngendes Schiff" style="width: 475px;"/>
  <img src="@/assets/images/Nordsee/IMG_20190727_153234673.jpg" alt="Kirche auf Hooge" style="width: 475px;"/>
  <p>Ein letzter Blick auf die Hallig...</p>
  <img src="@/assets/images/Nordsee/IMG-20190728-WA0002.jpg" alt="Br&uuml;cke auf Hooge" style="width: 524px;"/>
  <img src="@/assets/images/Nordsee/IMG-20190727-WA0011.jpg" alt="Schaukel auf Hooge" style="float: right; width: 231px;"/>
  <p>
  Den restlichen Tag verbrachten wir noch auf F&ouml;hr am Strand.
  </p>
  <img src="@/assets/images/Nordsee/IMG_20190727_111256136.jpg" alt="Steg in Wyk auf F&ouml;hr" style="width: 755px;"/>
  <p>
  Leider ist uns dann aufgefallen, dass die F&auml;hren an Samstagen anders fahren, als an Wochentagen. Ich kann nur jedem raten, das im Auge
  zu behalten! Letztendlich mussten wir noch anderthalb Stunden warten und beim Taxiunternehmen anrufen, dass wir uns versp&auml;ten. So haben wir
  im Gasthof "Zum Walfisch" noch etwas gegessen.<br>
  Wieder auf dem Festland haben wir den Schl&uuml;ssel wieder zur&uuml;ckgegeben und fuhren weiter in unsere letzte Unterkunft nach Ockholm.<br>
  Am n&auml;chsten Tag traten wir unsere Reise zur&uuml;ck an. Ich kann nur raten, niemals zu planen, an einem Sonntag weit zu fahren oder gar abzureisen. Wir
  mussten den einzigen Bus des Tages von Ockholm nach Bredstedt nehmen. Zum Gl&uuml;ck befand sich die Bushaltestelle direkt vor unserer Unterkunft.
  Dann ging es weiter mit dem Zug bis nach Hamburg und dann mit dem Flixbus nach Frankfurt, wo wir sp&auml;t abends ankamen.
  </p>
  <p>
  Im Gro&szlig;en und Ganzen war es eine wundervolle Reise die ich &ndash; an manchen Stellen ausgebessert &ndash; so auf jeden Fall nochmal wiederholen w&uuml;rde. In Nordfriesland ist mein Herz geblieben.
  </p>
  </div>
</template>
